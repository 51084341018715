import { ERROR } from '../types'
import { QUALIFICATION_COLLECTION, ROLE_ADMIN, USER_COLLECTION } from '../../_constants/globals'
import { doc, serverTimestamp, updateDoc, deleteField, deleteDoc } from 'firebase/firestore'


export const deleteQualificationAction = id => (dispatch, getState, { db }) =>
  deleteDoc(doc(db, QUALIFICATION_COLLECTION, id))
    .catch(err => {
      dispatch({ type: ERROR, err })
    })

export const updateQualificationAction = (id, qualification) => (dispatch, getState, { db }) => {
  console.log('updateQualificationAction', id, qualification)
  if (qualification.checkerId && getState().auth.profile.roles.includes(ROLE_ADMIN)) {
    qualification.checkerRef = doc(db, USER_COLLECTION, qualification.checkerId)
    qualification.checkingDate = serverTimestamp()
  } else {
    qualification.checkerRef = deleteField()
    qualification.checkingDate = deleteField()
  }
  return updateDoc(doc(db, QUALIFICATION_COLLECTION, id), {
    ...qualification,
    _updatedAt: serverTimestamp(),
  })
    .catch(err => {
      dispatch({ type: ERROR, err })
    })
}
