import { SIGNOUT_SUCCESS, AUTH_ERROR, LISTEN_PROFILE, EMAIL_SENT, EMAIL_ERROR } from '../types'
import { USER_COLLECTION } from '../../_constants/globals'
import { ONBOARDING } from '../../_constants/routes'
import {
  signInWithEmailAndPassword, updatePassword, signOut, signInWithEmailLink, onAuthStateChanged, sendPasswordResetEmail,
} from 'firebase/auth'
import { onSnapshot, doc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { resetLocalStorage } from '../../_helpers/localStorageHelper'


/**
 * Signs in with email and password
 *
 * @param {object} credentials
 */
export const signInAction = credentials => (dispatch, getState, { auth }) =>
  signInWithEmailAndPassword(auth, credentials.email, credentials.password)
    .catch(err => dispatch({
      type: AUTH_ERROR,
      payload: err,
    }))

export const signInWithEmailLinkAction = (email, url = window.location.href) => (dispatch, getState, { auth }) =>
  signInWithEmailLink(auth, email, url + ONBOARDING)

export const signOutAction = () => (dispatch, getState, { auth }) =>
  signOut(auth).then(() => {
    resetLocalStorage()
    window.location.reload()
    return dispatch({ type: SIGNOUT_SUCCESS })
  })

export const listenProfileAction = () => (dispatch, getState, { auth, db }) =>
  onAuthStateChanged(auth, authUser => authUser
    ? onSnapshot(doc(db, USER_COLLECTION, authUser.uid), async dbUser => {
      const profile = {
        id: dbUser.id,
        ...dbUser.data(),
        isAnonymous: auth.currentUser.isAnonymous,
        emailVerified: auth.currentUser.emailVerified,
        isLoaded: true,
        token: await auth.currentUser.getIdTokenResult(),
        isEmpty: false,
      }
      console.info('profile', profile)
      return dispatch({
        type: LISTEN_PROFILE,
        payload: profile,
      })
    })
    : dispatch({
      type: LISTEN_PROFILE,
      payload: { isLoaded: true, isEmpty: true },
    }),
  )


export const resetPasswordAction = email => (dispatch, getState, { auth }) =>
  sendPasswordResetEmail(auth, email)
    .then(() => dispatch({ type: EMAIL_SENT }))
    .catch(error => {
      console.error(error)
      dispatch({ type: EMAIL_ERROR })
    })

/**
 * Update password for the registered user
 *
 * @param {string} password
 */
export const updatePasswordAction = password => (dispatch, getState, { auth }) =>
  updatePassword(auth.currentUser, password)

export const checkPasswordAction = (email, password) => (dispatch, getState, { functions }) =>
  httpsCallable(functions, 'checkPassword')({ email, password, _VERBOSE: process.env.NODE_ENV !== 'production' })
