import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { some } from 'lodash'
import Loading from '../_library/Loading'
import { PB_ITEM_COLLECTION, PB_LESSON_COLLECTION, PROGRESS_BOOK_COLLECTION } from '../../_constants/globals'
import { getDocAction, listenCollection } from '../../store/actions/firestoreActions'


export const NextItems = ({ progressBookId, items, lessons, listenCollection, getDocAction }) => {

  const [progressBook, setProgressBook] = useState(null)

  useEffect(() => {
    const itemListener = listenCollection({
      collection: PB_ITEM_COLLECTION,
      orderBy: [['order', 'asc']],
      storeAs: 'items',
    })
    return () => {
      itemListener()
    }
  }, [listenCollection])

  useEffect(() => {
    const lessonListener = listenCollection({
      collection: PB_LESSON_COLLECTION,
      where: [['progressBookRef', '==', PROGRESS_BOOK_COLLECTION, progressBookId]],
      orderBy: [['date', 'asc']],
      storeAs: 'lessons',
    })
    return () => {
      lessonListener()
    }
  }, [listenCollection, progressBookId])

  useEffect(() => {
    getDocAction(PROGRESS_BOOK_COLLECTION, progressBookId).then(res => setProgressBook(res))
  }, [getDocAction, progressBookId])

  const unvalidatedItems = useMemo(() =>
    progressBook && items && lessons && items.filter(i =>
      i.level === progressBook.level
      && i.type === 'practical'
      && !lessons.some(l => some(l.practicalItems, (obj, key) => key === i.id && obj.grade === i.objective)),
    ),
  [progressBook, items, lessons],
  )

  if (!unvalidatedItems) return <Loading />
  else return (<>
    <ul>
      {unvalidatedItems.slice(0, 4).map(item => <li key={item.id}>{item.name}</li>)}
    </ul>
    {unvalidatedItems.length > 4 && `...et ${unvalidatedItems.length - 4} autres`}
  </>)
}

const mapStateToProps = state => ({
  items: state.firestore.items,
  lessons: state.firestore.lessons,
})

NextItems.propTypes = {
  progressBookId: PropTypes.string.isRequired,
  items: PropTypes.array,
  lessons: PropTypes.array,
  listenCollection: PropTypes.func.isRequired,
  getDocAction: PropTypes.func.isRequired,
}

export default compose(
  connect(mapStateToProps, { listenCollection, getDocAction }),
)(NextItems)
