import React, { useMemo, useState } from 'react'
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Chip, Typography, useTheme, useMediaQuery, ListItemButton, Box, styled, Button } from '@mui/material'
import { Flight, Group, Star, Euro, Dashboard, Settings, ChevronLeft, ChevronRight, Add, FlightTakeoff, AssignmentTurnedIn, PlayArrow } from '@mui/icons-material'
import Logo from '../../images/logo.svg'
import * as ROUTES from '../../_constants/routes'
import NavBackground from '../../images/nav_nachos.png'
import { useSelector } from 'react-redux'
import NewFlightDialog from '../flights/NewFlightDialog'
import { getQualificationStatus } from '../../_helpers/qualificationHelper'
import { chain } from 'lodash'
import { useLocation, useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import { MENU_STATUS_BIG, MENU_STATUS_HIDDEN, MENU_STATUS_SMALL } from '../../_constants/globals'

const listItemStyles = {
  py: 2,
  borderBottom: 'solid 1px',
  borderColor: 'primary.divider',
}

const StyledListItemText = styled(ListItemText)(() => ({
  fontSize: 'inherit',
  marginTop: 0,
  marginBottom: 0,
  display: 'inline-block',
  verticalAlign: 'top',
}))

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: 'inherit',
  marginRight: theme.spacing(2),
  minWidth: 'auto',
}))

const chevronStyle = {
  transition: 'transform .3s cubic-bezier(0.4, 0, 0.2, 1),color .1s cubic-bezier(0.4, 0, 0.2, 1)',
}

const fixedDecimals = num => Math.round(num * 100) / 100

const NavigationDrawer = ({ drawerWidth, menuStatus, setMenuStatus }) => {
  
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const profile = useSelector(state => state.auth.profile)
  const qualifications = useSelector(state => state.firestore.qualifications?.filter(q => q.userRef.id === state.auth.profile.id))
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const adminMode = useSelector(state => state.data.adminMode)
  
  const [openNewFlightDialog, setOpenNewFlightDialog] = useState(false)
  
  const qualifChip = useMemo(() => {
    if (!qualifications) return null
    const statuses = chain(qualifications).groupBy(getQualificationStatus).mapValues(val => val.length).values()
    if (statuses.expired)
      return { color: 'error.light', value: statuses.expired }
    else if (statuses.expireSoon)
      return { color: 'orange', value: statuses.expireSoon }
    else return { color: 'success.light', value: qualifications.length }
  }, [qualifications])
  
  return (
    <>
      <Drawer
        variant={isUpMd ? 'permanent' : 'temporary'}
        open={menuStatus !== MENU_STATUS_HIDDEN}
        onClose={() => setMenuStatus(MENU_STATUS_HIDDEN)}
        PaperProps={{
          sx: {
            width: menuStatus === MENU_STATUS_BIG ? drawerWidth : 60,
            backgroundAttachment: 'fixed',
            backgroundColor: 'background.menu',
            backgroundImage: `url(${NavBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '256px 556px',
            backgroundPosition: 'left 0 bottom 0',
            overflowX: 'hidden',
            maxHeight: '100vh',
            transition: menuStatus === MENU_STATUS_BIG
              ? theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              })
              : theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
          },
        }}
      >
        <List disablePadding sx={{ height: '100%' }}>
          <ListItem
            onClick={() => navigate(ROUTES.HOME)}
            sx={{
              ...listItemStyles,
              fontWeight: 500,
              color: 'white',
              '&:hover': {
                backgroundColor: 'initial',
              },
            }}
          >
            <Box component='img' src={Logo} alt='MPV' sx={{ height: '28px', ml: '-4px' }} />
            {menuStatus === MENU_STATUS_BIG && <Box component='span' sx={{ ml: 2 }}>Midi Pyrénées Voltige</Box>}
          </ListItem>
          <ListItemButton
            onClick={() => navigate(ROUTES.HOME)}
            sx={{
              py: 2,
              color: pathname === ROUTES.HOME ? 'primary.light' : 'rgba(255, 255, 255, 0.7)',
              borderBottom: 'solid 1px',
              borderColor: 'primary.divider',
              paddingRight: '44px',
              display: 'inline-block',
              width: '80%',
              '&:hover,&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
              '&:after': {
                backgroundColor: 'primary.divider',
                content: '\'\'',
                height: '36px',
                opacity: 1,
                position: 'absolute',
                right: 0,
                top: '10px',
                transition: '.075s cubic-bezier(0.4, 0, 1, 1)',
                width: '1px',
              },
            }}
          >
            <StyledListItemIcon><Dashboard /></StyledListItemIcon>
            {menuStatus === MENU_STATUS_BIG && <StyledListItemText>Dashboard</StyledListItemText>}
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(ROUTES.PROFILE)}
            sx={{
              ...listItemStyles,
              color: pathname.includes(ROUTES.PROFILE) ? 'primary.light' : 'rgba(255, 255, 255, 0.7)',
              display: 'inline-block',
              width: menuStatus === MENU_STATUS_BIG ? '20%' : '100%',
              '&:hover,&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
            }}
          >
            <StyledListItemIcon><Settings /></StyledListItemIcon>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(ROUTES.ACTIVITIES)}
            sx={{
              ...listItemStyles,
              color: pathname.includes(ROUTES.ACTIVITIES) ? 'primary.light' : 'rgba(255, 255, 255, 0.7)',
              '&:hover,&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
            }}
          >
            <StyledListItemIcon><PlayArrow/></StyledListItemIcon>
            {menuStatus === MENU_STATUS_BIG && <StyledListItemText>Activités</StyledListItemText>}
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(ROUTES.FLIGHTS)}
            sx={{
              ...listItemStyles,
              color: pathname.includes(ROUTES.FLIGHTS) ? 'primary.light' : 'rgba(255, 255, 255, 0.7)',
              '&:hover,&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
            }}
          >
            <StyledListItemIcon><FlightTakeoff /></StyledListItemIcon>
            {menuStatus === MENU_STATUS_BIG && <StyledListItemText>Vols</StyledListItemText>}
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(ROUTES.ACCOUNT)}
            sx={{
              ...listItemStyles,
              color: pathname.includes(ROUTES.ACCOUNT) ? 'primary.light' : 'rgba(255, 255, 255, 0.7)',
              '&:hover,&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
            }}
          >
            <StyledListItemIcon><Euro /></StyledListItemIcon>
            {menuStatus === MENU_STATUS_BIG && <StyledListItemText>
              Comptes
              {!adminMode && <Chip
                variant='outlined'
                size='small'
                label={`${profile.balance > 0 ? '+' : ''}${fixedDecimals(profile.balance)} €`}
                sx={{
                  float: 'right',
                  color: profile.balance > 0 ? 'success.light' : 'error.light',
                  borderColor: profile.balance > 0 ? 'success.light' : 'error.light',
                }}
              />}
            </StyledListItemText>}
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(ROUTES.QUALIFICATIONS)}
            sx={{
              ...listItemStyles,
              color: pathname.includes(ROUTES.QUALIFICATIONS) ? 'primary.light' : 'rgba(255, 255, 255, 0.7)',
              '&:hover,&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
            }}
          >
            <StyledListItemIcon><Star /></StyledListItemIcon>
            {menuStatus === MENU_STATUS_BIG && <StyledListItemText>
              Validités
              {qualifChip && !adminMode && <Chip
                size='small'
                variant='outlined'
                label={qualifChip.value}
                sx={{
                  float: 'right',
                  color: qualifChip.color,
                  borderColor: qualifChip.color,
                }}
              />}
            </StyledListItemText>}
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(ROUTES.MEMBERS)}
            sx={{
              ...listItemStyles,
              color: pathname.includes(ROUTES.MEMBERS) ? 'primary.light' : 'rgba(255, 255, 255, 0.7)',
              '&:hover,&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
            }}
          >
            <StyledListItemIcon><Group /></StyledListItemIcon>
            {menuStatus === MENU_STATUS_BIG && <StyledListItemText>Membres</StyledListItemText>}
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(ROUTES.AIRCRAFTS)}
            sx={{
              ...listItemStyles,
              color: pathname.includes(ROUTES.AIRCRAFTS) ? 'primary.light' : 'rgba(255, 255, 255, 0.7)',
              '&:hover,&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
            }}
          >
            <StyledListItemIcon><Flight /></StyledListItemIcon>
            {menuStatus === MENU_STATUS_BIG && <StyledListItemText>Avions</StyledListItemText>}
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(ROUTES.PROGRESS_BOOKS)}
            sx={{
              ...listItemStyles,
              color: pathname.includes(ROUTES.PROGRESS_BOOKS) ? 'primary.light' : 'rgba(255, 255, 255, 0.7)',
              '&:hover,&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
            }}
          >
            <StyledListItemIcon><AssignmentTurnedIn /></StyledListItemIcon>
            {menuStatus === MENU_STATUS_BIG && <StyledListItemText>Livrets de progression</StyledListItemText>}
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              !isUpMd && setMenuStatus(MENU_STATUS_HIDDEN)
              setOpenNewFlightDialog(true)
            }}
            sx={{
              ...listItemStyles,
              color: pathname === openNewFlightDialog ? 'primary.light' : 'rgba(255, 255, 255, 0.7)',
              position: 'absolute',
              bottom: 0,
              borderTop: `solid 1px ${theme.palette.primary.divider}`,
              width: '100%',
              '&:hover,&:focus': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
            }}
          >
            <StyledListItemIcon><Add /></StyledListItemIcon>
            {menuStatus === MENU_STATUS_BIG && <StyledListItemText>Saisir un vol</StyledListItemText>}
          </ListItemButton>
        </List>
        {menuStatus === MENU_STATUS_BIG && <Typography variant='body2' color='rgba(255, 255, 255, 0.4)' fontSize='.7rem' textAlign='center' sx={{
          position: 'absolute',
          bottom: theme.spacing(1),
          left: 0,
          width: '80%',
        }}>
          Version {window.version}
        </Typography>}
        <Button
          onClick={() => setMenuStatus(prev => {
            if (prev !== MENU_STATUS_BIG) return MENU_STATUS_BIG
            else if (isUpMd) return MENU_STATUS_SMALL
            else return MENU_STATUS_HIDDEN
          })}
          sx={{
            height: '44px',
            color: 'rgba(255, 255, 255, 0.7)',
            display: 'block',
          }}>
          {menuStatus === MENU_STATUS_BIG
            ? <ChevronLeft sx={{ ...chevronStyle, float: 'right' }} />
            : <ChevronRight sx={chevronStyle} />
          }
        </Button>
      </Drawer>
      {openNewFlightDialog && <NewFlightDialog open onClose={() => setOpenNewFlightDialog(false)} />}
    </>
  )
}

NavigationDrawer.propTypes = {
  drawerWidth: PropTypes.number.isRequired,
  menuStatus: PropTypes.string.isRequired,
  setMenuStatus: PropTypes.func.isRequired,
}

export default NavigationDrawer
