import React, { useEffect, useMemo } from 'react'
import PlanningPanel from '../bookings/PlanningPanel'
import TabbedPage from '../_library/TabbedPage'
import { compact } from 'lodash'
import TrainingPanel from './TrainingPanel'
import ActivityDetails from './ActivityDetails'
import useListener from '../../hooks/useListener'
import { listenActivityAction, listenCollection } from '../../store/actions/firestoreActions'
import { useMatch } from 'react-router'
import { ACTIVITIES } from '../../_constants/routes'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectActivityAction } from '../../store/actions/dataActions'
import { ACTIVITY_COLLECTION, AVAILABILITY_COLLECTION, CHAT_COLLECTION, QUALIFICATION_COLLECTION, REQUEST_COLLECTION, SLOT_COLLECTION } from '../../_constants/globals'


const ActivityPage = () => {
  
  useListener(listenActivityAction)
  const match = useMatch(ACTIVITIES + '/:id')
  const dispatch = useDispatch()
  const activity = useSelector(state => state.firestore.activities?.find(b => b.id === match?.params.id) || {}, shallowEqual)
  useListener(() => listenCollection({
    collection: AVAILABILITY_COLLECTION,
    where: [['day', '>=', activity.firstDay.toDate()]],
    orderBy: [['day', 'asc']],
    endAt: activity.lastDay.toDate(),
  }), [activity.firstDay, activity.lastDay], !!activity?.firstDay)
  useListener(() => listenCollection({
    collection: SLOT_COLLECTION,
    where: [['activityRef', '==', ACTIVITY_COLLECTION, activity.id]],
    orderBy: [['_createdAt', 'asc']],
  }), [activity.id], !!activity?.id)
  useListener(() => listenCollection({
    collection: REQUEST_COLLECTION,
    where: [['activityRef', '==', ACTIVITY_COLLECTION, activity.id]],
    orderBy: [['_createdAt', 'asc']],
  }), [activity.id], !!activity?.id)
  useListener(() => listenCollection({
    collection: CHAT_COLLECTION,
    where: [['activityRef', '==', ACTIVITY_COLLECTION, activity.id]],
    orderBy: [['_createdAt', 'asc']],
  }), [activity.id], !!activity?.id)
  useListener(() => listenCollection({
    collection: QUALIFICATION_COLLECTION,
    where: [['archived', '!=', true]],
    orderBy: [
      ['archived', 'asc'],
      ['type.order', 'asc'],
      ['_createdAt', 'desc'],
    ],
  }), [])
  
  useEffect(() => {activity && dispatch(selectActivityAction(activity))}, [activity])
  
  const tabs = useMemo(() => compact([
    {
      key: 'list',
      label: 'Stages',
      title: 'Prochains stages',
      route: 'list',
      content: <TrainingPanel />,
    },
    {
      key: 'planning',
      label: 'Planning',
      route: 'planning',
      content: <PlanningPanel />,
    },
    {
      key: 'training',
      label: '',
      title: activity.title || '',
      route: '/:id',
      content: <ActivityDetails />,
    },
  ]), [activity])
  
  return <TabbedPage tabs={tabs} />
}

export default ActivityPage
