import React  from 'react'
import { AppBar, Typography, Grid, Paper } from '@mui/material'
import { useSelector } from 'react-redux'
import FlightCard from './FlightCard'
import AnnouncementCard from './AnnouncementCard'
import AircraftCard from './AircraftCard'
import ProgressionCard from './ProgressionCard'
import { FLIGHT_COLLECTION, PROGRESS_BOOK_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { addMonths, startOfDay } from 'date-fns'
import { listenCollection } from '../../store/actions/firestoreActions'
import Page from '../_library/Page'
import useListener from '../../hooks/useListener'
import { AirworthinessCard } from './AirworthinessCard'
import ActivityCard from './ActivityCard'


export const DashboardPage = () => {
  
  const profile = useSelector(state => state.auth.profile)
  const aircrafts = useSelector(state => state.firestore.aircrafts?.filter(a => a.activated))
  const openBooks = useSelector(state => state.firestore.progressBooks?.filter(b => b.status === 'open'))
  
  useListener(() => listenCollection({
    collection: FLIGHT_COLLECTION,
    where: [['startDate', '>', addMonths(startOfDay(Date.now()), -3)]],
    orderBy: [['startDate', 'asc']],
  }), [])
  useListener(() => listenCollection({
    collection: PROGRESS_BOOK_COLLECTION,
    where: [['pilotRef', '==', USER_COLLECTION, profile.id]],
  }), [profile.id])
  
  return <Page withTopBarAndMenu>
    <AppBar
      component='div'
      color='primary'
      position='relative'
      elevation={0}
      sx={{ height: '40vh' }}
    >
      <Typography color='inherit' variant='h4' component='h1' ml={1}>
        {profile.firstname + ' ' + profile.lastname}
      </Typography>
    </AppBar>
    <Paper sx={{ mt: '-250px', mx: 'auto', maxWidth: '936px', backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Grid container justifyContent='center' spacing={1} px={2} >
        <Grid item md={12}>
          <AnnouncementCard />
        </Grid>
        <Grid item xs={12} md={4}>
          <AirworthinessCard />
        </Grid>
        <Grid item xs={12} md={4}>
          <ActivityCard />
        </Grid>
        <Grid item xs={12} md={4}>
          {openBooks?.length > 0 ? <ProgressionCard /> : <FlightCard />}
        </Grid>
      </Grid>
      <Grid container justifyContent='center' spacing={1} px={2}>
        {aircrafts?.map(aircraft =>
          <Grid item xs={12} md={4} key={aircraft.id}>
            <AircraftCard aircraft={aircraft} />
          </Grid>,
        )}
      </Grid>
    </Paper>
  </Page>
}

export default DashboardPage
