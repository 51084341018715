import React from 'react'
import PropTypes from 'prop-types'
import PlanningRow from './PlanningRow'
import { AVAILABILITY_COLLECTION, BOOKING_COLLECTION, FLIGHT_COLLECTION } from '../../_constants/globals'
import { endOfDay, format, isPast, startOfDay } from 'date-fns'
import { useSelector } from 'react-redux'
import Loading from '../_library/Loading'
import { chain } from 'lodash'
import { listenCollection } from '../../store/actions/firestoreActions'
import useListener from '../../hooks/useListener'
import { Box } from '@mui/material'


const hoursStyle = {
  textAlign: 'center',
  fontSize: '11px',
  color: '#777',
}
const pseudoBorder = {
  height: 4,
  width: '100%',
  background: 'transparent',
}
const blank4ElasticLine = {
  verticalAlign: 'bottom',
  borderLeft: '1px solid #ccc',
}
const PlanningTable = ({ day }) => {
  
  useListener(() => listenCollection({
    collection: BOOKING_COLLECTION,
    where: [['days', 'array-contains', format(day, 'dd-MM-yyyy')]],
    orderBy: [['startDate', 'asc']],
  }), [day])
  useListener(() => listenCollection({
    collection: AVAILABILITY_COLLECTION,
    where: [['day', '==', startOfDay(day)]],
    storeAs: 'dailyAvailabilities',
  }), [day])
  useListener(() => listenCollection({
    collection: FLIGHT_COLLECTION,
    where: [
      ['startDate', '>=', startOfDay(day)],
      ['startDate', '<=', endOfDay(day)],
    ],
    orderBy: [['startDate', 'asc']],
    storeAs: 'bookingFlights',
  }), [day])
  const aircrafts = useSelector(state => state.firestore.aircrafts?.filter(a => a.activated))
  const bookings = useSelector(state => state.firestore.bookings)
  const dailyAvailabilities = useSelector(state => state.firestore.dailyAvailabilities)
  const flights = useSelector(state => state.firestore.bookings)

  const instructors = dailyAvailabilities && chain(dailyAvailabilities).groupBy('userRef.id').toPairs().value()

  const data = isPast(day) ? flights : bookings

  if (!aircrafts) return <Loading />

  return (
    <Box component='table' sx={{
      tableLayout: 'fixed',
      textAlign: 'right',
      borderSpacing: 0,
      width: '100%',
      '& th': {
        fontSize: '11px',
        color: '#777',
      },
    }}>
      <thead>
        <tr>
          <Box component='th' sx={{ fontWeight: 'normal' }}>H</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>08</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>09</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>10</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>11</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>12</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>13</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>14</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>15</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>16</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>17</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>18</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>19</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>20</Box>
        </tr>
        <Box component='tr' sx={{ fontSize: 0, height: 3 }}>
          <th>
            <Box sx={pseudoBorder} />
          </th>
          <td colSpan='2' className='s'>
            <Box sx={pseudoBorder} />
          </td>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
        </Box>
      </thead>
      <tbody>
        {aircrafts.map(aircraft =>
          <PlanningRow
            key={aircraft.id}
            resource={aircraft}
            day={day}
            bookings={data?.filter(data => data.aircraftRef?.id === aircraft.id)}
            type='aircraft'
          />,
        )}
        {instructors?.map(([key, availabilities]) =>
          <PlanningRow
            key={key}
            resource={{ name: availabilities[0].user?.firstname + ' ' + availabilities[0].user?.lastname, ...availabilities[0].user }}
            availabilities={availabilities}
            day={day}
            bookings={data?.filter(data => data.instructorRef?.id === key)}
            type='instructor'
          />,
        )}
      </tbody>
    </Box>
  )
}

PlanningTable.propTypes = {
  day: PropTypes.object.isRequired,
}

export default PlanningTable
