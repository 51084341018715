import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Card, CardActions, CardContent, Drawer, IconButton, List, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { PROGRESS_BOOKS } from '../../_constants/routes'
import { deleteDocAction, updateDocAction } from '../../store/actions/firestoreActions'
import { NOTIFICATION_COLLECTION } from '../../_constants/globals'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { selectLesson } from '../../store/actions/dataActions'
import { format } from 'date-fns'


const NotificationDrawer = ({ open, onClose }) => {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.firestore.notifications || [])
  
  const getSpeech = useCallback(({ type, params, message }) => {
    switch (type) {
      case 'progressBookClosing':
        return `${params.firstname} ${params.lastname} demande la validation de son livret ${params.level}`
      case 'lessonToComplete':
        const date = format(params.date.toDate(), 'dd-MM-yyyy') // eslint-disable-line no-case-declarations
        const time = format(params.date.toDate(), 'HH:mm') // eslint-disable-line no-case-declarations
        return `Complète le livret ${params.progressBookName} de ${params.pilotName} pour le vol sur ${params.aircraft} le ${date} à ${time}`
      default:
        return message
    }
  }, [notifications])
  
  const handleClick = ({ type, params, id, route }) => {
    switch (type) {
      case 'progressBookClosing':
        return dispatch(updateDocAction(NOTIFICATION_COLLECTION, id, { read: true }))
          .then(navigate(`${PROGRESS_BOOKS}/${params.progressBookRef.id}`))
          .then(onClose)
      case 'lessonToComplete':
        return dispatch(updateDocAction(NOTIFICATION_COLLECTION, id, { read: true }))
          .then(dispatch(selectLesson(params.lessonRef.id)))
          .then(navigate(`${PROGRESS_BOOKS}/${params.progressBookRef.id}`))
          .then(onClose)
      default:
        return navigate(route)
    }
  }
  
  const deleteNotif = ({ id }) => () => dispatch(deleteDocAction(NOTIFICATION_COLLECTION, id))
  
  return (
    <Drawer anchor='right' open={open} onClose={onClose} sx={{ '& .MuiDrawer-paper': { bgcolor: 'common.white' } }}>
      <Box role='presentation' sx={{ width: 400, maxWidth: '100vw' }}>
        <Box sx={{ bgcolor: 'primary.main', width: '100%', color: 'common.white', p: 1 }}>
          <Typography variant='h5'>Notifications</Typography>
          <IconButton onClick={onClose} color='inherit' size='large' sx={{
            position: 'absolute',
            right: theme => theme.spacing(1),
            top: theme => theme.spacing(1),
          }}><Close /></IconButton>
        </Box>
        <List>
          {notifications.length
            ? notifications.map(notif => (
              <Card key={notif.id} sx={{ position: 'relative', m: 1, p: 1 }}>
                <IconButton onClick={deleteNotif(notif)} color='inherit' size='large' sx={{
                  position: 'absolute',
                  right: theme => theme.spacing(1),
                  top: theme => theme.spacing(1),
                }}><Close sx={{ width: '15px', height: '15px' }} /></IconButton>
                <CardContent>
                  {getSpeech(notif)}
                </CardContent>
                <CardActions>
                  <Button size='small' onClick={() => handleClick(notif)}>Consulter</Button>
                </CardActions>
              </Card>
            ))
            : <Typography variant='body2' style={{ fontStyle: 'italic' }}>&nbsp;Pas de notification</Typography>
          }
        </List>
      </Box>
    </Drawer>
  )
}

NotificationDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default NotificationDrawer
