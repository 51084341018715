import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Card, CardContent, Typography, Button, CardActions, Link, TextField } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import { updateDocAction } from '../../store/actions/firestoreActions'
import { signOutAction } from '../../store/actions/authActions'
import { USER_COLLECTION } from '../../_constants/globals'


const styles = theme => ({
  root: {
    maxWidth: 900,
    margin: '2rem auto',
  },
  commentField: {
    marginTop: theme.spacing(4),
  },
})

const SumUp = ({ classes, back, updateDocAction, profile, signOutAction }) => {

  const [comments, setComments] = useState(null)

  const submitted = useMemo(() => profile.status === 'applicationSubmitted', [profile.status])

  const onNext = () =>
    updateDocAction(USER_COLLECTION, profile.id, {
      status: 'applicationSubmitted',
      onboardingComments: comments,
    })

  return (
    <Card className={classes.root} variant='outlined'>
      {submitted
        ? <CardContent>
          <Typography variant='h1'>A bientôt</Typography>
          <Typography variant='body1'>Ton inscription a bien été enregistrée. Tu recevras un email quand elle aura été validée.</Typography>
        </CardContent>
        : <CardContent>
          <Typography variant='h1'>Et voilà</Typography>
          <Typography variant='body1'>Pour finaliser ton inscription il te reste à créditer ton compte pilote.</Typography>
          <ul>
            <li>
              Par virement FR76 1005 7192 5700 0203 1430 120
              <Link
                href='/iban_CIC_MPV.pdf'
                target='_blank'
                rel='noopener noreferrer'
                download
                style={{ cursor: 'pointer' }}
                underline='hover'>
              &nbsp;Télécharger le RIB
              </Link>
            </li>
          </ul>
          <Typography variant='body1'>Vérifie que ton dossier est bien complet et valide avec le bouton ci-dessous.</Typography>
          <Typography variant='body2'>Merci de vérifier méticuleusement les infos et les pièces jointes de ton dossier
            afin de faliciter le travail du bénévole en charge des inscription. Notamment la lisibilté des pièces
            jointes.</Typography>
          <TextField
            label='Commentaires'
            placeholder='Tu peux écrire ici tous les commentaires que tu veux laisser à la personne qui validera ton inscription'
            value={comments || ''}
            onChange={e => setComments(e.target.value)}
            variant='outlined'
            multiline
            fullWidth
            className={classes.commentField}
            InputLabelProps={{ shrink: true }}
          />
        </CardContent>
      }
      <CardActions style={{ float: 'right' }}>
        <Button size='large' onClick={back}>
          Précédent
        </Button>
        {!submitted
          ? <Button size='large' color='primary' onClick={onNext}>Valider l&apos;inscription</Button>
          : <Button size='large' color='secondary' onClick={signOutAction}>Déconnexion</Button>
        }
      </CardActions>
    </Card>
  )
}

const mapStateToProps = state => ({
  profile: state.auth.profile,
})

SumUp.propTypes = {
  classes: PropTypes.object.isRequired,
  back: PropTypes.func.isRequired,
  updateDocAction: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  signOutAction: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { updateDocAction, signOutAction }),
)(SumUp)
