import { mapValues } from 'lodash'
import { arrayUnion, doc } from 'firebase/firestore'


export const hydrateRefs = (db, data) =>
  mapValues(data, (val, index) => {
    if (index.includes('Refs') && val instanceof Array) {
      const strategy = val[2] || 'replace'
      if (strategy === 'add')
        return arrayUnion(doc(db, val[0], val[1]))
      else
        return val[1].map(id => doc(db, val[0], id))
    }
    if (index.includes('Ref') && val instanceof Array) return val[1] ? doc(db, val[0], val[1]) : null
    else return val
  })
