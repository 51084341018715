import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'recompose'
import { Grid, CircularProgress, Button, Tooltip, Box } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import MUIDataTable from 'mui-datatables'
import { format, getYear } from 'date-fns'
import clsx from 'clsx'
import DeleteButton from '../_library/DeleteButton'
import { fetchAccountEntryAction } from '../../store/actions/accountActions'
import { SwapHoriz } from '@mui/icons-material'
import FluxUpdateDialog from './FluxUpdateDialog'
import { selectYear, selectAccount } from '../../store/actions/dataActions'
import { deleteDocAction, listenCollection } from '../../store/actions/firestoreActions'
import { ACCOUNT_COLLECTION, ACCOUNT_ENTRY_COLLECTION } from '../../_constants/globals'
import { compact } from 'lodash'
import { useLocation } from 'react-router'
import useListener from '../../hooks/useListener'


const styles = theme => ({
  row: {
    textAlign: 'center',
    '&:nth-child(2n+1)': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.primary.main,
    '&:nth-child(2n+1)': {
      backgroundColor: theme.palette.primary.main,
    },
  },
})

export const AccountTable = ({ classes }) => {
  
  const location = useLocation()
  const dispatch = useDispatch()
  const accountEntries = useSelector(state => state.firestore.accountEntries)
  const adminMode = useSelector(state => state.data.adminMode)
  const selectedAccount = useSelector(state => state.data.selectedAccount)
  const selectedYear = useSelector(state => state.data.selectedYear)
  const accounts = useSelector(state => state.firestore.accounts)
  useListener(() => listenCollection({
    collection: ACCOUNT_ENTRY_COLLECTION,
    where: compact([
      ['accountRef', '==', ACCOUNT_COLLECTION, selectedAccount.id],
      selectedYear !== 'all' && ['accountDate', '>=', new Date(selectedYear || getYear(Date.now()), 0, 1, 0, 0)],
      selectedYear !== 'all' && ['accountDate', '<=', new Date(selectedYear || getYear(Date.now()), 11, 31, 23, 59)],
    ]),
    orderBy: [['accountDate', 'asc']],
  }), [selectedAccount, selectedYear], !!selectedAccount)
  
  
  const [selectedFluxId, selectFluxId] = useState(null)
  const [currentScrollElement, setCurrentScrollElement] = useState(null)
  
  const scroll = useRef()
  
  const selectedEntryId = useMemo(() => location.state?.entryId, [location.state])
  
  useEffect(() => {
    selectedEntryId && dispatch(fetchAccountEntryAction(selectedEntryId))
      .then(entryDoc => {
        dispatch(selectYear(entryDoc.accountDate.toDate().getFullYear()))
        return dispatch(selectAccount(accounts.find(a => a.id === entryDoc.accountRef.id)))
      })
  }, [selectedEntryId, accounts])
  
  // Scroll to selected entry
  useEffect(() => {
    if (currentScrollElement) {
      scroll.current = currentScrollElement
      try {
        scroll.current.scrollIntoView({ block: 'center' })
      } catch (error) {
        console.error(error)
      }
    }
  }, [currentScrollElement])
  
  const data = useMemo(() => {
    if (!accountEntries) return
    const { total, data } = accountEntries.reduce((acc, val) => {
      const { accountDate, paymentType, comment, debit, credit, balance } = val
      acc.total += credit || 0 - debit || 0
      acc.data.push([
        val,
        accountDate && format(accountDate.toDate(), 'dd-MM-yyyy'),
        paymentType,
        comment,
        parseFloat(debit || 0).toFixed(2),
        credit?.toFixed(2),
        balance?.toFixed(2),
        null,
      ])
      return acc
    }, { total: accountEntries[0]?.balance || 0, data: [] })
    data.push([
      { id: 'total' },
      null,
      null,
      null,
      null,
      null,
      null,
      total.toFixed(2),
    ])
    return data
  }, [accountEntries])
  
  return (
    <Box sx={{ maxWidth: '1200px', my: 2, mx: 'auto' }}>
      {!data
        ? <Grid container item xs={12} justifyContent='center' sx={{ mt: '30px' }}>
          <CircularProgress size={20} />
        </Grid>
        
        : <MUIDataTable
          title={selectedAccount?.name}
          data={data}
          columns={[
            { name: 'id', options: { display: 'false', filter: false } },
            { name: 'Date', options: { filter: false, sort: true } },
            { name: 'Type', options: { filterType: 'multiselect', sort: true } },
            { name: 'Commentaire', options: { filterType: 'textField', sort: true } },
            { name: 'Débit', options: { filter: false, sort: true } },
            { name: 'Crédit', options: { filter: false, sort: true } },
            { name: 'solde', options: { display: 'excluded', filter: false } },
            { name: 'total', options: { display: 'excluded', filter: false } },
          ]}
          options={{
            selectableRows: 'none',
            viewColumns: adminMode,
            pagination: false,
            customRowRender: data => rowRender(data, classes, dispatch, selectFluxId, data[0].id === selectedEntryId, setCurrentScrollElement, adminMode),
            print: false,
          }}
        />
      }
      {selectedFluxId && <FluxUpdateDialog open onClose={() => selectFluxId(null)} fluxId={selectedFluxId} />}
    </Box>
  )
}

const rowRender = (data, classes, dispatch, selectFluxId, selectedRow, setCurrentScrollElement, adminMode) => {
  const [obj, accountDate, paymentType, comments, debit, credit, balance, total] = data
  if (total)
    return (
      <tr key={obj.id} id={obj.id} className={classes.row}>
        <td colSpan='3' style={{ textAlign: 'right' }}>
          <strong>Total</strong>
        </td>
        <td colSpan='2'>
          <Box component='strong' sx={{ color: total > 0 ? 'success.main' : 'error.main' }}>{total}€</Box>
        </td>
      </tr>
    )
  else if (balance !== undefined)
    return (
      <tr key={obj.id} id={obj.id} className={classes.row}>
        <td colSpan='3' style={{ textAlign: 'right' }}>
          <strong>Solde au premier janvier</strong>
        </td>
        <td colSpan='2'>
          <Box component='strong' sx={{ color: 'info.main' }}>{balance}€</Box>
        </td>
      </tr>
    )
  else
    return (
      <tr
        key={obj.id}
        id={obj.id}
        className={clsx(classes.row, selectedRow && classes.selectedRow)}
        ref={el => selectedRow && setCurrentScrollElement(el)}
      >
        <td>{accountDate}</td>
        <td>{paymentType}</td>
        <td style={{ textAlign: 'left' }}>{comments}</td>
        <td>{debit ? <strong>{debit}€</strong> : ''}</td>
        <td>{credit ? <strong>{credit}€</strong> : ''}</td>
        <td>
          {adminMode && paymentType === 'transfer' && <DeleteButton deleteAction={() => dispatch(deleteDocAction(ACCOUNT_ENTRY_COLLECTION, obj.id))} />}
          {adminMode && obj.fluxRef &&
            <Tooltip title='Editer le flux'>
              <Button onClick={() => selectFluxId(obj.fluxRef.id)} >
                <SwapHoriz />
              </Button>
            </Tooltip>
          }
        </td>
      </tr>
    )
}

AccountTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
)(AccountTable)
