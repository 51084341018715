import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, FormControl, InputLabel, Select, MenuItem,
  FormHelperText } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'recompose'
import UserSelect from '../_library/UserAutocomplete'
import { createProgressBookAction } from '../../store/actions/progressBookActions'
import { STATUS_ACTIVATED } from '../../_constants/globals'


const styles = theme => ({
  root: {
    '& .MuiDialog-paper': {
      minHeight: 260,
    },
    '& .MuiInput-underline::before': {
      borderBottomColor: 'rgba(0,0,0,.1)',
    },
  },
  select: {
    width: '100%',
  },
  avatar: {
    marginRight: 10,
    display: 'inline-flex',
  },
  aircraftBtn: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        cursor: 'default',
      },
    },
  },
})

const CreateProgressBookDialog = ({ classes, onClose }) => {
  
  const users = useSelector(state => state.firestore.users.filter(u => u.status === STATUS_ACTIVATED))
  const templates = useSelector(state => state.firestore.templates)
  const profile = useSelector(state => state.auth.profile)
  const adminMode = useSelector(state => state.data.adminMode)
  const dispatch = useDispatch()

  const [template, setTemplate] = useState(null)
  const [pilot, setPilot] = useState(profile)
  const [errors, setErrors] = useState({})

  const handleSubmit = () => {
    if (!template) setErrors(prev => ({ ...prev, type: 'Il faut sélectionner un type de livret' }))
    else dispatch(createProgressBookAction(template.name, template.level, pilot.id)).then(onClose)
  }

  return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-title' className={classes.root}>
      <DialogTitle id='form-dialog-title'>Ouvrir un livret de progression</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <>
            {(adminMode || profile.roles?.includes('instructeur')) && <Grid item xs={12}>
              {users && <UserSelect
                users={users}
                user={pilot}
                label='Pilote'
                className={classes.select}
                set={setPilot}
                disableClearable
              />}
            </Grid>}
            <Grid item xs={12}>
              <FormControl
                variant='standard'
                className={classes.select}
                error={Boolean(errors.type)}>
                <InputLabel id='type-select-label'>Type de livret</InputLabel>
                {templates && <Select
                  variant='standard'
                  labelId='type-select-label'
                  id='type-select'
                  value={template || ''}
                  onChange={e => setTemplate(e.target.value)}>
                  {templates.map(t => <MenuItem key={t.id} value={t}>{t.name}</MenuItem>)}
                </Select>}
                <FormHelperText>{errors.type}</FormHelperText>
              </FormControl>
            </Grid>
          </>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Annuler
        </Button>
        <Button onClick={handleSubmit} color='primary'>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CreateProgressBookDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
)(CreateProgressBookDialog)
