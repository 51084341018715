import React, { useEffect, useMemo, useState } from 'react'
import YearSelector from '../_library/YearSelector'
import { useDispatch, useSelector } from 'react-redux'
import AccountSelector from '../_library/AccountSelector'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import { SwapHoriz } from '@mui/icons-material'
import Icon from '@mdi/react'
import { mdiAccountCash } from '@mdi/js'
import FluxCreateDialog from './FluxCreateDialog'
import ApproDialog from './ApproDialog'
import AccountTable from './AccountTable'
import TabbedPage from '../_library/TabbedPage'
import { ACCOUNT_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { selectAccount } from '../../store/actions/dataActions'
import { compact } from 'lodash'
import AllEntryTable from './AllEntryTable'
import { listenCollection } from '../../store/actions/firestoreActions'
import useListener from '../../hooks/useListener'


export const AccountPage = () => {
  
  const profile = useSelector(state => state.auth.profile)
  const adminMode = useSelector(state => state.data.adminMode)
  const accounts = useSelector(state => state.firestore.accounts)
  const selectedAccount = useSelector(state => state.data.selectedAccount)
  const dispatch = useDispatch()
  useListener(() => listenCollection({
    collection: ACCOUNT_COLLECTION,
    where: adminMode ? [] : [['ownerRef', '==', USER_COLLECTION, profile.id]],
    orderBy: adminMode ? [['name', 'asc']] : [],
  }), [adminMode, profile.id])
  
  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const [openFluxDialog, setOpenFluxDialog] = useState(false)
  const [openApproDialog, setOpenApproDialog] = useState(false)
  
  useEffect(() => {
    if (accounts && (!selectedAccount || !adminMode)) {
      dispatch(selectAccount(accounts.find(a => a.ownerRef?.id === profile.id)))
    }
  }, [accounts, selectedAccount, profile.id, adminMode])
  
  const tabs = useMemo(() => compact([
    {
      key: 'pilot',
      label: 'Compte pilote',
      route: 'pilot',
      headerContent: <>
        {adminMode && <AccountSelector />}
        <YearSelector />
      </>,
      content: <AccountTable />,
    },
    adminMode && {
      key: 'all',
      label: 'Toutes les entrées',
      route: 'all',
      headerContent: <YearSelector />,
      content: <AllEntryTable />,
    },
  ]), [adminMode])
  
  return (
    <>
      <TabbedPage tabs={tabs} containerSx={{ pb: '100px' }} />
      {adminMode && <SpeedDial
        ariaLabel='speedDial'
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedDialOpen(false)}
        onClick={() => setSpeedDialOpen(true)}
        open={speedDialOpen}
        FabProps={{ color: 'secondary' }}
        sx={{
          position: 'fixed',
          right: theme => theme.spacing(3),
          bottom: theme => theme.spacing(3),
          '& .MuiSpeedDialAction-staticTooltipLabel': {
            width: 200,
          },
        }}
      >
        <SpeedDialAction
          icon={<SwapHoriz />}
          tooltipTitle='Créer un flux'
          tooltipOpen
          onClick={() => setOpenFluxDialog(true)}
        />
        <SpeedDialAction
          icon={<Icon path={mdiAccountCash} size={1} />}
          tooltipTitle='Approvisionner un compte pilote'
          tooltipOpen
          onClick={() => setOpenApproDialog(true)}
        />
      </SpeedDial>}
      {openFluxDialog && <FluxCreateDialog open onClose={() => setOpenFluxDialog(false)} />}
      {openApproDialog && <ApproDialog open onClose={() => setOpenApproDialog(false)} />}
    </>
  )
}

export default AccountPage
