import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, FormControl, Select, MenuItem, InputLabel, TextField, Autocomplete, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { updateFlightAction } from '../../store/actions/flightActions'
import { isValid } from 'date-fns'
import UserSelect from '../_library/UserAutocomplete'
import { DateTimePicker } from '@mui/x-date-pickers'
import useListener from '../../hooks/useListener'
import { listenCollection } from '../../store/actions/firestoreActions'
import { AIRPORT_COLLECTION } from '../../_constants/globals'


export const FlightDetails = ({ flight, close = () => null }) => {
  
  useListener(() => listenCollection({
    collection: AIRPORT_COLLECTION,
    where: [['country', '==', 'France']],
    orderBy: [['occurrences', 'desc']],
  }))
  const dispatch = useDispatch()
  const profile = useSelector(state => state.auth.profile)
  const users = useSelector(state => state.firestore.users)
  const aircrafts = useSelector(state => state.firestore.aircrafts.filter(a => a.activated))
  const flightTypes = useSelector(state => state.data.adminMode
    ? state.firestore.flightTypes
    : state.auth.profile.flightTypes && state.firestore.flightTypes.filter(t => state.auth.profile.flightTypes.some(slug => slug === t.slug)))
  const billingTypes = useSelector(state => state.data.adminMode
    ? state.firestore.billingTypes
    : state.auth.profile.billingTypes && state.firestore.billingTypes.filter(t => state.auth.profile.billingTypes.some(slug => slug === t.slug)))
  const airports = useSelector(state => state.firestore.airports)

  const instructors = useMemo(() => users && users.filter(u => u.roles?.includes('instructeur')), [users])

  if (!flight) return null
  return (
    <Grid container sx={{ overflowY: 'scroll' }}>
      <Grid container id='activity' sx={{ mb: 5 }}>
        <Grid item xs={4}>
          <Typography color='textSecondary' gutterBottom>Activité</Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant='standard'>
            <InputLabel shrink id='flight-type-select-label'>Type de vol</InputLabel>
            {flightTypes && <Select
              variant='standard'
              id='flight-type-select'
              labelId='flight-type-select-label'
              value={flight.flightTypeRef?.id}
              onChange={e => dispatch(updateFlightAction({ id: flight.id, flightTypeId: e.target.value }))}
              fullWidth
              margin='dense'
              disabled={!profile?.roles.includes('pilote')}>
              {flightTypes.map(type =>
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>,
              )}
            </Select>}
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <FormControl variant='standard' sx={{ mt: 1 }}>
            <InputLabel shrink id='billing-type-select-label'>
              Facturation
            </InputLabel>
            {billingTypes && <Select
              variant='standard'
              id='billing-type-select'
              labelId='billing-type-select-label'
              value={flight.billingTypeRef?.id}
              onChange={e => dispatch(updateFlightAction({ id: flight.id, billingTypeId: e.target.value }))}
              margin='dense'
              disabled={!profile?.roles.includes('pilote')}
              fullWidth
            >
              {billingTypes.map(type =>
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>,
              )}
            </Select>}
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <Typography color='textSecondary' gutterBottom>Avion</Typography>
          {aircrafts && <ToggleButtonGroup
            size='large'
            value={flight.aircraftRef?.id}
            exclusive
            onChange={(event, value) => dispatch(updateFlightAction({ id: flight.id, aircraftId: value }))}
          >
            {aircrafts.map(aircraft =>
              <ToggleButton key={aircraft.id} value={aircraft.id}>{aircraft.name}</ToggleButton>,
            )}
          </ToggleButtonGroup>}
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <DateTimePicker
            inputFormat='dd-MM-yyyy HH:mm'
            margin='dense'
            id='startDate'
            value={flight.startDate ? flight.startDate.toDate() : new Date()}
            onChange={val => isValid(val) && dispatch(updateFlightAction({ id: flight.id, startDate: val }))}
            ampm={false}
            label='date et heure'
            renderInput={params => <TextField variant='standard' sx={{ mt: 1 }} {...params} />}
          />
        </Grid>
      </Grid>
      <Grid container id='crew' sx={{ mb: 5 }}>
        <Grid item xs={4}>
          <Typography color='textSecondary' gutterBottom>Equipage</Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant='standard'>
            {users && <UserSelect
              disabled={!profile.roles?.includes('admin') && !profile.roles?.includes('instructeur')}
              user={users.find(u => u.id === flight.pilotRef?.id) || null}
              label='Pilote'
              set={user => dispatch(updateFlightAction({ id: flight.id, pilotId: user.id }))}
              disableClearable
              onKeyPress={e => e.key === 'Enter' && close()}
              sx={{ width: '100%' }}
            />}
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8}>
          <FormControl variant='standard' sx={{ minWidth: '200px' }}>
            {instructors && instructors.length > 0 && <UserSelect
              user={instructors.find(u => u.id === flight.instructorRef?.id) || null}
              users={instructors}
              label='Instructeur'
              set={user => dispatch(updateFlightAction({ id: flight.id, instructorId: user?.id || null }))}
              onKeyPress={e => e.key === 'Enter' && close()}
              sx={{ width: '100%' }}
            />}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container id='airports' sx={{ mb: 5 }}>
        <Grid item xs={4} ><Typography color='textSecondary' gutterBottom>Aérodromes</Typography></Grid>
        <Grid item xs={8} >
          <FormControl variant='standard' sx={{ minWidth: '200px' }}>
            {airports && <Autocomplete
              id='departure-select'
              options={airports}
              getOptionLabel={option => option.ICAO ? option.ICAO + ' - ' + option.name : ''}
              isOptionEqualToValue={(option, value) => option.id === value && option}
              renderInput={params => <TextField variant='standard' {...params} label='Départ' fullWidth />}
              value={flight.departure}
              onChange={(event, newValue) =>
                dispatch(updateFlightAction({ id: flight.id, departureId: newValue.id }))
              }
              onKeyPress={e => e.key === 'Enter' && close()}
            />}
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8} >
          <FormControl variant='standard' sx={{ minWidth: '200px' }}>
            {airports && <Autocomplete
              id='arrival-select'
              options={airports}
              getOptionLabel={option => option.ICAO ? option.ICAO + ' - ' + option.name : ''}
              isOptionEqualToValue={(option, value) => option.id === value}
              renderInput={params => <TextField variant='standard' {...params} label='Arrivée' fullWidth />}
              value={flight.arrival}
              onChange={(event, newValue) =>
                dispatch(updateFlightAction({ id: flight.id, arrivalId: newValue.id }))
              }
              onKeyPress={e => e.key === 'Enter' && close()}
            />}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container id='counter' sx={{ mb: 5 }}>
        <Grid item xs={4}>
          <Typography color='textSecondary' gutterBottom>Horamètre</Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='counterDeparture'
              label='Départ'
              type='number'
              fullWidth
              value={flight.counterDeparture}
              onChange={e => dispatch(updateFlightAction({ id: flight.id, counterDeparture: parseFloat(e.target.value) }))}
              inputProps={{ step: flight.aircraft?.price?.float || .01 }}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8} >
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='counterArrival'
              label='Arrivée'
              type='number'
              fullWidth
              value={flight.counterArrival}
              onChange={e => dispatch(updateFlightAction({ id: flight.id, counterArrival: parseFloat(e.target.value) }))}
              inputProps={{ step: flight.aircraft?.price?.float || .01 }}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8} >
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='duration'
              label='Durée'
              type='number'
              fullWidth
              value={flight.duration}
              disabled />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container id='fuel' sx={{ mb: 5 }}>
        <Grid item xs={4}>
          <Typography color='textSecondary' gutterBottom>
            Essence
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='fuelBefore'
              label='Départ'
              type='number'
              fullWidth
              value={flight.fuelBefore}
              onChange={e => dispatch(updateFlightAction({ id: flight.id, fuelBefore: parseFloat(e.target.value) }))}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8} >
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='fuelAfter'
              label='Arrivée'
              type='number'
              fullWidth
              value={flight.fuelAfter}
              onChange={e => dispatch(updateFlightAction({ id: flight.id, fuelAfter: parseFloat(e.target.value) }))}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container id='loadFactors' sx={{ mb: 5 }}>
        <Grid item xs={4}>
          <Typography color='textSecondary' gutterBottom>
            Accéléro
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='gPos'
              label='Positif'
              type='number'
              fullWidth
              value={flight.gPos}
              onChange={e => dispatch(updateFlightAction({ id: flight.id, gPos: parseFloat(e.target.value) }))}
              inputProps={{ step: .1 }}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={8} >
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='gNeg'
              label='Négatif'
              type='number'
              fullWidth
              value={flight.gNeg}
              onChange={e => dispatch(updateFlightAction({ id: flight.id, gNeg: parseFloat(e.target.value) }))}
              inputProps={{ step: .1 }}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container id='landings' sx={{ mb: 5 }}>
        <Grid item xs={4}>
          <Typography color='textSecondary' gutterBottom>
            Atterrissages
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant='standard'>
            <TextField
              variant='standard'
              margin='dense'
              id='landings'
              label='Nombre'
              type='number'
              fullWidth
              value={flight.landings}
              onChange={e => dispatch(updateFlightAction({ id: flight.id, landings: parseInt(e.target.value) }))}
              inputProps={{ step: 1 }}
              onKeyPress={e => e.key === 'Enter' && close()} />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} id='comments'>
        <TextField
          margin='dense'
          label='Commentaires'
          multiline
          fullWidth
          value={flight.comments || ''}
          rows={4}
          variant='outlined'
          onChange={e => dispatch(updateFlightAction({ id: flight.id, comments: e.target.value }))}
        />
      </Grid>
    </Grid>
  )
}

FlightDetails.propTypes = {
  flight: PropTypes.object.isRequired,
  close: PropTypes.func,
}

export default FlightDetails
