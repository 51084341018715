import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { updateDocAction } from '../../store/actions/firestoreActions'
import { PROGRESS_BOOK_COLLECTION, QUALIFICATION_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import InlineEditor from '../_library/InlineEditor'
import UserAutocomplete from '../_library/UserAutocomplete'
import { isValid } from 'date-fns'
import { DatePicker } from '@mui/x-date-pickers'


const styles = () => ({
  root: {
    '& .MuiInput-underline::before': {
      borderBottomColor: 'rgba(0, 0, 0, .1)',
    },
  },
  label: {
    display: 'inline-block',
    width: 200,
  },
})

const ClosureDialog = ({ classes, onClose, progressBook, updateDocAction, licence, pilot, instructors }) => {
  const [submitted, setSubmitted] = useState(false)

  const isPDFToBeGenerated = useMemo(
    () => progressBook && licence && pilot
      && progressBook.pilot?.firstname
      && progressBook.pilot?.lastname
      && licence.identValue
      && licence.grantDate
      && pilot.experience?.total
      && progressBook.experience?.cdb
      && progressBook.startDate
      && progressBook.closingDate
      && progressBook.closingPicHours,
    [progressBook, licence, pilot],
  )

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)
    updateDocAction(PROGRESS_BOOK_COLLECTION, progressBook.id, {
      status: 'closing',
    }).finally(() => setSubmitted(false))
  }

  return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-title' className={classes.root}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id='form-dialog-title'>Cloture du livret de progression</DialogTitle>
        <DialogContent>
          {progressBook.status === 'open' && <>
            <Typography variant='body1'>
              Si tu penses avoir validé tous les items du livret il ne te reste plus qu&apos;à le cloturer. Il sera ensuite soumis
              à la validation du chef pilote puis tu recevras la synthèse en PDF par email.
            </Typography>
            <Grid item xs={12} className={classes.formField}>
              <InlineEditor
                title='Prénom'
                value={progressBook.pilot?.firstname}
                handleSubmit={val => updateDocAction(USER_COLLECTION, progressBook.pilotRef.id, { firstname: val })}
              />
            </Grid>
            <Grid item xs={12} className={classes.formField}>
              <InlineEditor
                title='Nom'
                value={progressBook.pilot?.lastname}
                handleSubmit={val => updateDocAction(USER_COLLECTION, progressBook.pilotRef.id, { lastname: val })}
              />
            </Grid>
            {licence
              ? <>
                <Grid item xs={12} className={classes.formField}>
                  <InlineEditor
                    title='Numéro de Licence pilote'
                    value={licence.identValue}
                    handleSubmit={val => updateDocAction(QUALIFICATION_COLLECTION, licence.id, { identValue: val })}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formField}>
                  <Typography className={classes.label} color='textSecondary' gutterBottom>
                    Date d&apos;obtention
                  </Typography>
                  <DatePicker
                    inputFormat='dd-MM-yyyy'
                    margin='dense'
                    id='licence.grantDate'
                    value={licence.grantDate?.toDate() || null}
                    onChange={val => isValid(val) && updateDocAction(QUALIFICATION_COLLECTION, licence.id, { grantDate: val })}
                    renderInput={params => <TextField {...params} />}
                  />
                </Grid>
              </>
              : <Typography variant='body1' color='secondary'>Error : Pilot licence not found</Typography>
            }
            <Grid item xs={12} className={classes.formField}>
              <InlineEditor
                title='Nombre d&apos;heures de vol avant formation'
                value={pilot.experience?.total}
                handleSubmit={val => updateDocAction(USER_COLLECTION, pilot.id, { experience: { ...pilot.experience, total: val } })}
              />
            </Grid>
            <Grid item xs={12} className={classes.formField}>
              <InlineEditor
                title='Nombre d&apos;heures de CDB avant formation'
                value={progressBook.experience?.cdb}
                handleSubmit={val => updateDocAction(PROGRESS_BOOK_COLLECTION, progressBook.id, { experience: { ...progressBook.experience, cdb: val } })}
              />
            </Grid>
            <Grid item xs={12} className={classes.formField}>
              <InlineEditor
                title='Nombre d&apos;heures de voltige avant formation'
                value={progressBook.experience?.aerobaticsDC}
                handleSubmit={val => updateDocAction(PROGRESS_BOOK_COLLECTION, progressBook.id, { experience: { ...progressBook.experience, aerobaticsDC: val } })}
              />
            </Grid>
            <Grid item xs={12} className={classes.formField}>
              <InlineEditor
                title='sur avion type'
                value={progressBook.experience?.airplane}
                handleSubmit={val => updateDocAction(PROGRESS_BOOK_COLLECTION, progressBook.id, { experience: { ...progressBook.experience, airplane: val } })}
              />
            </Grid>
            <Grid item xs={12} className={classes.formField}>
              <Typography className={classes.label} color='textSecondary' gutterBottom>
                Formation débutée le
              </Typography>
              <DatePicker
                inputFormat='dd-MM-yyyy'
                margin='dense'
                id='progressBook.startDate'
                value={progressBook.startDate?.toDate() || null}
                onChange={val => isValid(val) && updateDocAction(PROGRESS_BOOK_COLLECTION, progressBook.id, { startDate: val })}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} className={classes.formField}>
              <Typography className={classes.label} color='textSecondary' gutterBottom>
                Formation terminée le
              </Typography>
              <DatePicker
                inputFormat='dd-MM-yyyy'
                margin='dense'
                id='closureDate-date'
                value={progressBook.closingDate?.toDate() || null}
                onChange={val => isValid(val) && updateDocAction(PROGRESS_BOOK_COLLECTION, progressBook.id, { closingDate: val })}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} className={classes.formField}>
              <InlineEditor
                title='Nombre d&apos;heures de vol en fin de formation'
                value={progressBook.closingPicHours}
                handleSubmit={val => updateDocAction(PROGRESS_BOOK_COLLECTION, progressBook.id, { closingPicHours: val })}
              />
            </Grid>
            <Grid item xs={12} className={classes.formField}>
              <Typography className={classes.label} color='textSecondary' gutterBottom>
                Instructeur
              </Typography>
              <FormControl variant='outlined' className={classes.formControl}>
                <UserAutocomplete
                  users={instructors}
                  user={{ id: progressBook.closingInstructorRef?.id, ...progressBook.closingInstructor }}
                  set={val => updateDocAction(PROGRESS_BOOK_COLLECTION, progressBook.id, { closingInstructorRef: [USER_COLLECTION, val?.id || null] })}
                  disableClearable
                />
              </FormControl>
            </Grid>
          </>
          }
          {progressBook.status === 'closing' &&
            <Typography variant='body1'>
              Ton livret est en cours de validation. Tu receveras le livret finalisé en format pdf par email si le chef pilote le valide
              sinon ce dernier prendra contact avec toi pour t&apos;indiquer ce qu&apos;il manque.
            </Typography>
          }
          {progressBook.error && <Typography color='secondary'>{progressBook.error}</Typography>}
        </DialogContent>
        <DialogActions>
          {progressBook.status === 'open' && isPDFToBeGenerated && <Button type='submit' disabled={submitted}>Cloturer</Button>}
          <Button onClick={onClose} color='primary'>Fermer</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const mapStateToPros = (state, props) => ({
  licence: state.firestore.licence && state.firestore.licence[0],
  pilot: state.firestore.users?.find(u => u.id === props.progressBook?.pilotRef.id),
  instructors: state.firestore.users?.filter(u => u.roles?.includes('instructeur')),
})

ClosureDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  progressBook: PropTypes.object.isRequired,
  updateDocAction: PropTypes.func.isRequired,
  licence: PropTypes.object,
  pilot: PropTypes.object.isRequired,
  instructors: PropTypes.array.isRequired,
}

export default compose(
  connect(mapStateToPros, { updateDocAction }),
  withStyles(styles),
)(ClosureDialog)
