import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { FLIGHT_COLLECTION, PB_LESSON_COLLECTION, PROGRESS_BOOK_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { Button, Checkbox, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { useDispatch, useSelector } from 'react-redux'
import CheckPasswordDialog from './CheckPasswordDialog'
import Grader from './Grader'
import clsx from 'clsx'
import { addDocAction, listenCollection } from '../../store/actions/firestoreActions'
import { chain, pickBy } from 'lodash'
import useListener from '../../hooks/useListener'


const styles = theme => ({
  instructions: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(2),
  },
  selectedRow: {
    '& td': {
      borderTop: 'solid black 1px',
      borderBottom: 'solid black 1px',
    },
    '& td:first-child': {
      borderLeft: 'solid black 1px',
    },
    '& td:last-child': {
      borderRight: 'solid black 1px',
    },
  },
  checkbox: {
    paddingLeft: 0,
  },
  cta: {
    width: '40%',
    minWidth: 200,
    margin: '5%',
  },
})

const LessonSetup = ({ classes, openBooks, flight, back, next }) => {
  
  const dispatch = useDispatch()
  const items = useSelector(state => state.firestore.items)
  const lessons = useSelector(state => state.firestore.lessons)
  useListener(() => listenCollection({
    collection: PB_LESSON_COLLECTION,
    where: [['pilotRef', '==', USER_COLLECTION, flight.pilotRef.id]],
    orderBy: [['date', 'asc']],
    storeAs: 'lessons',
  }), [flight?.pilotRef], !!flight?.pilotRef)

  const [selectedBook, selectBook] = useState(openBooks[0])
  const [selectedItemIds, setSelectedItemIds] = useState([])
  const [gradedItems, setGradedItems] = useState([])
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false)
  const [verifiedFI, setVerifiedFI] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const bookLessons = useMemo(() => selectedBook && lessons?.filter(l => l.progressBookRef.id === selectedBook.id), [selectedBook, lessons])
  const itemsWithStatus = useMemo(() => selectedBook && items?.filter(i => i.level === selectedBook.level).map(item => {
    if (bookLessons?.filter(l => l.gradedItems?.some(i => i.id === item.id)).pop()?.gradedItems.find(i => i.id === item.id).grade === 'trainingNeeded')
      return { ...item, status: '●' }
    else if (bookLessons?.some(l => l.gradedItems?.some(i => i.id === item.id && i.grade === item.objective))) return { ...item, status: '✔️' }
    else return { ...item, status: ' ' }
  }), [items, selectedBook, bookLessons])
  const theoreticalItems = useMemo(() => itemsWithStatus && itemsWithStatus.filter(i => i.type === 'theoretical'), [itemsWithStatus])
  const practicalItems = useMemo(() => itemsWithStatus && itemsWithStatus.filter(i => i.type === 'practical'), [itemsWithStatus])

  const handleSubmit = () => {
    setSubmitted(true)
    const progressBookIds = gradedItems.length
      ? chain(gradedItems).map('level').uniq().map(level => openBooks.find(b => b.level === level).id).value()
      : chain(selectedItemIds).map(id => items.find(i => i.id === id).level).uniq().map(level => openBooks.find(b => b.level === level).id).value()
    Promise.all(progressBookIds.map(progressBookId => dispatch(addDocAction(PB_LESSON_COLLECTION, pickBy({
      date: flight.startDate.toDate(),
      gradedItems: gradedItems.length ? gradedItems : [],
      selectedItemIds: !gradedItems.length && selectedItemIds,
      flightRef: [FLIGHT_COLLECTION, flight.id],
      instructorRef: [USER_COLLECTION, flight.instructorRef.id],
      progressBookRef: [PROGRESS_BOOK_COLLECTION, progressBookId],
      draft: gradedItems.length === 0,
      withTheory: true,
      withPractice: true,
    })))))
      .then(() => {
        setSelectedItemIds([])
        setGradedItems([])
        return setVerifiedFI(false)
      })
      .catch(err => console.error(err))
      .finally(() => setSubmitted(false))
  }

  return <>
    <DialogContent>
      {openBooks.length > 1
        ? <FormControl variant='standard'>
          <InputLabel id='book-select-label'>Livret</InputLabel>
          <Select
            variant='standard'
            labelId='book-select-label'
            id='book-select'
            value={selectedBook?.id || ''}
            onChange={e => selectBook(openBooks.find(book => book.id === e.target.value))}>
            {openBooks.map(book => <MenuItem value={book.id} key={book.id}>{book.name}</MenuItem>)}
          </Select>
        </FormControl>
        : <Typography variant='h5'>{openBooks[0]?.name}</Typography>
      }
      <Typography variant='body1' className={classes.instructions}>
        Pour aider {flight.instructor?.firstname} à remplir ton livret, sélectionne les items que vous avez travaillé
      </Typography>
      <Typography variant='h6' className={classes.title}>Items théoriques</Typography>
      <table style={{ borderCollapse: 'collapse' }}>
        <tbody>
          {theoreticalItems?.map(item =>
            <tr key={item.id} className={clsx(selectedItemIds.includes(item.id) && classes.selectedRow)}>
              <td>
                {verifiedFI
                  ? <Grader
                    grade={gradedItems.find(i => i.id === item.id)?.grade}
                    setGrade={val => setGradedItems(prev => [
                      ...prev,
                      {
                        id: item.id,
                        grade: prev.find(i => i.id === item.id)?.grade === val ? null : val,
                        ...items.find(i => i.id === item.id),
                      },
                    ])}
                    objective={item.objective}
                  />
                  : <Checkbox
                    className={classes.checkbox}
                    checked={selectedItemIds.includes(item.id)}
                    onChange={e => e.target.checked
                      ? setSelectedItemIds(val => [...val, item.id])
                      : setSelectedItemIds(val => val.filter(id => id !== item.id))
                    }
                    color='primary'
                  />
                }
              </td>
              <td style={{ textAlign: 'center' }}>{item.status}</td>
              <td>{item.name}</td>
            </tr>,
          )}
        </tbody>
      </table>
      <Typography variant='h6' className={classes.title}>Items pratiques</Typography>
      <table style={{ borderCollapse: 'collapse' }}>
        <tbody>
          {practicalItems?.map(item =>
            <tr key={item.id} className={clsx(selectedItemIds.includes(item.id) && classes.selectedRow)}>
              <td>
                {verifiedFI
                  ? <Grader
                    grade={gradedItems.find(i => i.id === item.id)?.grade}
                    setGrade={val => setGradedItems(prev => [
                      ...prev,
                      {
                        id: item.id,
                        grade: prev.find(i => i.id === item.id)?.grade === val ? null : val,
                        ...items.find(i => i.id === item.id),
                      },
                    ])}
                    objective={item.objective}
                  />
                  : <Checkbox
                    className={classes.checkbox}
                    checked={selectedItemIds.includes(item.id)}
                    onChange={e => e.target.checked
                      ? setSelectedItemIds(val => [...val, item.id])
                      : setSelectedItemIds(val => val.filter(id => id !== item.id))
                    }
                    color='primary'
                  />
                }
              </td>
              <td style={{ textAlign: 'center' }}>{item.status}</td>
              <td>{item.name}</td>
            </tr>,
          )}
        </tbody>
      </table>
      {verifiedFI
        ? <Button className={classes.cta} variant='contained' color='primary' onClick={handleSubmit} disabled={submitted}>
          Valider
        </Button>
        : <>
          <Button className={classes.cta} variant='contained' color='primary' onClick={() => setOpenPasswordDialog(true)} disabled={submitted}>
            Remplir en live
          </Button>
          <Button className={classes.cta} variant='contained' color='primary' onClick={handleSubmit} disabled={submitted}>
            Envoyer le recap à {flight.instructor?.firstname}
          </Button>
        </>
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={back} color='primary'>Retour</Button>
      <Button onClick={next} color='primary'>Fermer</Button>
    </DialogActions>
    {openPasswordDialog && <CheckPasswordDialog instructorRef={flight.instructorRef} onClose={() => setOpenPasswordDialog(false)} setVerifiedFI={setVerifiedFI} />}
  </>
}

LessonSetup.propTypes = {
  classes: PropTypes.object.isRequired,
  openBooks: PropTypes.array.isRequired,
  flight: PropTypes.object.isRequired,
  back: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
)(LessonSetup)
