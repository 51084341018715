import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { compose } from 'recompose'
import { Select, MenuItem, Box } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { addMonths, format } from 'date-fns'
import Loading from '../_library/Loading'
import MUIDataTable from 'mui-datatables'
import EditFlightDialog from './EditFlightDialog'
import clsx from 'clsx'
import { listenFlightsAction } from '../../store/actions/firestoreActions'
import useListener from '../../hooks/useListener'


const styles = () => ({
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
})

const LogbookTable = ({ classes }) => {
  
  const flights = useSelector(state => state.firestore.flights?.filter(f =>
    state.data.selectedUser
      ? f.pilotRef.id === state.data.selectedUser.id || f.instructorRef?.id === state.data.selectedUser.id
      : f.pilotRef.id === state.auth.profile.id || f.instructorRef?.id === state.auth.profile.id,
  ))
  
  const [selectedFlight, selectFlight] = useState(null)
  const [year, setYear] = useState((new Date()).getFullYear())
  const [month, setMonth] = useState((new Date()).getMonth())
  
  useListener(() => listenFlightsAction(year), [year])
  
  const years = useMemo(() => [...Array((new Date()).getFullYear() - 2017 + 1).keys()].map(y => y + 2017), [])
  const months = useMemo(() => [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ], [])
  const filteredFlights = useMemo(
    () => flights?.filter(f => f.startDate.toDate() > new Date(year, month) && f.startDate.toDate() < addMonths(new Date(year, month), 1)),
    [flights, year, month],
  )
  const data = useMemo(
    () => filteredFlights?.map( val => {
      const { startDate, aircraft, pilot, instructor, flightType, duration, comments, landings } = val
      return [
        format(startDate.toDate(), 'dd-MM-yyyy HH:mm'),
        aircraft && aircraft.name,
        pilot ? (pilot.firstname + ' ' + pilot.lastname) : '',
        instructor ? (instructor.firstname + ' ' + instructor.lastname) : '',
        flightType && flightType.name,
        duration.toFixed(2),
        landings,
        comments || '',
      ]
    }),
    [filteredFlights],
  )
  
  if (!flights) return <Loading />
  return (
    <Box sx={{ m: { xs: 0, sm: 2 } }}>
      <Select
        variant='standard'
        id='year'
        value={year}
        onChange={e => setYear(e.target.value)}
        sx={{ ml: 2, mb: { xs: 0, sm: 2 } }}
      >
        {years.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
      </Select>
      <Select
        variant='standard'
        id='month'
        value={month}
        onChange={e => setMonth(e.target.value)}
        sx={{ ml: 2, mb: { xs: 0, sm: 2 } }}
      >
        {months.map((month, key) => <MenuItem key={key} value={key}>{month}</MenuItem>)}
      </Select>
      <MUIDataTable
        data={data}
        columns={[
          { name: 'Date', options: { filter: true, sort: true } },
          { name: 'Avion', options: { filter: true, sort: true } },
          { name: 'Pilote', options: { filter: true, sort: true } },
          { name: 'Instructeur', options: { filter: true, sort: true } },
          { name: 'Type de vol', options: { filter: true, sort: true } },
          { name: 'Durée', options: { filter: true, sort: true } },
          { name: 'Atterrissages', options: { filter: true, sort: true } },
          { name: 'Commentaires', options: { filter: true, display: 'false' } },
        ]}
        options={{
          downloadOptions: {
            filename: `flights-${year}-${month}.csv`,
            filterOptions: {
              useDisplayedColumnsOnly: false,
              useDisplayedRowsOnly: true,
            },
          },
          onDownload: (buildHead, buildBody, columns, data) => {
            let res = ''
            data.forEach(row => {
              const model = (immat => {
                switch (immat) {
                  case 'F-HXAL': return 'EA330'
                  case 'F-BXHU': return 'CAP10'
                  case 'F-GLJG': return 'E200'
                  default: return ''
                }
              })(row.data[1])
              const fonction = row.data[3] === 'Romain COEUR' ? 'I' : 'P'
              const type = (flightType => {
                if (flightType.includes('Entrainement')) return 'voltige'
                else return flightType
              })(row.data[4])
              const duration = Math.trunc(row.data[5]) + ':' + ('0' + Math.round((row.data[5] % 1) * 60)).slice(-2)
              res += `${row.data[0].slice(0, 10)},Avion,${model},${row.data[1]},${fonction},${type},,,${duration}\n`
            })
            return res
          },
          selectableRows: 'none',
          search: false,
          onRowClick: (row, { dataIndex }) => !filteredFlights[dataIndex].validated && selectFlight(dataIndex),
          rowHover: false,
          setRowProps: (row, dataIndex) => ({
            className: clsx(!filteredFlights[dataIndex].validated && classes.clickable),
          }),
        }}
      />
      {selectedFlight !== null && <EditFlightDialog flight={filteredFlights[selectedFlight]} onClose={() => selectFlight(null)} />}
    </Box>
  )
}

LogbookTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
)(LogbookTable)
