import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, CircularProgress, Button, Box } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { format, formatDistanceToNow, differenceInDays } from 'date-fns'
import DeleteButton from '../_library/DeleteButton'
import { Check, Clear, Edit } from '@mui/icons-material'
import { deleteQualificationAction, updateQualificationAction } from '../../store/actions/qualifcationActions'
import EditQualificationDialog from './EditQualificationDialog'
import useListener from '../../hooks/useListener'
import { listenCollection } from '../../store/actions/firestoreActions'
import { QUALIFICATION_COLLECTION, STATUS_ACTIVATED } from '../../_constants/globals'
import { pick } from 'lodash'
import { getQualificationStatus } from '../../_helpers/qualificationHelper'
import QualificationIcon from '../_library/QualificationIcon'
import { useLocation } from 'react-router'
import { parseParams } from '../../_helpers/urlHelper'


const STORAGE_KEY = 'allQualificationsTableState'
const AllQualificationsTable = () => {
  
  const profile = useSelector(state => state.auth.profile)
  const qualifications = useSelector(state => state.firestore.qualifications)
  const dispatch = useDispatch()
  const location = useLocation()
  useListener(() => listenCollection({
    collection: QUALIFICATION_COLLECTION,
    where: [['archived', '!=', true]],
    orderBy: [
      ['archived', 'asc'],
      ['_createdAt', 'desc'],
    ],
  }), [profile.id])
  
  const [editQualification, setEditQualification] = useState(null)
  
  const alertFilter = useMemo(() => parseParams(location.search).filter, [location.search])
  
  // Select only the first element for each typeRef
  // The input must be an array sorted by desc _createdAt
  const data = useMemo(
    () => qualifications?.map(val => {
      const { id, user, type, identValue, grantDate, expireDate, attachmentUrl, checkingComment, checker } = val
      return [
        id,
        getQualificationStatus(val),
        user,
        user?.status,
        type?.shortName,
        identValue,
        grantDate,
        expireDate,
        attachmentUrl,
        checkingComment,
        checker,
        val,
      ]
    }), [qualifications])
  
  const localState = window.localStorage.getItem(STORAGE_KEY) &&
    JSON.parse(window.localStorage.getItem(STORAGE_KEY))
  
  return (
    <Box sx={{
      m: 2,
      '& .MuiTableCell-body': {
        fontSize: '0.9rem',
        p: 1,
      },
    }}>
      {!data
        ? <Grid container item xs={12} justifyContent='center' sx={{ mt: '30px' }}>
          <CircularProgress size={20} />
        </Grid>
        
        : <MUIDataTable
          title='Validités'
          data={data}
          columns={[
            { name: 'id', options: { filter: false, sort: false, display: 'false' } },
            {
              name: 'Alerte',
              options: {
                filter: true,
                sort: true,
                customBodyRender: status => <QualificationIcon qualificationStatus={status} />,
                filterType: 'multiselect',
                filterList: alertFilter && [alertFilter] || localState?.filterList[1] || [],
              },
            },
            {
              name: 'Pilote',
              options: {
                customBodyRender: u => u?.firstname + ' ' + u?.lastname,
                filterList: localState?.filterList[2] || [],
              },
            },
            { name: 'Status', options: { filterList: [STATUS_ACTIVATED], filterType: 'multiselect' } },
            { name: 'Type', options: { filter: true, sort: true, filterList: localState?.filterList[4] || [], filterType: 'multiselect' } },
            { name: 'Identifiant', options: { filter: false, sort: false, display: 'false' } },
            {
              name: 'Obtention',
              options: {
                filter: false,
                sort: true,
                display: 'false',
                customBodyRender: date => date && format(date.toDate(), 'dd-MM-yyyy'),
              },
            },
            {
              name: 'Limite de validité',
              options: {
                filter: false,
                sort: true,
                customBodyRender: date => {
                  if (!date) return null
                  let expires
                  const differenceInCalendarDays = date &&  differenceInDays(date.toDate(), new Date())
                  if (!date) expires = ''
                  else if (differenceInCalendarDays < 0) expires = '️⛔ expired'
                  else if (differenceInCalendarDays > 0 && differenceInCalendarDays < 60) expires = '⚠️ expires'
                  else expires = '✅️ expires'
                  return expires + ' ' + formatDistanceToNow(date.toDate(), { addSuffix: true }) + ' (' + format(date.toDate(), 'dd-MM-yyyy') + ')'
                },
              },
            },
            {
              name: 'Document',
              options: {
                filter: false,
                sort: true,
                // eslint-disable-next-line react/display-name
                customBodyRender: url => url && <a href={url} target='_blank' rel='noopener noreferrer' >Preview</a>,
              },
            },
            { name: 'Commentaire', options: { filter: false, sort: true, display: 'false' } },
            { name: 'Checker', options: { filter: true, sort: true, display: 'false', customBodyRender: u => u && u.firstname + ' ' + u.lastname } },
            {
              name: 'Actions',
              options: {
                filter: false,
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: qualification =>
                  <>
                    <DeleteButton deleteAction={() => dispatch(deleteQualificationAction(qualification.id))} />
                    <Button
                      onClick={() => setEditQualification(qualification)}
                      title='modifier la validité'
                      sx={{ minWidth: '40px' }}
                    >
                      <Edit />
                    </Button>
                    {profile.roles.includes('admin') && // eslint-disable-line react/prop-types
                      <Button
                        onClick={() => dispatch(updateQualificationAction(qualification.id, {
                          checkerId: profile.id,
                        }))}
                        title='vérifier la validité'
                        sx={{ minWidth: '40px' }}
                      >
                        {qualification.checkerRef ? <Clear /> : <Check /> }
                      </Button>
                    }
                  </>,
              },
            },
          ]}
          options={{
            selectableRows: 'none',
            onTableChange: (action, tableState) => {
              if (action === 'propsUpdate') return
              window.localStorage.setItem(STORAGE_KEY, JSON.stringify(pick(tableState, ['columns', 'filterList', 'sortOrder', 'rowsPerPage'])))
            },
          }}
        />
      }
      {editQualification && <EditQualificationDialog onClose={() => setEditQualification(null)} qualification={editQualification} />}
    </Box>
  )
}

export default AllQualificationsTable
