import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { formatDistanceToNowStrict, startOfDay, isValid, addHours, startOfToday } from 'date-fns'
import DeleteButton from '../_library/DeleteButton'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { deleteDocAction, updateDocAction } from '../../store/actions/firestoreActions'
import { AVAILABILITY_COLLECTION } from '../../_constants/globals'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'


const styles = () => ({
  formField: {
    marginBottom: '1rem',
    backgroundColor: 'white',
    padding: '1rem',
  },
  label: {
    color: 'rgba(0,0,0,.54)',
    display: 'inline-block',
    lineHeight: '20px',
    width: 200,
  },
})

const AvailabilityItem = ({ classes, item, updateDocAction, deleteDocAction }) => {

  const [submitted, setSubmitted] = useState(false)

  const update = (id, val) => {
    setSubmitted(true)
    updateDocAction(AVAILABILITY_COLLECTION, id, val)
      .then(() => setSubmitted(false))
  }

  return <Grid item xs={12} className={classes.formField}>
    <Typography className={classes.label} color='textSecondary'>
      {item.day && formatDistanceToNowStrict(item.day.toDate(), { unit: 'day', addSuffix: true }).replace('0 days ago', 'today')}
    </Typography>
    <DatePicker
      inputFormat='dd-MM-yyyy'
      margin='dense'
      value={item.day ? item.day.toDate() : new Date()}
      onChange={val => isValid(val) && update(item.id, {
        day: startOfDay(val),
        start: new Date(item.start.toDate().setFullYear(val.getFullYear(), val.getMonth(), val.getDate())),
        end: new Date(item.end.toDate().setFullYear(val.getFullYear(), val.getMonth(), val.getDate())),
      })}
      slotProps={{
        textField: { sx: { mr: 1, width: '150px' } },
      }}
    />
    <TimePicker
      variant='inline'
      ampm={false}
      margin='dense'
      value={item.start ? item.start.toDate() : new Date()}
      onChange={val => isValid(val) && update(item.id, { start: val })}
      minutesStep={15}
      minTime={addHours(startOfToday(), 8)}
      maxTime={addHours(startOfToday(), 19)}
      slotProps={{
        textField: { sx: { mr: 1, width: '110px' } },
      }}
    />
    <TimePicker
      variant='inline'
      ampm={false}
      margin='dense'
      value={item.end ? item.end.toDate() : new Date()}
      onChange={val => isValid(val) && update(item.id, { end: val })}
      minutesStep={15}
      minTime={addHours(startOfToday(), 9)}
      maxTime={addHours(startOfToday(), 20)}
      slotProps={{
        textField: { sx: { mr: 1, width: '110px' } },
      }}
    />
    {submitted
      ? <CircularProgress size={22} />
      : <DeleteButton deleteAction={() => deleteDocAction(AVAILABILITY_COLLECTION, item.id)} />
    }
  </Grid>

}

AvailabilityItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  updateDocAction: PropTypes.func.isRequired,
  deleteDocAction: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  connect(null, { updateDocAction, deleteDocAction }),
)(AvailabilityItem)
