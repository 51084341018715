import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, DialogActions, DialogContent, Grid, DialogTitle, Dialog, CircularProgress, Typography,
  Autocomplete, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ACCOUNT_COLLECTION, ACCOUNT_ENTRY_COLLECTION, ACCOUNT_FLUX_COLLECTION } from '../../_constants/globals'
import DeleteButton from '../_library/DeleteButton'
import { addDocAction, deleteDocAction, getDocAction, listenCollection, updateDocAction } from '../../store/actions/firestoreActions'
import { omit } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers'
import useListener from '../../hooks/useListener'
import Loading from '../_library/Loading'


export const FluxUpdateDialog = ({ open, onClose, fluxId }) => {
  
  const accounts = useSelector(state => state.firestore.accounts?.map(a => omit(a, ['ownerRef'])))
  const entries = useSelector(state => state.firestore.editFluxEntries)
  const dispatch = useDispatch()
  useListener(() => listenCollection({
    collection: ACCOUNT_ENTRY_COLLECTION,
    where: [['fluxRef', '==', ACCOUNT_FLUX_COLLECTION, fluxId]],
    storeAs: 'editFluxEntries',
  }), [fluxId])
  
  const [comment, setComment] = useState(null)
  const [accountDate, setAccountDate] = useState(new Date())
  
  const balanced = useMemo(() => entries?.reduce((acc, val) => acc + (val.credit || 0) - (val.debit || 0), 0) === 0, [entries])
  const valid = useMemo(() => !entries?.some(el => !el.accountRef), [entries])
  
  useEffect(() => {
    dispatch(getDocAction(ACCOUNT_FLUX_COLLECTION, fluxId)).then(flux => {
      flux?.comment && setComment(flux.comment) // eslint-disable-line
      flux?.accountDate && setAccountDate(flux.accountDate?.toDate()) // eslint-disable-line
    })
  }, [fluxId])
  
  const handleSubmit = () => dispatch(updateDocAction(ACCOUNT_FLUX_COLLECTION, fluxId, { balanced, comment, accountDate })).then(onClose)
  
  if (!entries) return <Loading />
  else return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id='form-dialog-title'>Editer un flux</DialogTitle>
      <DialogContent>
        {!accounts
          ? <CircularProgress color='inherit' />
          : <Grid container spacing={3}>
            {entries.filter(e => e.debit !== undefined).map((entry, index) =>
              <Grid item container xs={12} key={index} spacing={2} style={{ alignItems: 'center' }}>
                <Grid item xs={6}>
                  <Autocomplete
                    id='debit-select'
                    options={accounts}
                    getOptionLabel={option => option.name}
                    renderInput={params =>
                      <TextField variant='standard' {...params} label='Compte à débiter' />
                    }
                    value={accounts.find(a => a.id === entry.accountRef?.id) || ''}
                    onChange={(event, newValue) =>
                      dispatch(updateDocAction(ACCOUNT_ENTRY_COLLECTION, entry.id, {
                        accountRef: [ACCOUNT_COLLECTION, newValue.id],
                      }))
                    }
                    renderOption={(props, option) => <li {...props} key={'debit-' + option.id}>{option.name}</li>}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='standard'
                    margin='dense'
                    id='amount'
                    label='Montant'
                    type='number'
                    value={entry.debit}
                    onChange={e => {
                      e.persist()
                      dispatch(updateDocAction(ACCOUNT_ENTRY_COLLECTION, entry.id, {
                        debit: Number(e.target.value),
                      }))
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <DeleteButton deleteAction={() => dispatch(deleteDocAction(ACCOUNT_ENTRY_COLLECTION, entry.id))} />
                </Grid>
              </Grid>,
            )}
            <Typography variant='caption' m={2}>Total = {entries.reduce((acc, val) => acc + (val.debit || 0), 0)}</Typography>
            <Grid item xs={12}>
              <Button onClick={() => dispatch(addDocAction(ACCOUNT_ENTRY_COLLECTION, {
                fluxRef: [ACCOUNT_FLUX_COLLECTION, fluxId],
                debit: 0,
                accountDate,
              }))}>Ajouter un compte à débiter</Button>
            </Grid>
            {entries.filter(e => e.credit !== undefined).map((entry, index) =>
              <Grid item container xs={12} key={index} spacing={2} style={{ alignItems: 'center' }}>
                <Grid item xs={6}>
                  <Autocomplete
                    id='credit-select'
                    options={accounts}
                    getOptionLabel={option => option.name}
                    renderInput={params =>
                      <TextField variant='standard' {...params} label='Compte à créditer' />
                    }
                    value={accounts.find(a => a.id === entry.accountRef?.id) || ''}
                    onChange={(event, newValue) =>
                      dispatch(updateDocAction(ACCOUNT_ENTRY_COLLECTION, entry.id, {
                        accountRef: [ACCOUNT_COLLECTION, newValue.id],
                      }))
                    }
                    renderOption={(props, option) => <li {...props} key={'debit-' + option.id}>{option.name}</li>}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='standard'
                    margin='dense'
                    id='amount'
                    label='Montant'
                    type='number'
                    value={entry.credit}
                    onChange={e => {
                      e.persist()
                      dispatch(updateDocAction(ACCOUNT_ENTRY_COLLECTION, entry.id, { credit: Number(e.target.value) }))
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <DeleteButton deleteAction={() => dispatch(deleteDocAction(ACCOUNT_ENTRY_COLLECTION, entry.id))} />
                </Grid>
              </Grid>,
            )}
            <Typography variant='caption' m={2}>Total = {entries.reduce((acc, val) => acc + (val.credit || 0), 0)}</Typography>
            <Grid item xs={12}>
              <Button onClick={() => dispatch(addDocAction(ACCOUNT_ENTRY_COLLECTION, {
                fluxRef: [ACCOUNT_FLUX_COLLECTION, fluxId],
                credit: 0,
                accountDate,
              }))}>Ajouter un compte à créditer</Button>
            </Grid>
            <Grid item xs={12}>
              <Typography display='inline-flex' mr={1}>Flux à l&apos;équilibre</Typography>
              <ToggleButtonGroup
                color='primary'
                value={balanced}
                exclusive
              >
                <ToggleButton disabled value={true} sx={{
                  p: 2,
                  '&.Mui-disabled': {
                    color: 'black',
                  },
                  '&.Mui-selected': {
                    bgcolor: 'success.main',
                  },
                }}>Oui</ToggleButton>
                <ToggleButton disabled value={false} sx={{
                  p: 2,
                  '&.Mui-disabled': {
                    color: 'black',
                  },
                  '&.Mui-selected': {
                    bgcolor: 'error.main',
                  },
                }}>Non</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                disableToolbar
                variant='inline'
                inputFormat='dd-MM-yyyy'
                margin='dense'
                id='startDate-date'
                label='Date du transfer'
                value={accountDate}
                onChange={setAccountDate}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='standard'
                margin='dense'
                id='comment'
                label='Commentaire'
                fullWidth
                value={comment || ''}
                multiline
                onChange={e => setComment(e.target.value)} />
            </Grid>
          </Grid>
        }
      </DialogContent>
      <DialogActions>
        <DeleteButton text='Supprimer' deleteAction={() => dispatch(deleteDocAction(ACCOUNT_FLUX_COLLECTION, fluxId)).then(onClose)} />
        <Button onClick={onClose} >Annuler</Button>
        <Button onClick={handleSubmit} color='primary' disabled={!valid}>Valider</Button>
      </DialogActions>
    </Dialog>
  )
}

FluxUpdateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fluxId: PropTypes.string.isRequired,
}

export default FluxUpdateDialog
