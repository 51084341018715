import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import {
  CardActions, FormControl, MenuItem, Select, Typography, Card, CardContent, CardHeader, Avatar, CardMedia, Grid,
  Button, TextField,
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import { updateQualificationAction } from '../../store/actions/qualifcationActions'
import InlineEditor from '../_library/InlineEditor'
import { QUALIFICATION_SUBTYPES, QUALIFICATION_TYPES } from '../../_constants/globals'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import * as _ from 'lodash'
import { red, green } from '@mui/material/colors'
import { DatePicker } from '@mui/x-date-pickers'


const styles = () => ({
  root: {
    margin: '2rem auto',
    maxWidth: 900,
  },
  cardTitle: {
    marginBottom: '1rem',
  },
  card: {
    minWidth: 275,
    marginBottom: '2rem',
  },
  formField: {
    marginBottom: '1rem',
  },
  label: {
    color: 'rgba(0,0,0,.54)',
    display: 'inline-block',
    lineHeight: '20px',
    width: 200,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    cursor: 'zoom-in',
    '& canvas': {
      margin: 'auto',
    },
  },
  pdfPageBtn: {
    textAlign: 'center',
    '& p': {
      display: 'inline-block',
    },
  },
  title: {
    color: 'rgba(0,0,0,.54)',
    display: 'inline-block',
    lineHeight: '20px',
    width: 200,
  },
})

const getQualificationFields = type => {
  switch (type) {
    case QUALIFICATION_TYPES.LICENSE.shortName:
      return [
        { id: 'subtype',
          type: 'select',
          values: QUALIFICATION_SUBTYPES.filter(sub => sub.type === QUALIFICATION_TYPES.LICENSE.id),
        },
        { id: 'identValue', type: 'string' },
        { id: 'attachmentUrl', type: 'file' },
        { id: 'grantDate', type: 'date' },
      ]
    case QUALIFICATION_TYPES.SEP.shortName:
      return [
        { id: 'expireDate', type: 'date' },
        { id: 'attachmentUrl', type: 'file' },
      ]
    case QUALIFICATION_TYPES.VISITE.shortName:
      return [
        { id: 'subtype',
          type: 'select',
          values: QUALIFICATION_SUBTYPES.filter(sub => sub.type === QUALIFICATION_TYPES.VISITE.id),
        },
        { id: 'expireDate', type: 'date' },
        { id: 'grantDate', type: 'date' },
        { id: 'attachmentUrl', type: 'file' },
      ]
    case QUALIFICATION_TYPES.FFA.shortName:
      return [
        { id: 'identValue', type: 'string' },
        { id: 'club', type: 'string' },
        { id: 'expireDate', type: 'date' },
        { id: 'attachmentUrl', type: 'file' },
      ]
    case QUALIFICATION_TYPES.FCL800.shortName:
    case QUALIFICATION_TYPES.BFV1.shortName:
    case QUALIFICATION_TYPES.BFV2.shortName:
    case QUALIFICATION_TYPES.BFV3.shortName:
      return [
        { id: 'attachementUrl', type: 'file' },
      ]
    default:
      return []
  }
}

const getExtension = url => {
  const urlObject = new URL(url)
  const filename = urlObject.pathname.split('/').pop()
  return filename.split('.')[1].toLowerCase()
}

const QualificationCard = ({ classes, qualification, updateQualificationAction, profile }) => {
  
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  
  return (
    <Card className={classes.card} variant='outlined'>
      <CardHeader
        avatar={
          <Avatar aria-label={qualification.type?.name} style={{
            backgroundColor: qualification.checkerRef ? green[500] : red[500],
          }}>V</Avatar>
        }
        title={qualification.type?.name}
        subheader={qualification.user?.firstname + ' ' + qualification.user?.lastname}
      />
      {qualification.attachmentUrl
        && ['jpg', 'jpeg', 'svg', 'png'].includes(getExtension(qualification.attachmentUrl))
        && <a href={qualification.attachmentUrl} target='_blank' rel='noopener noreferrer'>
          <CardMedia
            className={classes.media}
            image={qualification.attachmentUrl}
            title='attachment'
          />
        </a>}
      {qualification.attachmentUrl
        && (getExtension(qualification.attachmentUrl) === 'pdf')
        && <CardMedia
          component={() => <>
            <a href={qualification.attachmentUrl} target='_blank' className={classes.media} rel='noopener noreferrer'>
              <Document file={qualification.attachmentUrl} onLoadSuccess={({ numPages }) => setNumPages(numPages)} >
                <Page pageNumber={pageNumber} />
              </Document>
            </a>
            <div className={classes.pdfPageBtn}>
              <Button disabled={pageNumber <= 1} onClick={() => setPageNumber(num => num - 1)}><ChevronLeft /></Button>
              <p>Page {pageNumber} of {numPages}</p>
              <Button disabled={pageNumber >= numPages} onClick={() => setPageNumber(num => num + 1)}><ChevronRight /></Button>
            </div>
          </>}
          title='attachment'
        />}
      <CardContent>
        {qualification.type && getQualificationFields(qualification.type.shortName).map(field =>
          <Grid item xs={12} className={classes.formField} key={field.id}>
            {field.type === 'string' && <InlineEditor title={field.id} value={qualification[field.id]} handleSubmit={val => updateQualificationAction(qualification.id, { [field.id]: val })} />}
            {field.type === 'select' && <>
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                {field.id}
              </Typography>
              <FormControl variant='outlined'>
                <Select
                  variant='standard'
                  value={_.find(QUALIFICATION_SUBTYPES,  { id: qualification.subtype }) || 'no'}
                  onChange={e => updateQualificationAction(qualification.id, { [field.id]: e.target.value.id })}
                  margin='dense'>
                  <MenuItem value='no'><em>Aucune license</em></MenuItem>
                  {field.values.map(val => <MenuItem key={val.id} value={val}>{val.label}</MenuItem>)}
                </Select>
              </FormControl>
            </>}
            {field.type === 'date' && <>
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                {field.id}
              </Typography>
              <DatePicker
                inputFormat='dd-MM-yyyy'
                margin='dense'
                id={qualification.type.shortName + '-' + field.id}
                value={qualification[field.id]?.toDate() || null}
                onChange={(val, str) => /\d{2}-\d{2}-\d{4}/g.test(str) && updateQualificationAction(qualification.id, { [field.id]: val })}
                renderInput={params => <TextField {...params} />}
              />
            </>}
          </Grid>,
        )}
      </CardContent>
      <CardActions style={{ float: 'right' }}>
        {qualification.checkingDate
          ? <Button size='large' color='secondary' variant='contained' onClick={() => updateQualificationAction(qualification.id, { checkerId: null })}>
            Invalider
          </Button>
          : <Button
            size='large'
            color='primary'
            variant='contained'
            onClick={() => updateQualificationAction(qualification.id, { checkerId: profile.id })}
          >
            Valider
          </Button>
        }
      </CardActions>
    </Card>
  )
}

const mapStateToProps = state => ({
  profile: state.auth.profile,
})

QualificationCard.propTypes = {
  classes: PropTypes.object.isRequired,
  qualification: PropTypes.object.isRequired,
  updateQualificationAction: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { updateQualificationAction }),
)(QualificationCard)
