import { USER_COLLECTION } from '../../_constants/globals'
import { INVITE_MEMBER_ERROR, INVITE_MEMBER_SUCCESS } from '../types'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { collection, addDoc } from 'firebase/firestore'
import { updateDocAction } from './firestoreActions'

/**
 * @deprecated
 */
export const updateUserAction = (userId, updatedUser) => updateDocAction(USER_COLLECTION, userId, updatedUser)

// https://firebase.google.com/docs/storage/web/start
export const uploadProfilePicture = (file, uid) => (dispatch, getState, { storage }) =>
  uploadBytesResumable(ref(storage, `profile-pictures/${uid}-${(new Date()).getMilliseconds()}.${file.name.split('.').pop()}`), file)

/**
 * Creates a new user and sends invite email
 *
 * @param {object} user
 */
export const inviteMemberAction = user => (dispatch, getState, { db }) =>
  addDoc(collection(db, USER_COLLECTION), user)
    .then(() => dispatch({ type: INVITE_MEMBER_SUCCESS }))
    .catch(err => {
      console.error(err)
      dispatch({ type: INVITE_MEMBER_ERROR })
    })
