import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete } from '@mui/material'
import Avatar from './Avatar'
import { createFilterOptions } from '@mui/material/useAutocomplete'
import { useDispatch, useSelector } from 'react-redux'
import Loading from './Loading'
import { addDocAction } from '../../store/actions/firestoreActions'
import { STATUS_VISITOR, USER_COLLECTION } from '../../_constants/globals'
import { AddCircleOutlined } from '@mui/icons-material'


const filter = createFilterOptions()
const UserAutocomplete = ({ sx, users: propUsers, user, set, disabled, label, freeSolo, ...props }) => {
  
  const dispatch = useDispatch()
  const users = useSelector(state => propUsers || state.firestore.users || [])
  
  const [open, setOpen] = useState(false)
  const [dialogValue, setDialogValue] = useState({})

  const handleSubmit = event => {
    event.preventDefault()
    dispatch(addDocAction(USER_COLLECTION, { ...dialogValue, status: STATUS_VISITOR }, true))
      .then(userRef => {
        set({
          id: userRef.id,
          status: STATUS_VISITOR,
          ...dialogValue,
        })
        setDialogValue({})
        return setOpen(false)
      })
  }
  
  if (!users) return <Loading />
  else return <>
    <Autocomplete
      options={users}
      getOptionLabel={option => {
        if (typeof option === 'string') return option
        if (option.inputValue) return option.inputValue
        else return option.firstname + ' ' + option.lastname
      }}
      renderOption={(props, option) => <li {...props} key={label + option.id}>
        {option.firstname === 'Créer'
          ? <AddCircleOutlined color='secondary' sx={{ mr: 1, width: '35px', height: '35px', display: 'inline-block', verticalAlign: 'middle' }} />
          : <Avatar user={option} sx={{ mr: 1 }} />
        }
        {option.firstname + ' ' + option.lastname}
      </li>}
      renderInput={params => <TextField variant='standard' {...params} label={label} />}
      value={user || null}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string' && freeSolo) {
          // timeout to avoid instant validation of the dialog's form.
          setTimeout(() => {
            setOpen(true)
            setDialogValue({
              firstname: newValue.split(' ')[0],
              lastname: newValue.split(' ')[1] || '',
            })
          })
        } else if (newValue && newValue.inputValue && freeSolo) {
          setOpen(true)
          setDialogValue({
            firstname: newValue.inputValue.split(' ')[0],
            lastname: newValue.inputValue.split(' ')[1] || '',
          })
        } else {
          set(newValue)
        }
      }}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        if (params.inputValue !== '' && freeSolo && !users.some(u => u.firstname + ' ' + u.lastname === params.inputValue)) {
          filtered.push({
            inputValue: params.inputValue,
            firstname: 'Créer',
            lastname: params.inputValue,
          })
        }
        return filtered
      }}
      onInputChange={(event, inputValue) => {
        const user = users.find(u => u.firstname + ' ' + u.lastname === inputValue)
        if (user) set(user)
      }}
      sx={{
        display: 'inline-flex',
        minWidth: 200,
        ...sx,
      }}
      {...props}
    />
    <Dialog open={open} onClose={() => setOpen(false)}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Créer un utilisateur</DialogTitle>
        <DialogContent>
          <TextField
            variant='standard'
            autoFocus
            id='firstname'
            value={dialogValue.firstname || ''}
            onChange={event =>
              setDialogValue({
                ...dialogValue,
                firstname: event.target.value,
              })
            }
            label='Prénom'
            type='text'
            style={{ marginRight: 8 }} />
          <TextField
            variant='standard'
            id='lastname'
            value={dialogValue.lastname || ''}
            onChange={event =>
              setDialogValue({
                ...dialogValue,
                lastname: event.target.value,
              })
            }
            label='Nom'
            type='text' />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Fermer</Button>
          <Button type='submit' color='primary'>Créer</Button>
        </DialogActions>
      </form>
    </Dialog>
  </>
}

UserAutocomplete.propTypes = {
  sx: PropTypes.object,
  users: PropTypes.array,
  user: PropTypes.object,
  set: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  freeSolo: PropTypes.bool,
}

export default UserAutocomplete
