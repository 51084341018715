import React from 'react'
import { Typography, Card, Button, CardActions, CardContent, Skeleton, CardHeader } from '@mui/material'
import { AssignmentTurnedIn } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { formatDistanceToNow } from 'date-fns'
import { fr } from 'date-fns/locale'
import { useNavigate } from 'react-router'
import { PROGRESS_BOOKS } from '../../_constants/routes'
import CompletenessGraph from '../progressBooks/CompletenessGraph'
import NextItems from '../progressBooks/NextItems'


export const ProgressionCard = () => {
  
  const openBooks = useSelector(state => state.firestore.progressBooks?.filter(b => b.status === 'open'))
  const navigate = useNavigate()

  if (openBooks === undefined) return <Skeleton height={460} variant='rectangular' sx={{ borderRadius: 1 }} />
  else return (
    <Card sx={{ minWidth: 275, mb: 2, p: 1, position: 'relative', height: 460 }}>
      <CardHeader avatar={<AssignmentTurnedIn />} title={openBooks[0].name} sx={{ color: 'grey.600' }} />
      <CardContent>
        {openBooks?.length === 0
          ? 'Tu n\'as pas de livret ouvert pour le moment. Clique sur le lien ci-dessous pour afficher la liste des livrets disponibles'
          : <>
            <Typography>Livret ouvert depuis {formatDistanceToNow(openBooks[0]._createdAt.toDate(), { locale: fr })}</Typography>
            <Typography mt={2}>Complétion</Typography>
            <CompletenessGraph completeness={openBooks[0].completeness} />
            <Typography mt={2}>Prochaines figures :</Typography>
            <NextItems progressBookId={openBooks[0].id} />
          </>
        }
      </CardContent>
      <CardActions sx={{ position: 'absolute', bottom: theme => theme.spacing(.5), width: '100%', bgcolor: 'white' }}>
        <Button color='primary' onClick={() => navigate(PROGRESS_BOOKS + '/open')}>
          Livret de progression
        </Button>
      </CardActions>
    </Card>
  )
}

export default ProgressionCard
