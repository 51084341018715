import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import {
  Button,
  CardActions,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  TextField,
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import { updateDocAction } from '../../store/actions/firestoreActions'
import { USER_COLLECTION } from '../../_constants/globals'


const styles = () => ({
  root: {
    margin: '2rem auto',
    maxWidth: '900px',
  },
  cardTitle: {
    marginBottom: '1rem',
  },
  card: {
    minWidth: 275,
    marginBottom: '2rem',
  },
  formField: {
    marginBottom: '1rem',
  },
  label: {
    color: 'rgba(0,0,0,.54)',
    display: 'inline-block',
    lineHeight: '20px',
    width: '200px',
  },
})

const CoordinatesForm = ({ classes, profile, updateDocAction, next, back }) => {

  const [phone, setPhone] = useState(profile.phone)
  const [street, setStreet] = useState(profile.address?.street)
  const [zipcode, setZip] = useState(profile.address?.zipcode)
  const [city, setCity] = useState(profile.address?.city)
  const [country, setCountry] = useState(profile.address?.country || 'France')
  const [errors, setErrors] = useState({})

  const handleSubmit = () => {
    setErrors({})
    if (!phone) setErrors(err => ({ ...err, phone: 'Le numéro de téléphone doit être rempli' }))
    if (!street) setErrors(err => ({ ...err, street: 'Le champ doit être rempli' }))
    if (!zipcode) setErrors(err => ({ ...err, zipcode: 'Le champ doit être rempli' }))
    if (!city) setErrors(err => ({ ...err, city: 'Le champ doit être rempli' }))
    if (!country) setErrors(err => ({ ...err, country: 'Le champ doit être rempli' }))
    if (phone
      && street
      && zipcode
      && city
      && country
    )
      updateDocAction(USER_COLLECTION, profile.id, { phone, address: { street, zipcode, city, country } })
        .then(next)
  }

  return (
    <Card className={classes.root} variant='outlined'>
      <CardContent>
        <Grid item xs={12} className={classes.formField}>
          <Typography className={classes.label} color='textSecondary'>
            Téléphone
          </Typography>
          <TextField
            variant='standard'
            value={phone || ''}
            onChange={e => setPhone(e.target.value)}
            error={Boolean(errors.phone)}
            helperText={errors.phone} />
        </Grid>
        <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
        <Typography variant='body1'>Adresse</Typography>
        <Grid item xs={12} className={classes.formField}>
          <Typography className={classes.label} color='textSecondary'>
            Numéro et rue
          </Typography>
          <TextField
            variant='standard'
            value={street || ''}
            onChange={e => setStreet(e.target.value)}
            error={Boolean(errors.street)}
            helperText={errors.street} />
        </Grid>
        <Grid item xs={12} className={classes.formField}>
          <Typography className={classes.label} color='textSecondary'>
            Code postal
          </Typography>
          <TextField
            variant='standard'
            value={zipcode || ''}
            onChange={e => setZip(e.target.value)}
            error={Boolean(errors.zipcode)}
            helperText={errors.zipcode} />
        </Grid>
        <Grid item xs={12} className={classes.formField}>
          <Typography className={classes.label} color='textSecondary'>
            Ville
          </Typography>
          <TextField
            variant='standard'
            value={city || ''}
            onChange={e => setCity(e.target.value)}
            error={Boolean(errors.city)}
            helperText={errors.city} />
        </Grid>
        <Grid item xs={12} className={classes.formField}>
          <Typography className={classes.label} color='textSecondary'>
            Pays
          </Typography>
          <TextField
            variant='standard'
            value={country}
            onChange={e => setCountry(e.target.value)}
            error={Boolean(errors.country)}
            helperText={errors.country} />
        </Grid>
      </CardContent>
      <CardActions style={{ float: 'right' }}>
        <Button size='large' onClick={back}>
          Précédent
        </Button>
        <Button size='large' color='primary' onClick={handleSubmit}>
          Valider
        </Button>
      </CardActions>
    </Card>
  )
}

const mapStateToProps = state => ({
  profile: state.auth.profile,
})

CoordinatesForm.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  updateDocAction: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { updateDocAction }),
)(CoordinatesForm)
