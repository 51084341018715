import {
  LISTEN_USERS, LISTEN_ACCOUNT_ENTRIES, LISTEN_FLIGHTS, LISTEN_QUALIFICATIONS, LISTEN_ACCOUNT_FLUX, LISTEN_AVAILABILIIES,
  LISTEN_BILLING_TYPES, LISTEN_BOOKINGS, LISTEN_FLIGHT_TYPES, LISTEN_EMAILS, LISTEN_PROGRESSBOOKS,
  LISTEN_PROGRESSBOOKS_TEMPLATES, LISTEN_ACCOUNTS, LISTEN_AIRCRAFTS, LISTEN_AIRPORTS, LISTEN_PROGRESSBOOKS_ITEMS,
  LISTEN_QUALIFICATION_TYPES, LISTEN_SETTING, LISTEN_COLLECTION,
} from '../types'

const firestoreReducer = (state = {}, action) => {
  switch(action.type) {

    case LISTEN_COLLECTION:
      return {
        ...state,
        [action.collection]: action.payload,
      }

    case LISTEN_SETTING:
      return { ...state, settings: action.payload }

    case LISTEN_ACCOUNT_ENTRIES:
      return { ...state, accountEntries: action.payload }

    case LISTEN_ACCOUNT_FLUX:
      return { ...state, accountFlux: action.payload }

    case LISTEN_ACCOUNTS:
      return { ...state, accounts: action.payload }

    case LISTEN_AIRCRAFTS:
      return { ...state, aircrafts: action.payload }

    case LISTEN_AIRPORTS:
      return { ...state, airports: action.payload }

    case LISTEN_AVAILABILIIES:
      return { ...state, availabilities: action.payload }

    case LISTEN_BILLING_TYPES:
      return { ...state, billingTypes: action.payload }

    case LISTEN_BOOKINGS:
      return { ...state, bookings: action.payload }

    case LISTEN_EMAILS:
      return { ...state, emails: action.payload }

    case LISTEN_FLIGHT_TYPES:
      return { ...state, flightTypes: action.payload }

    case LISTEN_FLIGHTS:
      return { ...state, flights: action.payload }

    case LISTEN_PROGRESSBOOKS:
      return { ...state, progressBooks: action.payload }

    case LISTEN_PROGRESSBOOKS_ITEMS:
      return { ...state, progressBookItems: action.payload }

    case LISTEN_PROGRESSBOOKS_TEMPLATES:
      return { ...state, progressBookTemplates: action.payload }

    case LISTEN_QUALIFICATION_TYPES:
      return { ...state, qualificationTypes: action.payload }

    case LISTEN_QUALIFICATIONS:
      return { ...state, qualifications: action.payload }

    case LISTEN_USERS:
      return { ...state, users: action.payload }

    default:
      return state
  }
}

export default firestoreReducer
