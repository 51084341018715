import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Button, Box } from '@mui/material'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { InsertDriveFile, Delete } from '@mui/icons-material'
import { ref, uploadBytesResumable, deleteObject, getStorage, getDownloadURL } from 'firebase/storage'


const FileUpload = ({ fileUrl, folder, fileName, className, upload, style, placeholder, onComplete, deleteFile, error, helperText, sx }) => {
  
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [uploadError, setUploadError] = useState(false)
  
  const submit = event => {
    setLoading(true)
    const uploadTask = upload(
      folder,
      fileName,
      event.target.files[0],
    )
    uploadTask.on('state_changed',
      function progress(snapshot) {
        setProgress(100 * snapshot.bytesTransferred / snapshot.totalBytes)
      },
      function error(err) {
        console.error(err)
        setLoading(false)
        setUploadError(true)
      },
      function complete() {
        const storage = getStorage()
        getDownloadURL(ref(storage, uploadTask.snapshot.ref.fullPath)).then(onComplete)
        setLoading(false)
        setUploadError(false)
      },
    )
  }
  
  const onDelete = () => {
    const fullPath = fileUrl.split('?')[0].split('/o/')[1].replace('%2F', '/')
    console.log('fullPath', fullPath)
    deleteFile(fullPath).then(() => onComplete(null))
  }
  
  return (
    <Box sx={{ width: 'fit-content', ...sx }}>
      <Box className={className} style={style} sx={{
        padding: '5px 8px',
        border: !loading && 'solid 1px',
        borderColor: error ? 'error.main' : 'black',
        borderRadius: 20,
      }}>
        <Box
          component='label'
          htmlFor={folder}
          sx={{
            cursor: 'pointer',
            position: 'relative',
            minWidth: '40px',
          }}
        >
          <InsertDriveFile sx={{
            verticalAlign: 'text-bottom',
            color: fileUrl ? 'success.main' : uploadError ? 'error.main' : 'inherit',
          }} />
          {fileUrl && <Box component='span' role='img' sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            fontSize: '10px',
          }}>✔️</Box>}
          {uploadError && <Box component='span' role='img' sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            fontSize: '10px',
          }}>✖️</Box>}
          {loading && <CircularProgress color='inherit' size='120%' value={progress} variant='determinate' thickness={1} sx={{
            position: 'absolute',
            backgroundColor: 'rgba(255,255,255,.5)',
            top: '-10%',
            left: '-20%',
          }} />}
          {placeholder && !loading && !fileUrl && <Typography variant='body1' sx={{
            display: 'inline-block',
            fontSize: '.9rem',
            fontStyle: 'italic',
            color: (!!uploadError || !!error) && 'error.main',
          }}>{placeholder}</Typography>}
        </Box>
        <Box
          component='input'
          type='file'
          name='file'
          id={folder}
          onChange={submit}
          sx={{
            width: '0.1px',
            height: '0.1px',
            opacity: 0,
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1',
          }}
        />
        {fileUrl && <a href={fileUrl} target='_blank' rel='noopener noreferrer'>{folder}</a>}
        {fileUrl && <Button disableRipple onClick={onDelete} sx={{
          color: 'grey',
          minWidth: 'initial',
          verticalAlign: 'sub',
          padding: 0,
          '& .MuiSvgIcon-root': {
            fontSize: '1.2rem',
          },
        }}><Delete /></Button>}
      </Box>
      {helperText && <Typography variant='caption' sx={{ color: error && 'error.main' }}>{helperText}</Typography>}
    </Box>
  )
}

const mapActionsToProps = ({
  upload: (folder, name, file) => (dispatch, getState, { storage }) =>
    uploadBytesResumable(ref(storage, `${folder}/${name}-${(new Date()).getMilliseconds() }.${file.name.split('.').pop()}`), file),
  deleteFile: filePath => (dispatch, getState, { storage }) =>
    deleteObject(ref(storage, filePath)),
})

FileUpload.propTypes = {
  fileUrl: PropTypes.string,
  folder: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  className: PropTypes.string,
  upload: PropTypes.func.isRequired,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  sx: PropTypes.object,
}

export default compose(
  connect(null, mapActionsToProps),
)(FileUpload)
