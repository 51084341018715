/* eslint-disable */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
// import { Page, Text, Document, StyleSheet, View, Image } from '@react-pdf/renderer'
import ffaLogo from '../../images/hatch.png'
import { format } from 'date-fns'
import { chain, chunk } from 'lodash'

// Create styles
// const styles = StyleSheet.create({
//   page: {
//     padding: '10px',
//     fontSize: '12px',
//   },
//   header: {
//     textAlign: 'center',
//     margin: 'auto',
//     marginTop: '5px',
//     marginBottom: '20px',
//   },
//   logo: {
//     width: '150px',
//   },
//   h1: {
//     fontSize: '20px',
//     fontWeight: 700,
//   },
//   h2: {
//     fontSize: '15px',
//   },
//   input: {
//     color: 'grey',
//   },
// })

const ProgressBookPDF = ({ progressBook, licence, pilot, lessons, items }) => {

  const instructors = useMemo(
    () => chain(lessons)
      .groupBy(l => l.instructorRef.id)
      .mapValues(arr => arr[0].instructor)
      .values(),
    [lessons],
  )
  console.log('instructors', instructors)
  const planes = useMemo(
    () => chain(lessons)
      .groupBy(l => l.flight.aircraft.name)
      .mapValues(arr => arr[0].flight.aircraft)
      .values(),
    [lessons],
  )
  console.log('planes', planes)
  const durationSum = useMemo(
    () => lessons.reduce((acc, val) => [...acc, acc.at(-1) || 0 + val.flight?.duration || 0], []),
    [lessons],
  )
  console.log('durationSum', durationSum)
  const itemNumbers = useMemo(
    () => lessons.map(l => Object.keys({ ...l.theoreticalItems, ...l.practicalItems }).map(id => items.find(i => i.id === id).order).join(', ')),
    [lessons, items],
  )
  console.log('itemNumbers', itemNumbers)

  return (
    <div>La génération de PDF est désactivée</div>
    // <Document>
    //   <Page size='A4' style={styles.page} wrap>
    //     <View style={styles.header}>
    //       <Image style={styles.logo} src={ffaLogo} />
    //       <Text style={styles.h1}>Livret de progression</Text>
    //       <Text style={styles.h2}>Formation à la qualification voltige FCL 800</Text>
    //       <Text>DTO responsable et n° d’agrément: </Text><Text className={styles.input}>Midi-Pyrénées Voltige</Text>
    //       <Text>Adresse: 34 avenue Jean-René Lagasse 31130 Balma</Text>
    //     </View>
    //     <View style={styles.pilotIdentity}>
    //       <Text>Pilote</Text>
    //       <Text>Nom: {progressBook.pilot.lastname}</Text>
    //       <Text>Prénom: {progressBook.pilot.firstname}</Text>
    //       <Text>licence n°: {licence.identValue} délivrée le {format(licence.grantDate.toDate(), 'dd-MM-yyyy')}</Text>
    //       <Text>expérience: heures avion totales {pilot.experience.total} dont heures en CDT de bord: CDT {progressBook.experience.cdb}.</Text>
    //       <Text>le cas échéant expérience en voltige: {progressBook.experience.aerobaticsDC}h en DC sur avion type: {progressBook.experience.airplane}.</Text>
    //       <Text>signature:</Text>
    //       <Text>date du début de formation à la qualification voltige : {format(progressBook.startDate.toDate(), 'dd-MM-yyyy')}.</Text>
    //     </View>
    //     <View style={styles.instructors}>
    //       <Text>Instructeurs voltige participant à la formation :</Text>
    //       {instructors.map(i =>
    //         <Text key={i.lastname}>Nom: {i.lastname} Prénom: {i.firstname} licence n°: …………………………………</Text>,
    //       )}
    //     </View>
    //     <View style={styles.planes}>
    //       <Text>Avions utilisés dans le cadre de la formation:</Text>
    //       {planes.map(a =>
    //         <Text key={a.name}>Type: {a.type} Immatriculation: {a.name}</Text>,
    //       )}
    //     </View>
    //     <View style={styles.training}>
    //       <Text>Date de délivrance de la qualification voltige FCL 800: ………………………..</Text>
    //       <Text>Nbre d’heures totales du pilote: …………..dont ………… heures en CDT de bord après délivrance de la licence</Text>
    //       <Text>Qualification délivrée par l’instructeur :</Text>
    //       <Text>Nom: ………………………………..Prénom: …………………………………licence n°: …………………………………</Text>
    //       <Text>Signature :</Text>
    //     </View>
    //   </Page>
    //   {chunk(lessons, 3).map((chunkedLessons, page) => (
    //     <Page size='A4' key={page}>
    //       {chunkedLessons.map((lesson, index) => (
    //         <View style={styles.lessonCard} key={index}>
    //           <View style={styles.cardHeader}>
    //             <Text>
    //               vol n° {3 * page + index + 1}
    //               date: {format(lesson.flight.startDate.toDate(), 'dd-MM-yyyy')}
    //               tps de vol: {lesson.flight.duration}min (cumul: {durationSum[3 * page + index]})
    //               avion: {lesson.flight.aircraft.type}
    //               immat: {lesson.flight.aircraft.name}
    //               zone: {lesson.flight.arrival.ICAO}
    //             </Text>
    //           </View>
    //           <View style={styles.cardSubHeader}>
    //             <Text>Objectifs du vol: items n°: {itemNumbers[3 * page + index]}</Text>
    //           </View>
    //           <View style={styles.cardContent}>
    //             <View style={styles.cardSkillPanel}>
    //               <Text>Bilan des compétences</Text>
    //               <Text>Techniques</Text>
    //               <Text>1 Pilotage I{lesson.generalImpression.Pilotage === 'unsatisfactory' ? '☒' : '☐'} S{lesson.generalImpression.Pilotage === 'satisfactory' ? '☒' : '☐'} P{lesson.generalImpression.Pilotage === 'efficient' ? '☒' : '☐'}</Text>
    //               <Text>2 Trajectoires, orientation I{lesson.generalImpression['Trajectoires, Orientations'] === 'unsatisfactory' ? '☒' : '☐'} S{lesson.generalImpression['Trajectoires, Orientations'] === 'satisfactory' ? '☒' : '☐'} P{lesson.generalImpression['Trajectoires, Orientations'] === 'efficient' ? '☒' : '☐'}</Text>
    //               <Text>Non techniques</Text>
    //               <Text>3 Connaissances I{lesson.generalImpression.Connaissances === 'unsatisfactory' ? '☒' : '☐'} S{lesson.generalImpression.Connaissances === 'satisfactory' ? '☒' : '☐'} P{lesson.generalImpression.Connaissances === 'efficient' ? '☒' : '☐'}</Text>
    //               <Text>4 Conscience de la situation I{lesson.generalImpression['Conscience de la situation'] === 'unsatisfactory' ? '☒' : '☐'} S{lesson.generalImpression['Conscience de la situation'] === 'satisfactory' ? '☒' : '☐'} P{lesson.generalImpression['Conscience de la situation'] === 'efficient' ? '☒' : '☐'}</Text>
    //               <Text>5 Gestion des ressources I{lesson.generalImpression['Gestion des ressources'] === 'unsatisfactory' ? '☒' : '☐'} S{lesson.generalImpression['Gestion des ressources'] === 'satisfactory' ? '☒' : '☐'} P{lesson.generalImpression['Gestion des ressources'] === 'efficient' ? '☒' : '☐'}</Text>
    //               <Text>6 Prise de décision I{lesson.generalImpression['Prise de décision'] === 'unsatisfactory' ? '☒' : '☐'} S{lesson.generalImpression['Prise de décision'] === 'satisfactory' ? '☒' : '☐'} P{lesson.generalImpression['Prise de décision'] === 'efficient' ? '☒' : '☐'}</Text>
    //               <Text>(I insuffisant S satisfaisant P performant)</Text>
    //             </View>
    //             <View style={styles.cardDebriefPanel}>
    //               <Text>Débriefing</Text>
    //             </View>
    //           </View>
    //         </View>
    //       ))}
    //     </Page>
    //   ))}
    // </Document>
  )
}

ProgressBookPDF.propTypes = {
  progressBook: PropTypes.object,
  licence: PropTypes.object,
  pilot: PropTypes.object,
  lessons: PropTypes.array,
  items: PropTypes.array,
}

export default ProgressBookPDF
