import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import withStyles from '@mui/styles/withStyles'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { Chart } from 'react-charts'
import { eachMonthOfInterval, format, isSameMonth } from 'date-fns'
import { fr } from 'date-fns/locale'
import Loading from '../_library/Loading'


const styles = theme => ({
  root: {
    width: '100%',
    height: 200,
  },
  graph: {
    width: '100%',
    height: '100%',
    position: 'relative',
    border: 'solid 1px',
    borderColor: theme.palette.primary.main,
  },
})

export const DistributionGraph = ({ classes, className, lessons }) => {

  const data = useMemo(() => lessons?.length > 0 && eachMonthOfInterval({
    start: lessons[0].date.toDate() < Date.now() ? lessons[0].date.toDate() : Date.now(),
    end: Date.now(),
  }).map(month => [
    format(month, 'MMMM yyyy', { locale: fr }),
    lessons.filter(l => isSameMonth(month, l.date.toDate())).length,
  ])
  , [lessons])

  if (!lessons) return <Loading />
  else return (
    <div className={clsx(classes.root, className)}>
      <Chart
        data={[
          {
            label: 'Leçons',
            data,
          },
        ]}
        axes={[
          { primary: true, type: 'ordinal', position: 'bottom' },
          { type: 'linear', position: 'left', min: 0 },
        ]}
        tooltip
        getSeriesStyle={() => ({ color: 'red' })}
      />
    </div>
  )
}

const mapStateToProps = (state, props) => ({
  lessons: state.firestore.lessons?.filter(l => l.progressBookRef?.id === props.progressBook.id),
})

DistributionGraph.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  lessons: PropTypes.array,
  progressBook: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(DistributionGraph)
