import React, { useEffect, useState } from 'react'
import { Typography, CircularProgress, Box } from '@mui/material'
import Avatar from '../_library/Avatar'
import { uploadProfilePicture } from '../../store/actions/usersActions'
import { useDispatch, useSelector } from 'react-redux'


const InlineAvatarEditor = () => {
  
  const profile = useSelector(state => state.auth.profile)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const [prevPhotoUrl] = useState(profile.photoUrl)
  useEffect(() => {
    if (profile.photoUrl !== prevPhotoUrl){
      setLoading(false)
    }
  }, [profile.photoUrl, prevPhotoUrl])

  const handleSubmit = event => {
    setLoading(true)
    dispatch(uploadProfilePicture(
      event.target.files[0],
      profile.id,
    )).on('state_changed',
      function progress(snapshot) {
        setProgress(100 * snapshot.bytesTransferred / snapshot.totalBytes)
      },
      function error(err) {
        console.error(err)
      },
      function complete() {
        console.log('upload complete')
      },
    )
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Typography gutterBottom lineHeight='20px' width={200} sx={{ color: 'rgba(0,0,0,.54)', display: 'inline-block' }}>
        Photo de profil
      </Typography>
      <Box
        component='label'
        htmlFor='file'
        sx={{
          cursor: 'pointer',
          position: 'relative',
          minWidth: 40,
        }}
      >
        <Avatar user={profile} />
        {loading && <CircularProgress color='inherit' size={35} value={progress} variant='determinate' sx={{
          width: 35,
          height: 35,
          position: 'absolute',
          left: 0,
          top: 0,
          backgroundColor: 'rgba(255,255,255,.5)',
        }} />}
      </Box>
      <Box
        component='input'
        type='file'
        name='file'
        id='file'
        onChange={handleSubmit}
        sx={{
          width: '0.1px',
          height: '0.1px',
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: '-1',
        }}
      />
    </Box>
  )
}

export default InlineAvatarEditor
