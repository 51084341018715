import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Select, MenuItem } from '@mui/material'
import { eachDayOfInterval, format, isSameDay } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { fr } from 'date-fns/locale'
import { setDocAction } from '../../store/actions/firestoreActions'
import { ACTIVITY_COLLECTION, REQUEST_COLLECTION, USER_COLLECTION } from '../../_constants/globals'


const HorizPlanning = ({ userId, activityId }) => {
  
  const { aircrafts, firstDay, lastDay } = useSelector(state => state.data.selectedActivity || {})
  const requests = useSelector(state => state.firestore.requests.filter(r => r.userRef.id === userId))
  const dispatch = useDispatch()
  
  const days = useMemo(
    () => firstDay && lastDay && eachDayOfInterval({ start: firstDay.toDate(), end: lastDay.toDate() }),
    [firstDay, lastDay],
  )
  
  const handleSetRequest = (aircraft, day, period, flightType) => dispatch(setDocAction(
    REQUEST_COLLECTION,
    requests.find(r => r.aircraft === aircraft.name && isSameDay(r.day?.toDate(), day) && r.period === period)?.id || null,
    {
      activityRef: [ACTIVITY_COLLECTION, activityId],
      userRef: [USER_COLLECTION, userId],
      flightType,
      aircraft,
      day,
      period,
    },
  ))
  
  return (
    <Box component='table' sx={{
      borderCollapse: 'collapse',
      '& td': { borderLeft: '1px solid black' },
      width: '100%',
    }}>
      <thead>
        <tr>
          <th colSpan={2} />
          {days.map(day => <th key={day.getTime() + 'head'}>{format(day, 'EEEE', { locale: fr })}</th>)}
        </tr>
      </thead>
      <tbody>
        {aircrafts.reduce((acc, val) => [...acc, { aircraft: val, period: 'AM' }, { aircraft: val, period: 'PM' }], []).map(({ aircraft, period }) =>
          <Box component='tr' key={aircraft.name + period} sx={{ borderTop: '1px solid black' }}>
            {period ==='AM' && <th rowSpan={2}>{aircraft.name}</th>}
            <th>{period}</th>
            {days.map(day => <td key={day.getTime() + period}>
              <Select
                variant='standard'
                fullWidth
                value={requests.find(r => r.aircraft === aircraft.name && isSameDay(r.day?.toDate(), day) && r.period === period)?.flightType || ''}
                onChange={e => handleSetRequest(aircraft.name, day, period, e.target.value)}
                sx={{ '&:before': { borderBottom: 'none' } }}
              >
                <MenuItem value=''><em>vide</em></MenuItem>
                <MenuItem value='Solo'>Solo</MenuItem>
                <MenuItem value='Double'>Double</MenuItem>
                <MenuItem value='Passager'>Passager</MenuItem>
              </Select>
            </td>)}
          </Box>,
        )}
      </tbody>
    </Box>
  )
}

HorizPlanning.propTypes = {
  userId: PropTypes.string.isRequired,
  activityId: PropTypes.string.isRequired,
}

export default HorizPlanning
