import { ERROR, LISTEN_ACCOUNT_ENTRIES, LISTEN_FLIGHTS, SUCCESS, LISTEN_COLLECTION } from '../types'
import {
  ACCOUNT_COLLECTION, ACCOUNT_ENTRY_COLLECTION, ACTIVITY_COLLECTION, AIRPORT_COLLECTION, BOOKING_COLLECTION,
  FLIGHT_COLLECTION, QUALIFICATION_COLLECTION, QUALIFICATION_TYPE_COLLECTION, USER_COLLECTION,
} from '../../_constants/globals'
import { startOfYear, endOfYear } from 'date-fns'
import { compact, omit, unionBy } from 'lodash'
import { doc, query, collection, where, onSnapshot, orderBy, getDoc, limit, addDoc, serverTimestamp, updateDoc,
  deleteDoc, getDocs, limitToLast, writeBatch, startAt, endAt } from 'firebase/firestore'
import { hydrateRefs } from '../../_helpers/firestoreHelpers'


/**
 * Set a listener on Firestore collection
 * @param {string} collectionParam
 * @param {string[]} whereParam
 * @param {string[]} sorts
 * @param {number} limit
 * @param {object} endAtParam
 * @param {string} storeAs
 * @param {string} debugKey
 */
export const listenCollection = ({ collection: collectionParam, where: whereParam = [], orderBy: orderParam = [], limit, endAt: endAtParam, storeAs, debugKey }) => (dispatch, getState, { db }) => {
  debugKey && console.log('listenCollection DEBUG', debugKey)
  debugKey && console.log('collectionParam', collectionParam)
  debugKey && console.log('where', whereParam)
  debugKey && console.log('orderBy', orderParam)
  debugKey && console.log('limit', limit)
  debugKey && console.log('endAt', endAtParam)
  debugKey && console.log('storeAs', storeAs)
  const constraints = []
  whereParam.forEach(filter => constraints.push(
    filter[0].includes('Ref')
      ? where(filter[0], filter[1], doc(db, filter[2], filter[3]))
      : where(...filter),
  ))
  orderParam.forEach(sort => constraints.push(orderBy(...sort)))
  if (endAtParam) constraints.push(endAt(endAtParam))
  if (limit) constraints.push(limitToLast(limit))
  debugKey && console.log('constraints', constraints)
  return onSnapshot(query(collection(db, collectionParam), ...constraints), snap => {
    debugKey && console.log(`New ${collectionParam} snapshot. length: `, snap.size)
    return dispatch({
      type: LISTEN_COLLECTION,
      collection: storeAs || collectionParam,
      payload: snap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
    })
  })
}

/**
 * Get collection data from firestore
 * @param {string} collectionStr
 * @param {string[]} filters
 */
export const fetchCollection = ({ collection: collectionStr, where: filters = [] }) => (dispatch, getState, { db }) => {
  const q = query(
    collection(db, collectionStr),
    ...filters.map(filter =>
      filter[0].includes('Ref')
        ? where(filter[0], filter[1], doc(db, filter[2], filter[3]))
        : where(...filter),
    ),
  )
  return getDocs(q).then(snap =>
    dispatch({
      type: LISTEN_COLLECTION,
      collection: collectionStr,
      payload: snap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
    }),
  )
}

/**
 * Get only one document
 * @param {object | string} ref
 * @param {string} id
 */
export const getDocAction = (ref, id) => (dispatch, getState, { db }) => id
  ? getDoc(doc(db, ref, id)).then(doc => ({ id: doc.id, ...doc.data() }))
  : getDoc(ref).then(doc => ({ id: doc.id, ...doc.data() }))

/**
 * @param {string} collectionStr
 * @param {object} data
 * @param {boolean} notification
 */
export const addDocAction = (collectionStr, data, notification = false) => (dispatch, getState, { db }) =>
  addDoc(collection(db, collectionStr), {
    ...hydrateRefs(db, data),
    _createdAt: serverTimestamp(),
    _updatedAt: serverTimestamp(),
  })
    .then(res => {
      if (notification)
        dispatch({
          type: SUCCESS,
          payload: 'notifications.addDoc.success.' + collectionStr,
        })
      return res
    })
    .catch(err => {
      if (notification)
        dispatch({
          type: ERROR,
          payload: 'notifications.addDoc.error',
        })
      console.error(err)
    })

/**
 * @param {string} collectionStr
 * @param {string} id
 * @param {object} data
 * @param {boolean} notification
 */
export const updateDocAction = (collectionStr, id, data, notification = false) => (dispatch, getState, { db }) =>
  updateDoc(doc(db, collectionStr, id), {
    ...hydrateRefs(db, data),
    _updatedAt: serverTimestamp(),
  })
    .then(res => {
      if (notification)
        dispatch({
          type: SUCCESS,
          payload: 'notifications.updateDoc.success',
        })
      return res
    })
    .catch(err => {
      if (notification)
        dispatch({
          type: ERROR,
          payload: 'notifications.updateDoc.error',
        })
      console.error(err)
    })

/**
 * @param {string} collectionStr
 * @param {string} id
 * @param {object} data
 * @param {boolean} notification
 */
export const setDocAction = (collectionStr, id, data, notification = false) =>
  id ? updateDocAction(collectionStr, id, data, notification) : addDocAction(collectionStr, data, notification)

/**
 * @param {string} collectionStr
 * @param {string} id
 * @param {boolean} notification
 */
export const deleteDocAction = (collectionStr, id, notification = false) => (dispatch, getState, { db }) =>
  deleteDoc(doc(db, collectionStr, id))
    .then(res => {
      if (notification)
        dispatch({
          type: SUCCESS,
          payload: 'notifications.deleteDoc.success',
        })
      return res
    })
    .catch(err => {
      if (notification)
        dispatch({
          type: ERROR,
          payload: 'notifications.deleteDoc.error',
        })
      console.error(err)
    })

/**
 * Listen account entry docs
 * RULE : user is connected
 */
export const listenAccountEntryAction = (account, { year, limit: limitOption }) => (dispatch, getState, { db }) => {
  const constraints = compact([
    where('accountRef', '==', doc(db, ACCOUNT_COLLECTION, account.id)),
    year && where('accountDate', '>=', new Date(year, 0, 1, 0, 0)),
    year && where('accountDate', '<=', new Date(year, 11, 31, 23, 59)),
    orderBy('accountDate', limitOption ? 'desc' : 'asc'),
    limitOption && limit(limitOption),
  ])
  return onSnapshot(query(collection(db, ACCOUNT_ENTRY_COLLECTION), ...constraints), snap => {
    process.env.REACT_APP_DEBUG && console.log('New accountEntries snapshot. length: ', snap.size)
    return dispatch({
      type: LISTEN_ACCOUNT_ENTRIES,
      payload: snap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
    })
  })
}

/**
 * Listen airport docs
 * RULE : user is connected
 */
export const listenAirportAction = () =>
  listenCollection({
    collection: AIRPORT_COLLECTION,
    orderBy: [['occurrences', 'desc']],
  })

/**
 * Listen flights for a given year
 * @param {number|string} year
 */
export const listenFlightsAction = year => (dispatch, getState, { db }) => {
  const constraints = compact([
    orderBy('startDate', 'asc'),
    year !== 'all' && startAt(startOfYear((new Date()).setFullYear(year) || new Date())),
    year !== 'all' && endAt(endOfYear((new Date()).setFullYear(year) || new Date())),
  ])
  return onSnapshot(query(collection(db, FLIGHT_COLLECTION), ...constraints), snap =>
    dispatch({
      type: LISTEN_FLIGHTS,
      payload: snap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
    }),
  )
}

/**
 * @deprecated
 */
export const deleteBookingAction = id => (dispatch, getState, { getFirestore }) =>
  getFirestore().collection(BOOKING_COLLECTION).doc(id).delete()
    .catch(err =>
      dispatch({ type: ERROR, err }),
    )

export const submitExperience = (experienceData, userId) => async (dispatch, getState, { db }) => {
  const uid = userId || getState().auth.profile.id
  const { firstname, lastname } = getState().auth.profile
  const userRef = doc(db, USER_COLLECTION, uid)
  const user = { uid, firstname, lastname }
  const _createdAt = serverTimestamp()
  const types = await getDocs(collection(db, QUALIFICATION_TYPE_COLLECTION))
  
  console.log('experienceData', experienceData)
  const { license, sep, tw, vp, visite, ffa, experience, fcl800, bfv1, bfv2, bfv3, competition } = experienceData
  
  const batch = writeBatch(db)
  
  if (license) createOrUpdateQualif(batch, db, _createdAt, userRef, user, license, types.docs.find(doc => doc.get('slug') === 'licence').ref)
  if (sep) createOrUpdateQualif(batch, db, _createdAt, userRef, user, sep, types.docs.find(doc => doc.get('slug') === 'sep').ref)
  if (tw) createOrUpdateQualif(batch, db, _createdAt, userRef, user, tw, types.docs.find(doc => doc.get('slug') === 'tw').ref)
  if (vp) createOrUpdateQualif(batch, db, _createdAt, userRef, user, vp, types.docs.find(doc => doc.get('slug') === 'vp').ref)
  if (visite) createOrUpdateQualif(batch, db, _createdAt, userRef, user, visite, types.docs.find(doc => doc.get('slug') === 'medical').ref)
  if (ffa) createOrUpdateQualif(batch, db, _createdAt, userRef, user, ffa, types.docs.find(doc => doc.get('slug') === 'ffa').ref)
  if (experience) batch.update(userRef, { experience })
  if (fcl800) createOrUpdateQualif(batch, db, _createdAt, userRef, user, fcl800, types.docs.find(doc => doc.get('slug') === 'fcl800').ref)
  if (bfv1) createOrUpdateQualif(batch, db, _createdAt, userRef, user, bfv1, types.docs.find(doc => doc.get('slug') === 'bfv1').ref)
  if (bfv2) createOrUpdateQualif(batch, db, _createdAt, userRef, user, bfv2, types.docs.find(doc => doc.get('slug') === 'bfv2').ref)
  if (bfv3) createOrUpdateQualif(batch, db, _createdAt, userRef, user, bfv3, types.docs.find(doc => doc.get('slug') === 'bfv3').ref)
  if (competition) batch.update(userRef, { competition })
  
  return batch.commit()
}

const createOrUpdateQualif = (batch, db, _createdAt, userRef, user, entry, typeRef) => {
  if (!entry.id) {
    batch.set(doc(collection(db, QUALIFICATION_COLLECTION)), { ...entry, _createdAt, userRef, user, typeRef })
  }
  else if (entry.delete) {
    batch.delete(doc(db, QUALIFICATION_COLLECTION, entry.id))
  }
  else {
    batch.update(doc(db, QUALIFICATION_COLLECTION, entry.id), omit(entry, ['id']))
  }
}

/**
 * Get document reference
 * @param {string} collection
 * @param {string} id
 */
export const getRef = (collection, id) => (dispatch, getState, { db }) =>
  doc(db, collection, id)

/**
 * Listen activities and add virtual field users
 */
export const listenActivityAction = () => (dispatch, getState, { db }) => {
  const constraints = compact([
    orderBy('firstDay', 'asc'),
  ])
  return onSnapshot(
    query(collection(db, ACTIVITY_COLLECTION), ...constraints),
    snap => dispatch({
      type: LISTEN_COLLECTION,
      collection: ACTIVITY_COLLECTION,
      payload: snap.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        users: unionBy(
          doc.get('organizer') && [{ ...doc.get('organizer'), role: 'Organisateur' }],
          doc.get('instructors')?.map(u => ({ ...u, role: 'Instructeur' })),
          doc.get('megs')?.map(u => ({ ...u, role: 'MEG' })),
          doc.get('vds')?.map(u => ({ ...u, role: 'VD' })),
          doc.get('pilots')?.map(u => ({ ...u, role: 'Pilote' })),
          'id',
        ),
      })),
    }),
  )
}
