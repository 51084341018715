import React from 'react'
import SubscriptionTable from './SubscriptionTable'
import AccountValidation from './AccountValidation'
import { parseParams } from '../../_helpers/urlHelper'
import { useLocation } from 'react-router'


const AccountValidationPanel = () => {
  
  const location = useLocation()
  
  const { userId } = parseParams(location.search)
  
  return userId
    ? <AccountValidation userId={userId} />
    : <SubscriptionTable />
}

export default AccountValidationPanel
