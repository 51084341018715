import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, DialogContent, DialogActions, Button, CircularProgress, TextField, Switch, FormControlLabel, Typography } from '@mui/material'
import { addDocAction, updateDocAction } from '../../store/actions/firestoreActions'
import { compact } from 'lodash'
import { AIRCRAFT_COLLECTION, MECA_REPORT_COLLECTION } from '../../_constants/globals'
import { useDispatch } from 'react-redux'


export const StatusReport = ({ flight, next }) => {
  
  const dispatch = useDispatch()

  const [mecaRAS, setMecaRAS] = useState(false)
  const [mecaComment, setMecaComment] = useState(null)
  const [generalRAS, setGeneralRAS] = useState(false)
  const [generalComment, setGeneralComment] = useState(null)
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    setErrors(prev => ({ ...prev, meca: null }))
  }, [mecaRAS, mecaComment])

  useEffect(() => {
    setErrors(prev => ({ ...prev, general: null }))
  }, [generalRAS, generalComment])

  const handleSubmit = () => {
    if (!mecaRAS && !mecaComment) setErrors(prev => ({ ...prev, meca: 'Si la méca n\'est pas RAS il faut expliquer le problème' }))
    if (!generalRAS && !generalComment) setErrors(prev => ({ ...prev, general: 'Si l\'avion n\'est pas prêt à voltiger merci d\'inquer au prochain pilote ce qu\'il y a à faire' }))
    if ((mecaRAS || mecaComment) && (generalRAS || generalComment)) {
      setSubmitted(true)
      Promise.all(compact([
        mecaComment && dispatch(addDocAction(MECA_REPORT_COLLECTION, {
          comment: mecaComment,
          aircraftRef: [AIRCRAFT_COLLECTION, flight.aircraftRef.id],
        })),
        dispatch(updateDocAction(AIRCRAFT_COLLECTION, flight.aircraftRef.id, {
          comments: generalComment,
        })),
      ]))
        .then(next)
        .catch(() => setSubmitted(false))
    }
  }

  return !flight
    ? <CircularProgress color='inherit' />
    : <>
      <DialogContent>
        <Grid container sx={{ overflowY: 'scroll' }}>
          <h2>Etat mécanique</h2>
          <Grid item xs={12} id='mecaStatus'>
            <FormControlLabel control={<Switch checked={mecaRAS} onChange={e => setMecaRAS(e.target.checked)} />} label='RAS' />
            {!mecaRAS && <TextField
              variant='standard'
              margin='dense'
              label='Commentaires'
              fullWidth
              value={mecaComment || ''}
              multiline
              onChange={e => setMecaComment(e.target.value)}
              error={Boolean(errors.meca)}
              helperText={errors.meca} />}
          </Grid>
          <h2>Etat général</h2>
          <Grid item xs={12} id='generalState'>
            <FormControlLabel
              control={<Switch checked={generalRAS} onChange={e => setGeneralRAS(e.target.checked)} />}
              label='L&apos;avion est rangé prêt à voltiger'
              style={{ display: 'block' }}
            />
            <Typography variant='caption'>
              C&apos;est à dire :
              <ul>
                <li>Rangé dans son hangar</li>
                <li>Bords d&apos;attaque nettoyés</li>
                <li>Verrière nettoyée</li>
                <li>Caches pitots en place</li>
                <li>Réservoir voltige plein, ailes vides</li>
                <li>Baché</li>
                <li>Bouteille sur le reniflard (Extras)</li>
              </ul>
            </Typography>
            {!generalRAS && <TextField
              variant='standard'
              margin='dense'
              label='Commentaires'
              fullWidth
              value={generalComment || ''}
              multiline
              onChange={e => setGeneralComment(e.target.value)}
              error={Boolean(errors.general)}
              helperText={errors.general} />}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color='primary' disabled={submitted}>
          Valider
        </Button>
      </DialogActions>
    </>
}

StatusReport.propTypes = {
  flight: PropTypes.object,
  next: PropTypes.func,
}

export default StatusReport
