import React, { useMemo } from 'react'
import GeneralPanel from './GeneralPanel'
import PilotPanel from './PilotPanel'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import InstructorPanel from './InstructorPanel'
import TabbedPage from '../_library/TabbedPage'
import { compact } from 'lodash'


const ProfilePage = ({ profile }) => {

  const tabs = useMemo(() => compact([
    {
      key: 'general',
      label: 'Général',
      route: 'general',
      content: <GeneralPanel />,
    },
    {
      key: 'pilot',
      label: 'Pilote',
      route: 'pilot',
      content: <PilotPanel />,
    },
    profile.roles.includes('instructeur') && {
      key: 'instructor',
      label: 'Instructeur',
      route: 'instructor',
      content: <InstructorPanel />,
    },
  ]), [profile.roles])

  return <TabbedPage tabs={tabs} />
}

const mapStateToProps = state => ({
  profile: state.auth.profile,
})

ProfilePage.propTypes = {
  profile: PropTypes.object.isRequired,
}

export default compose(
  connect(mapStateToProps),
)(ProfilePage)
