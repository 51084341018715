import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { PB_LESSON_COLLECTION, PB_TEMPLATE_COLLECTION } from '../../_constants/globals'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../_library/Loading'
import { Link } from 'react-router-dom'
import { PROGRESS_BOOKS } from '../../_constants/routes'
import { getDocAction, listenCollection } from '../../store/actions/firestoreActions'
import { chain, groupBy } from 'lodash'
import useListener from '../../hooks/useListener'


const LessonSummary = ({ back, next, lessonId }) => {
  
  const templates = useSelector(state => state.firestore.templates)
  const items = useSelector(state => state.firestore.items)
  const dispatch = useDispatch()
  useListener(() => listenCollection({
    collection: PB_TEMPLATE_COLLECTION,
    orderBy: [['order', 'asc']],
    storeAs: 'templates',
  }))

  const [lesson, setLesson] = useState(null)

  useEffect(() => {
    dispatch(getDocAction(PB_LESSON_COLLECTION, lessonId)).then(doc => setLesson(doc))
  }, [lessonId])

  const groupedItems = useMemo(
    () => lesson && templates && items &&
      chain(lesson.gradedItems?.length ? lesson.gradedItems : lesson.selectedItemIds.map(id => items.find(i => i.id === id)))
        .groupBy('level')
        .mapKeys((_, level) => templates.find(t => t.level === level).name)
        .mapValues(group => groupBy(group, 'type'))
        .toPairs()
        .value(),
    [lesson, templates, items],
  )

  return <>
    <DialogContent>
      <Typography variant='h4'>Résumé</Typography>
      {groupedItems
        ? groupedItems.map(pair => <div key={pair[0]}>
          <Typography variant='h5'>Livret: <Link to={PROGRESS_BOOKS + '/open'}>{pair[0]}</Link></Typography>
          {pair[1].theoretical && <>
            <Typography variant='h6' mt={2}>Items théoriques</Typography>
            <ul>{pair[1].theoretical.map(item => <li key={item.id}>{item.name}</li>)}</ul>
          </>}
          {pair[1].practical && <>
            <Typography variant='h6' mt={2}>Items pratiques</Typography>
            <ul>{pair[1].practical.map(item => <li key={item.id}>{item.name}</li>)}</ul>
          </>}
        </div>)
        : <Loading />
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={back} color='primary'>
        Retour
      </Button>
      <Button onClick={next} color='primary'>
        Fermer
      </Button>
    </DialogActions>
  </>
}

LessonSummary.propTypes = {
  lessonId: PropTypes.string.isRequired,
  back: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
}

export default LessonSummary
