import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../_library/Loading'
import DetailsCard from './DetailsCard'
import MapCard from './MapCard'
import CalendarItem from './CalendarItem'
import { printEventDuration } from '../../_helpers/dateHelper'
import ActionBar from './ActionBar'
import UserCard from './UserCard'
import PlanningCard from './PlanningCard'
import DiscussionCard from './DiscussionCard'
import PublishCard from './PublishCard'
import { updateDocAction } from '../../store/actions/firestoreActions'
import { NOTIFICATION_COLLECTION } from '../../_constants/globals'


const ActivityDetails = () => {
  
  const activity = useSelector(state => state.data.selectedActivity || {})
  const { airport, firstDay, lastDay, id, title, coverUrl, organizerRef } = activity
  const profile = useSelector(state => state.auth.profile)
  const adminMode = useSelector(state => state.data.adminMode)
  const notifications = useSelector(state => state.firestore.notifications?.filter(n => n.activityRef?.id === id) || [])
  const dispatch = useDispatch()
  
  useEffect(() => {
    notifications?.forEach(n => dispatch(updateDocAction(NOTIFICATION_COLLECTION, n.id, { read: true })))
  }, [])
  
  if (!id) return <Loading />
  else return (
    <Box>
      <Box id='activity-details-header' sx={{ backgroundImage: 'linear-gradient(to top, #FFFFFF, rgba(255,255,255.9), rgba(255,255,255,.7), rgba(255,255,255,.4), rgba(255,255,255,0));' }}>
        <Box component='img' src={coverUrl} alt='cover' sx={{ display: 'block', width: '100%', maxWidth: 665, mx: 'auto', borderRadius: '10px' }} />
        <Box sx={{ px: 2, pb: 2, maxWidth: 1000, mx: 'auto' }}>
          <CalendarItem date={firstDay.toDate()} sx={{ mt: '-60px', position: 'relative' }} />
          <Typography color='secondary' textTransform='uppercase' mt={1}>{printEventDuration(firstDay, lastDay)}</Typography>
          <Typography variant='h1' mt={1} fontSize='2rem'>{title}</Typography>
          <ActionBar activity={activity} />
        </Box>
      </Box>
      <Grid container spacing={2} pr={2} sx={{ pt: 2, pb: '120px', maxWidth: 1000, mx: 'auto', width: '100%' }}>
        <Grid item md={12}>
          <PlanningCard />
        </Grid>
        <Grid item md={7}>
          <DetailsCard />
          {(adminMode || organizerRef?.id === profile.id) && <PublishCard sx={{ mt: 2 }} />}
          <DiscussionCard sx={{ mt: 2 }} />
        </Grid>
        <Grid item md={5}>
          <UserCard sx={{ mb: 2 }} />
          {airport && <MapCard airport={airport} />}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ActivityDetails
