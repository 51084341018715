import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, useTheme, useMediaQuery, IconButton, DialogContent, DialogActions, Box, TextField, Autocomplete, ToggleButtonGroup, ToggleButton,
  FormGroup, FormControlLabel, Switch } from '@mui/material'
import { Close } from '@mui/icons-material'
import CTAButton from '../_library/CTAButton'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { omit } from 'lodash'
import Avatar from '../_library/Avatar'
import { addDocAction, updateDocAction } from '../../store/actions/firestoreActions'
import { ACTIVITY_COLLECTION, STATUS_VISITOR, USER_COLLECTION } from '../../_constants/globals'


const ROLES = ['Instructeur', 'Pilote', 'vd', 'meg']
const UserTextField = props => <TextField variant='standard' fullWidth sx={{ mt: 1 }} {...props} />

export const AddUserDialog = ({ open, onClose, activityId }) => {
  
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const allUsers = useSelector(state => state.firestore.users?.sort((a, b) => {
    const aScore = a.roles?.includes('instructeur') && a.status === 'Actif' ? 2 : a.status === 'Actif' ? 1 : 0
    const bScore = b.roles?.includes('instructeur') && b.status === 'Actif' ? 2 : b.status === 'Actif' ? 1 : 0
    return bScore - aScore
  }), shallowEqual)
  const dispatch = useDispatch()
  const formRef = useRef()
  
  const [role, setRole] = useState(null)
  const [user, setUser] = useState(null)
  const [firstname, setFirstname] = useState(null)
  const [lastname, setLastname] = useState(null)
  const [phone, setPhone] = useState(null)
  const [email, setEmail] = useState(null)
  const [payment, setPayment] = useState(false)
  const [medical, setMedical] = useState(false)
  const [comment, setComment] = useState(null)
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(false)
  
  const handleSubmit = async e => {
    e.preventDefault()
    if (['vd', 'meg'].includes(role) && !firstname)
      setErrors(prev => ({ ...prev, firstname: 'il faut indiquer un prénom' }))
    if (['vd', 'meg'].includes(role) && !lastname)
      setErrors(prev => ({ ...prev, firstname: 'il faut indiquer un nom' }))
    if (['vd', 'meg'].includes(role) && !phone)
      setErrors(prev => ({ ...prev, phone: 'Le GO a besoin du numéro de téléphonne' }))
    if (['Instructeur', 'Pilote'].includes(role) || (firstname && phone)) {
      setSubmitted(true)
      let data
      if (role === 'Instructeur')
        data = { instructorRefs: [USER_COLLECTION, user.id, 'add'] }
      else if (role === 'Pilote')
        data = { pilotRefs: [USER_COLLECTION, user.id, 'add'] }
      else {
        const userRef = await dispatch(addDocAction(USER_COLLECTION, { firstname, lastname, phone, email, payment, medical, comment, status: STATUS_VISITOR }, true))
        data = { [role + 'Refs']: [USER_COLLECTION, userRef.id, 'add'] }
      }
      return dispatch(updateDocAction(ACTIVITY_COLLECTION, activityId, data))
        .then(onClose)
        .finally(() => setSubmitted(false))
    }
  }
  
  return (
    <Dialog
      open={open || false}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      fullScreen={!isUpMd}
    >
      <DialogTitle>
        Ajouter un participant
        &nbsp;
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <form onSubmit={handleSubmit} ref={formRef}>
          <Box sx={{ px: 1 }}>
            <ToggleButtonGroup
              exclusive
              fullWidth
              value={role}
              color='primary'
              onChange={(_, value) => {
                setErrors(prev => omit(prev, 'role'))
                setRole(value)
              }}
              sx={{ mt: 2 }}
            >
              {ROLES.map(role => <ToggleButton key={role} value={role}>{role}</ToggleButton>)}
            </ToggleButtonGroup>
            {errors.role && <Box sx={{ color: 'error.main', fontSize: '0.75rem', lineHeight: 1.66 }}>{errors.role}</Box>}
            {['Instructeur', 'Pilote'].includes(role) && <Autocomplete
              id='userSelect'
              options={allUsers}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              groupBy={u => {
                if (u.roles?.includes('instructeur') && u.status === 'Actif') return 'Instructeurs'
                else if (u.status === 'Actif') return 'Actifs'
                else return 'Anciens'
              }}
              value={user}
              onChange={(_, value) => setUser(value)}
              getOptionLabel={u => u?.firstname + ' ' + u?.lastname}
              renderOption={(props, option) => <li {...props} key={'organizer-' + option.id}>
                <Avatar user={option} sx={{ mr: 1 }} />
                {option.firstname + ' ' + option.lastname}
              </li>}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='outlined'
                  label={role}
                  sx={{ mt: 2 }}
                />
              )}
              loading={!allUsers}
            />}
            {['vd', 'meg'].includes(role) && <Box sx={{ py: 1, px: 2, maxWidth: 400 }}>
              <UserTextField label='Prénom' value={firstname || ''} onChange={e => setFirstname(e.target.value)} error={!!errors.firstname} />
              <UserTextField label='Nom' value={lastname || ''} onChange={e => setLastname(e.target.value)} error={!!errors.lastname} />
              <UserTextField label='Email' value={email || ''} onChange={e => setEmail(e.target.value)} error={!!errors.email} />
              <UserTextField label='Téléphone' value={phone || ''} onChange={e => setPhone(e.target.value)} error={!!errors.phone} />
              <FormGroup sx={{ m: 1 }}>
                <FormControlLabel control={<Switch />} label='Le paiement a été fait' checked={payment} onChange={e => setPayment(e.target.checked)} />
                <FormControlLabel control={<Switch />} label='Le certificat a été envoyé' checked={medical} onChange={e => setMedical(e.target.checked)} />
              </FormGroup>
              <TextField
                variant='outlined'
                margin='dense'
                label='Commentaire'
                fullWidth
                value={comment || ''}
                multiline
                onChange={e => {
                  setComment(e.target.value)
                }}
                rows={3}
                sx={{ mt: 1 }}
              />
            </Box>}
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <CTAButton type='submit' fullWidth onClick={() => formRef.current.requestSubmit()} disabled={!role || submitted}>
          Ajouter
        </CTAButton>
      </DialogActions>
    </Dialog>
  )
}

AddUserDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  activityId: PropTypes.string.isRequired,
}

export default AddUserDialog
