import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'


/**
 * https://react-redux.js.org/api/hooks
 */
export default (action, deps = [], cond = true) => {
  const dispatch = useDispatch()
  useEffect(
    () => {
      if (cond) {
        const unsubscribe = bindActionCreators(action, dispatch)()
        return () => {
          unsubscribe()
        }
      }
    },
    deps ? [dispatch, ...deps] : [dispatch],
  )
}
