import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../store/actions/dataActions'


export const UserSelector = () => {
  
  const profile = useSelector(state => state.auth.profile)
  const users = useSelector(state => state.firestore.users)
  const selectedUser = useSelector(state => state.data.selectedUser)
  const dispatch = useDispatch()
  
  return (
    <FormControl variant='standard' sx={{ m: 1, verticalAlign: 'baseline' }}>
      <InputLabel id='user-select-label' sx={{ color: 'white' }}>User</InputLabel>
      <Select
        labelId='user-select-label'
        id='user-select'
        value={selectedUser?.id || profile.id}
        onChange={e => dispatch(selectUser(users.find(u => u.id === e.target.value)))}
        label='User'
        sx={{ minWidth: '120px', borderColor: 'secondary.main' }}
      >
        {users?.map(u => <MenuItem key={u.id} value={u.id}>{u.firstname} {u.lastname}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default UserSelector
