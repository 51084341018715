import { LISTEN_PROFILE } from '../types'

export const INITIAL_STATE = {
  profile: { isLoaded: false },
}

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LISTEN_PROFILE:
      return { ...state, profile: action.payload }
    default:
      return state
  }
}
