import React, { useMemo } from 'react'
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material'
import { FLIGHT_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { listenCollection } from '../../store/actions/firestoreActions'
import useListener from '../../hooks/useListener'


const FlightConnector = ({ lesson, handleChange, error }) => {
  
  const profile = useSelector(state => state.auth.profile)
  const flights = useSelector(state => state.firestore['flightsToConnect-' + lesson.id])
  useListener(() => listenCollection({
    collection: FLIGHT_COLLECTION,
    where: [
      ['pilotRef', '==', USER_COLLECTION, lesson.pilotRef.id],
      ['instructorRef', '==', USER_COLLECTION, lesson.instructorRef.id || lesson.instructorRef[1]],
    ],
    orderBy: [['startDate', 'asc']],
    storeAs: 'flightsToConnect-' + lesson.id,
  }), [lesson], !!(lesson.pilotRef && lesson.instructorRef))
  
  const filteredFlights = useMemo(
    () => flights?.filter(f => !f.lessonRef || lesson?.flightRef?.id === f.id || lesson?.id === 'new')
      .map(f => ({
        ...f,
        otherGuy: f.pilotRef.id === profile.id ? f.instructor?.firstname : f.pilot?.firstname,
      })),
    [flights, profile.id, lesson],
  )
  
  return (
    <FormControl variant='standard'>
      <InputLabel id='flight-select-helper-label'>Vol</InputLabel>
      <Select
        id='flight-select-helper'
        labelId='flight-select-helper-label'
        variant='standard'
        value={lesson?.flightRef ? lesson.flightRef.id || lesson.flightRef[1] : ''}
        onChange={e => handleChange(e.target.value, flights)}
        error={error}>
        <MenuItem value=''><em>None</em></MenuItem>
        {filteredFlights?.map(flight => <MenuItem key={flight.id} value={flight.id}>
          Le {format(flight.startDate.toDate(), 'dd-MM-yyyy')} à {format(flight.startDate.toDate(), 'HH:mm')} avec {flight.otherGuy}
        </MenuItem>)}
      </Select>
      <FormHelperText error={error}>Sélectionne un vol pour le lier à cette leçon</FormHelperText>
    </FormControl>
  )
}

FlightConnector.propTypes = {
  lesson: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
}

export default FlightConnector
