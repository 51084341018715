import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Typography, Card, Button, CardActions, CardContent, Collapse, Tooltip } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import clsx from 'clsx'
import { format } from 'date-fns'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { deleteLessonAction, updateLessonAction } from '../../store/actions/progressBookActions'
import { connect } from 'react-redux'
import FlightConnector from './FlightConnector'


const styles = theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(4),
    padding: 10,
  },
  lesson: {
    width: '100%',
    border: 'solid black 1px',
    marginBottom: theme.spacing(2),
    borderCollapse: 'collapse',
    '& td': {
      padding: theme.spacing(1),
      verticalAlign: 'initial',
    },
  },
  stats: {
    borderRight: 'solid black 1px',
    width: 400,
  },
  title: {
    color: theme.palette.grey[600],
  },
  content: {
    color: 'black',
  },
  mergeBtn: {
    margin: 'auto',
    display: 'block',
    marginTop: -theme.spacing(2),
    padding: 0,
    '& svg': {
      verticalAlign: 'middle',
    },
  },
})

export const LessonsCard = ({ classes, className, lessons, items, updateLessonAction, deleteLessonAction }) => {

  const [expand, setExpand] = useState(false)

  const mergeTooltip = useMemo(() => 'Si les deux leçons correspondent au même ensemble briefing-vol, tu peux les fusionner', [])

  const canMerge = (lesson1, lesson2) =>
    format(lesson1.date.toDate(), 'dd-MM-yyyy') === format(lesson2.date.toDate(), 'dd-MM-yyyy')
    && lesson1.instructorRef?.id === lesson2.instructorRef?.id
    && !(lesson1.theoreticalItems && lesson2.theoreticalItems)
    && !(lesson1.practicalItems && lesson2.practicalItems)

  const doMerge  = (lesson1, lesson2) => {
    updateLessonAction(lesson1.id, {
      theoreticalItems: lesson1.theoreticalItems || lesson2.theoreticalItems,
      practicalItems: lesson1.practicalItems || lesson2.practicalItems,
      flightRef: lesson1.flightRef || lesson2.flightRef || null,
      comment: lesson1.comment || lesson2.comment || null,
    }).then(() => deleteLessonAction(lesson2.id))
  }

  return (
    <Card className={clsx(className, classes.root)}>
      <CardContent>
        <Button onClick={() => setExpand(val => !val)}>{expand ? 'Masquer' : 'Afficher'} les {lessons.length} leçons au format FFA</Button>
        <Collapse in={expand} style={{ width: '100%' }}>
          {lessons.map((lesson, index) => <div key={index}>
            {index > 0 && canMerge(lessons[index - 1], lesson) &&
            <Tooltip title={mergeTooltip}>
              <Button className={classes.mergeBtn} onClick={() => doMerge(lessons[index - 1], lesson)}>
                <ArrowDropUp />
                Fusionner les leçons
                <ArrowDropDown />
              </Button>
            </Tooltip>
            }
            <table className={classes.lesson}>
              <tbody>
                <tr>
                  <td className={classes.stats}>
                    <Typography className={classes.title}>Leçon n°: <span className={classes.content}>{index + 1}</span></Typography>
                    <Typography className={classes.title}>Date: <span className={classes.content}>{format(lesson.date.toDate(), 'dd-MM-yyyy')}</span></Typography>
                    <Typography className={classes.title}>Instructeur: <span className={classes.content}>{lesson.instructor?.firstname} {lesson.instructor?.lastname}</span></Typography>
                    <Typography className={classes.title}>Durée du briefing: <span className={classes.content}>{lesson.briefingDuration}</span></Typography>
                    {lesson.flight
                      ? <>
                        <Typography className={classes.title}>Durée du vol: <span className={classes.content}>{lesson.flight.duration}</span></Typography>
                        <Typography className={classes.title}>Avion: <span className={classes.content}>{lesson.flight.aircraft?.name}</span></Typography>
                        <Typography className={classes.title}>Zone: <span className={classes.content}>{lesson.flight.arrival?.ICAO} {lesson.flight.arrival?.name}</span></Typography>
                      </>
                      : <FlightConnector lesson={lesson} handleChange={flightId => updateLessonAction(lesson.id, { flightId })} />
                    }
                  </td>
                  <td>
                    {lesson.theoreticalItems && <>
                      <Typography className={classes.title}>Items abordés au briefing:</Typography>
                      <ul>
                        {items.map(item => Object.keys(lesson.theoreticalItems).includes(item.id) &&
                          <li key={item.id}>{item.name}</li>,
                        )}
                      </ul>
                    </>}
                    {lesson.practicalItems && <>
                      <Typography className={classes.title}>Figures travaillées pendant le vol:</Typography>
                      <ul>
                        {items.map(item => Object.keys(lesson.practicalItems).includes(item.id) &&
                          <li key={item.id}>{item.name}</li>,
                        )}
                      </ul>
                    </>}
                    {lesson.generalImpression && false && <>
                      <Typography className={classes.title}>Impression générale:</Typography>
                      <ul>
                        {lesson.generalImpression.map((val, item) =>
                          <li key={item}>{item}; {val}</li>,
                        )}
                      </ul>
                    </>}
                    {lesson.comment && <>
                      <Typography className={classes.title}>Commentaires:</Typography>
                      <Typography>{lesson.comment}</Typography>
                    </>}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>)}
        </Collapse>
      </CardContent>
      {expand && <CardActions className={classes.actions}>
        <Button color='primary' onClick={() => setExpand(false)} className={classes.expandBtn}>
          <ArrowDropUp />
        </Button>
      </CardActions>}
    </Card>
  )
}

LessonsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  lessons: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  updateLessonAction: PropTypes.func.isRequired,
  deleteLessonAction: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  connect(null, { updateLessonAction, deleteLessonAction }),
)(LessonsCard)
