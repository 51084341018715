import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Card, CardActions, CardContent, IconButton } from '@mui/material'
import { eachDayOfInterval, format, isSameDay } from 'date-fns'
import Slot from './Slot'
import { Add } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { addDocAction, updateDocAction } from '../../store/actions/firestoreActions'
import { ACTIVITY_COLLECTION, AIRCRAFT_COLLECTION, SLOT_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import Loading from '../_library/Loading'
import InstructorSelect from './InstructorSelect'
import { fr } from 'date-fns/locale'
import { head, last } from 'lodash'
import Request from './Request'


const PlanningCard = ({ sx }) => {

  const { aircraftRefs, firstDay, lastDay, id, organizerRef, pilotRefs = [] } = useSelector(state => state.data.selectedActivity || {})
  const slots = useSelector(state => state.firestore.slots)
  const requests = useSelector(state => state.firestore.requests)
  const dispatch = useDispatch()
  const allAircrafts = useSelector(state => state.firestore.aircrafts)
  const profile = useSelector(state => state.auth.profile)
  const adminMode = useSelector(state => state.data.adminMode)

  const [editMode, setEditMode] = useState(false)

  const days = useMemo(
    () => firstDay && lastDay && eachDayOfInterval({ start: firstDay.toDate(), end: lastDay.toDate() }),
    [firstDay, lastDay],
  )

  const aircrafts = useMemo(
    () => allAircrafts?.filter(a => aircraftRefs.map(({ id }) => id).includes(a.id)),
    [allAircrafts, aircraftRefs],
  )

  const handleSlotCreate = (day, period, aircraftId) => dispatch(addDocAction(SLOT_COLLECTION, {
    activityRef: [ACTIVITY_COLLECTION, id],
    aircraftRef: [AIRCRAFT_COLLECTION, aircraftId],
    day,
    period,
  }))

  const handleAcceptRequest = request => {
    const slot = slots.find(s =>
      (s.day.seconds === request.day.seconds || isSameDay(days[Math.floor(s.day) - 1], request.day.toDate()))
      && s.period === request.period
      && s.aircraftRef?.id === request.aircraftRef.id
      && !s.userRef,
    )
    if (slot) dispatch(updateDocAction(SLOT_COLLECTION, slot.id, { userRef: [USER_COLLECTION, request.userRef.id] }))
    if (slot && !pilotRefs.some(ref => ref.id === request.userRef.id))
      dispatch(updateDocAction(ACTIVITY_COLLECTION, id, { pilotRefs: [USER_COLLECTION, request.userRef.id, 'add'] }))
  }

  if (!aircrafts) return <Loading />
  else return (
    <Card sx={sx}>
      <CardContent>
        <Box component='table' sx={{
          borderCollapse: 'collapse',
          '& td': { borderLeft: '1px solid black' },
          width: '100%',
        }}>
          <thead>
            <tr>
              <th colSpan={2} />
              <th>FI</th>
              {aircrafts.map(aircraft => <th key={aircraft.id}>{aircraft.name}</th>)}
            </tr>
          </thead>
          <tbody>
            <Box component='tr' sx={{ borderTop: '1px solid black' }}>
              <th colSpan={2}>Convoyage</th>
              <td></td>
              {aircrafts.map(aircraft => <td key={'inbound'+aircraft.name}>
                <div>
                  {slots?.filter(s => typeof s.day !== 'number' && isSameDay(s.day?.toDate(), head(days)) && s.period === 'inbound' && s.aircraftRef?.id === aircraft.id).map((s, i) => <Slot key={i} disabled={!editMode} {...s} />)}
                  {editMode && <IconButton title='ajouter un créneau' onClick={() => handleSlotCreate(head(days), 'inbound', aircraft.id)} sx={{ p: 0 }}><Add /></IconButton>}
                </div>
                <div>
                  {requests?.filter(r => r.aircraftRef?.id === aircraft.id && isSameDay(r.day?.toDate(), head(days)) && r.period === 'inbound').map(r =>
                    <Request key={r.id} onClick={() => handleAcceptRequest(r)} disabled={!editMode} {...r} />,
                  )}
                </div>
              </td>)}
            </Box>
            {days.reduce((acc, val) => [...acc, { day: val, period: 'AM' }, { day: val, period: 'PM' }], []).map(({ day, period }, index) =>
              <Box key={day.getTime()+period} component='tr' sx={{ borderTop: period ==='AM' && '1px solid black' }}>
                {period ==='AM' && <th rowSpan={2}>{format(day, 'EEEE', { locale: fr })}</th>}
                <td style={{ border: 'none' }}>{period}</td>
                <td><InstructorSelect day={day} period={period} disabled={!editMode} /></td>
                {aircrafts.map(aircraft => <td key={aircraft.name + period}>
                  <div>
                    {slots?.filter(s => s.day === index / 2 + 1 && s.period === period && s.aircraftRef?.id === aircraft.id).map((s, i) => <Slot key={i} disabled={!editMode} {...s} />)}
                    {editMode && <IconButton title='ajouter un créneau' onClick={() => handleSlotCreate(index / 2 + 1, period, aircraft.id)} sx={{ p: 0 }}><Add /></IconButton>}
                  </div>
                  <div>
                    {requests?.filter(r => r.aircraftRef?.id === aircraft.id && isSameDay(r.day?.toDate(), day) && r.period === period).map(r =>
                      <Request key={r.id} onClick={() => handleAcceptRequest(r)} disabled={!editMode} {...r} />,
                    )}
                  </div>
                  {period === 'AM' && <Box sx={{ height: '8px' }} />}
                </td>)}
              </Box>,
            )}
            <Box component='tr' sx={{ borderTop: '1px solid black' }}>
              <th colSpan={2}>Convoyage</th>
              <td></td>
              {aircrafts.map(aircraft => <td key={'outbound'+aircraft.name}>
                <div>
                  {slots?.filter(s => typeof s.day !== 'number' && isSameDay(s.day?.toDate(), last(days)) && s.period === 'outbound' && s.aircraftRef?.id === aircraft.id).map((s, i) => <Slot key={i} disabled={!editMode} {...s} />)}
                  {editMode && <IconButton title='ajouter un créneau' onClick={() => handleSlotCreate(last(days), 'outbound', aircraft.id)} sx={{ p: 0 }}><Add /></IconButton>}
                </div>
                <div>
                  {requests?.filter(r => r.aircraftRef?.id === aircraft.id && isSameDay(r.day?.toDate(), last(days)) && r.period === 'outbound').map(r =>
                    <Request key={r.id} onClick={() => handleAcceptRequest(r)} disabled={!editMode} {...r} />,
                  )}
                </div>
              </td>)}
            </Box>
          </tbody>
        </Box>
      </CardContent>
      <CardActions>
        {(adminMode || organizerRef?.id === profile.id) && <Button fullWidth onClick={() => setEditMode(prev => !prev)}>
          {editMode ? 'Sauvegarder' : 'Modifier'}
        </Button>}
      </CardActions>
    </Card>
  )
}

PlanningCard.propTypes = {
  activity: PropTypes.object,
  sx: PropTypes.object,
}

export default PlanningCard
