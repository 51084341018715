import React, { useEffect, useState } from 'react'
import { Stepper, Step, StepButton, Box } from '@mui/material'
import Welcome from './Welcome'
import CoordinatesForm from './CoordinatesForm'
import { useSelector } from 'react-redux'
import IdentityForm from './IdentityForm'
import ExperienceForm from './ExperienceForm'
import SumUp from './SumUp'


function getSteps() {
  return ['Bienvenue', 'Identité', 'Coordonnées', 'Validités', 'Résumé']
}

const OnboardingPage = () => {
  
  const profile = useSelector(state => state.auth.profile)
  
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})
  const steps = getSteps()

  useEffect(() => {
    if (profile.experience){
      setCompleted(obj => ({ ...obj, 4: true }))
      setActiveStep(4)
    }
    else setActiveStep(3)
    if (profile.experience) setCompleted(obj => ({ ...obj, 3: true }))
    if (profile.phone && profile.address) setCompleted(obj => ({ ...obj, 2: true }))
    else setActiveStep(2)
    if (profile.gender) setCompleted(obj => ({ ...obj, 1: true }))
    else setActiveStep(1)
    if (!profile.hasNoPassword) setCompleted(obj => ({ ...obj, 0: true }))
    else setActiveStep(0)
  }, [profile])

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStep = step => () => {
    setActiveStep(step)
  }

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <Welcome next={handleNext} />
      case 1:
        return <IdentityForm back={handleBack} next={handleNext} />
      case 2:
        return <CoordinatesForm back={handleBack} next={handleNext} />
      case 3:
        return <ExperienceForm back={handleBack} next={handleNext} />
      case 4:
        return <SumUp back={handleBack} />
      default:
        return 'Unknown stepIndex'
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel nonLinear>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {getStepContent(activeStep)}
      </div>
    </Box>
  )
}

export default OnboardingPage
