import React from 'react'
import PropTypes from 'prop-types'
import { TextField, Autocomplete } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Loading from './Loading'
import { selectAccount } from '../../store/actions/dataActions'


const AccountSelector = ({ className }) => {
  
  const accounts = useSelector(state => state.firestore.accounts?.filter(a => a.activated))
  const selectedAccount = useSelector(state => state.data.selectedAccount)
  const dispatch = useDispatch()
  
  if (!accounts) return (<Loading/>)
  else return (
    <Autocomplete
      options={accounts}
      getOptionLabel={option => option.name || ''}
      renderInput={params => <TextField variant='standard' {...params} label='Compte' sx={{ color: 'white' }}/>}
      value={selectedAccount || null}
      onChange={(event, newValue) => dispatch(selectAccount(newValue))}
      isOptionEqualToValue={(option, value) => option.id === value?.id || {}}
      disableClearable
      className={className}
      onInputChange={(event, inputValue) => {
        const account = accounts.find(a => a.name === inputValue)
        if (account && account?.id !== selectedAccount?.id) dispatch(selectAccount(account))
      }}
      sx={{ display: 'inline-flex', minWidth: '200px' }}
    />
  )
}

AccountSelector.propTypes = {
  className: PropTypes.string,
}

export default AccountSelector
