import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, CircularProgress, Avatar as MuiAvatar } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { compose } from 'recompose'
import { connect } from 'react-redux'


const styles = () => ({
  title: {
    color: 'rgba(0,0,0,.54)',
    display: 'inline-block',
    lineHeight: '20px',
    width: '200px',
  },
  value: {
    display: 'inline-block',
    color: 'black',
  },
  editButton: {
    cursor: 'pointer',
    position: 'relative',
  },
  dialogButton: {
    fontWeight: 600,
  },
  inputfile: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '-1',
  },
  spinner: {
    width: '35px',
    height: '35px',
    position: 'absolute',
    left: 0,
    backgroundColor: 'rgba(255,255,255,.5)',
  },
  bigAvatar: {
    width: 35,
    height: 35,
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
})

const AircraftAvatarEditor = ({ classes, aircraft, uploadProfilePicture }) => {

  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const [prevPhotoUrl] = useState(aircraft.photoUrl)
  useEffect(() => {
    if (aircraft.photoUrl !== prevPhotoUrl){
      setLoading(false)
    }
  }, [aircraft.photoUrl, prevPhotoUrl])

  const submit = event => {
    setLoading(true)
    uploadProfilePicture(
      event.target.files[0],
      aircraft.id,
    ).on('state_changed',
      function progress(snapshot) {
        setProgress(100 * snapshot.bytesTransferred / snapshot.totalBytes)
      },
      function error(err) {
        console.error(err)
      },
      function complete() {
        console.log('upload complete')
      },
    )
  }

  return (
    <div>
      <Typography className={classes.title} color='textSecondary' gutterBottom>
        Photo de profil
      </Typography>
      <label
        htmlFor='file'
        className={classes.editButton}
        style={{ minWidth: '40px' }}
      >
        <MuiAvatar
          alt={aircraft.name}
          src={aircraft.photoUrl}
          className={classes.bigAvatar}
        />
        {loading && <CircularProgress className={classes.spinner} color='inherit' size={35} value={progress} variant='determinate' />}
      </label>
      <input
        type='file'
        name='file'
        id='file'
        className={classes.inputfile}
        onChange={submit}
      />
    </div>
  )
}

const mapActionsToProps = ({
  uploadProfilePicture: (file, id) => (dispatch, getState, { getFirebase }) =>
    getFirebase()
      .storage()
      .ref(`aircraft-pictures/${ id }-${ (new Date()).getMilliseconds() }.${ file.name.split('.').pop() }`)
      .put(file),
})

AircraftAvatarEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  aircraft: PropTypes.object.isRequired,
  uploadProfilePicture: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  connect(null, mapActionsToProps),
)(AircraftAvatarEditor)
