import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, DialogActions, DialogContent, FormControl, InputLabel, Select, MenuItem, Grid, FormHelperText,
  Typography, ToggleButtonGroup, ToggleButton } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'recompose'
import { updateDocAction } from '../../store/actions/firestoreActions'
import { addMinutes } from 'date-fns'
import UserSelect from '../_library/UserAutocomplete'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { AIRCRAFT_COLLECTION, BOOKING_COLLECTION, FLIGHT_TYPE_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { pickBy } from 'lodash'


const styles = theme => ({
  select: {
    width: '100%',
  },
  aircraftBtn: {
    '&.MuiToggleButton-root.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: 'black',
    },
  },
  subTitle: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
})

const EditBookingForm = ({ classes, onClose, booking }) => {
  
  const dispatch = useDispatch()
  const aircrafts = useSelector(state => state.firestore.aircrafts?.filter(a => a.activated))
  const flightTypes = useSelector(state => state.firestore.flightTypes?.filter(a => a.activated))
  const users = useSelector(state => state.firestore.users)
  const profile = useSelector(state => state.auth.profile)

  const [startDate, setStartDate] = useState(booking.startDate.toDate())
  const [endDate, setEndDate] = useState(booking.endDate.toDate())
  const [comments, setComments] = useState(booking.comments)
  const [aircraftId, setAircraftId] = useState(booking.aircraftRef.id)
  const [flightTypeId, setFlightTypeId] = useState(booking.flightTypeRef.id)
  const [pilot, setPilot] = useState(null)
  const [instructor, setInstructor] = useState(null)
  const [error, setError] = useState({})
  const [submitted, setSubmitted] = useState(false)

  const availableInstructors = useMemo(() => users?.filter(u => (u.roles?.includes('instructeur'))), [users])

  useEffect(() => startDate && setEndDate(addMinutes(startDate, 45)), [startDate])

  useEffect(() => {
    if (users && booking.pilotRef)
      setPilot(users.find(u => u.id === booking.pilotRef.id))
    if (users && booking.instructorRef)
      setInstructor(users.find(u => u.id === booking.instructorRef.id))
  }, [users, booking])

  const handleSubmit = () => {
    if (!aircraftId)
      setError(prev => ({ ...prev, aircraft: 'missing-aircraft' }))
    if (!flightTypeId)
      setError(prev => ({ ...prev, flightType: 'missing-type' }))
    if (aircraftId && flightTypeId) {
      setSubmitted(true)
      dispatch(updateDocAction(BOOKING_COLLECTION, booking.id, pickBy({
        instructorRef: [USER_COLLECTION, instructor?.id],
        startDate,
        endDate,
        aircraftRef: [AIRCRAFT_COLLECTION, aircraftId],
        pilotRef: [USER_COLLECTION, pilot.id],
        flightTypeRef: [FLIGHT_TYPE_COLLECTION, flightTypeId],
        comments,
      })))
        .then(onClose)
        .finally(() => setSubmitted(false))
    }
  }

  return <>
    <DialogContent>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='body2'>Début</Typography>
        </Grid>
        <Grid item xs={7}>
          <DatePicker
            disableToolbar
            variant='inline'
            inputFormat='dd-MM-yyyy'
            margin='dense'
            id='startDate-date'
            value={startDate}
            fullWidth
            onChange={setStartDate}
          />
        </Grid>
        <Grid item xs={5}>
          <TimePicker
            variant='inline'
            ampm={false}
            margin='dense'
            id='startDate-time'
            value={startDate}
            onChange={setStartDate}
            minutesStep={5}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2'>Fin</Typography>
        </Grid>
        <Grid item xs={7}>
          <DatePicker
            disableToolbar
            variant='inline'
            inputFormat='dd-MM-yyyy'
            margin='dense'
            id='endDate-date'
            value={endDate}
            fullWidth
            onChange={setEndDate}
          />
        </Grid>
        <Grid item xs={5}>
          <TimePicker
            variant='inline'
            ampm={false}
            margin='dense'
            id='endDate-time'
            value={endDate}
            onChange={setEndDate}
            minutesStep={5}
          />
        </Grid>
        <Grid item>
          <Typography variant='body2'>Avion</Typography>
          <ToggleButtonGroup
            size='large'
            value={aircraftId}
            exclusive
            onChange={(event, value) => value && setAircraftId(value)}
            style={{ border: error.aircraft && 'solid red' }}
          >
            {aircrafts && aircrafts.map(aircraft =>
              <ToggleButton key={aircraft.id} value={aircraft.id} disabled={!aircraft.bookable} className={classes.aircraftBtn}>
                {aircraft.name}
              </ToggleButton>,
            )}
          </ToggleButtonGroup>
          {error.aircraft && <FormHelperText>{error.aircraft}</FormHelperText>}
        </Grid>
        <Grid item xs={12} id='flightType'>
          <FormControl
            variant='standard'
            className={classes.select}
            required
            error={!!error.flightType}>
            <InputLabel id='type-select-label' shrink>Type de vol</InputLabel>
            <Select
              variant='standard'
              labelId='type-select-label'
              id='type-select'
              value={flightTypeId || ''}
              onChange={e => setFlightTypeId(e.target.value)}>
              {flightTypes?.map(type =>
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>,
              )}
            </Select>
            {error.flightType && <FormHelperText color='red'>{error.flightType}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid container id='crew' item xs={12} spacing={3}>
          <Typography variant='subtitle1' className={classes.subTitle}>Equipage</Typography>
          <Grid item xs={6} >
            {users && <UserSelect
              users={users}
              disabled={!profile.roles?.includes('admin') && !profile.roles?.includes('instructeur')}
              user={pilot}
              label='Pilote'
              className={classes.select}
              set={setPilot}
              defaultValue={users && users.find(u => u.id === profile.id)}
            />}
          </Grid>
          <Grid item xs={6}>
            {availableInstructors && <UserSelect
              users={availableInstructors}
              user={instructor}
              label='Instructeur'
              className={classes.select}
              set={setInstructor}
            />}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant='standard'
            margin='dense'
            id='comments'
            label='Commentaires'
            fullWidth
            value={comments || ''}
            multiline
            onChange={e => setComments(e.target.value)} />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color='primary'>
        Annuler
      </Button>
      <Button onClick={handleSubmit} color='primary' disabled={submitted}>
        Valider
      </Button>
    </DialogActions>
  </>
}

EditBookingForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
)(EditBookingForm)
