import React, { useState } from 'react'
import { Grid, Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import OutlinedTextField from '../_library/OutlinedTextField'
import { resetPasswordAction, signInAction } from '../../store/actions/authActions'


export const LoginForm = () => {
  
  const dispatch = useDispatch()

  // STATES
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [forgottenPwd, setForgottenPwd] = useState(false)

  const submit = e => {
    e.preventDefault()
    forgottenPwd
      ? dispatch(resetPasswordAction(email))
      : dispatch(signInAction({ email, password }))
  }

  return (
    <form onSubmit={submit} id='loginForm'>
      <Grid container>
        <Grid item xs={12}>
          <OutlinedTextField
            required
            onChange={e => setEmail(e.target.value)}
            name='email'
            type='email'
            value={email || ''}
            label='Email'
            sx={{
              width: { xs: '80%', sm: '60%' },
              borderRadius: .5,
            }}
          />
        </Grid>

        {!forgottenPwd && <Grid item xs={12}>
          <OutlinedTextField
            required
            onChange={e => setPassword(e.target.value)}
            name='password'
            type='password'
            value={password || ''}
            label='Mot de passe'
            sx={{
              width: { xs: '80%', sm: '60%' },
              borderRadius: .5,
            }}
          />
        </Grid>}

        <Button variant='text' onClick={() => setForgottenPwd(prev => !prev)} sx={{ width: '100%', fontSize: '.9rem' }}>
          {forgottenPwd ? 'Connexion' : 'Mot de passe oublié ?'}
        </Button>

        <Grid container direction={'column'} alignItems={'center'}>
          {forgottenPwd
            ? <Button type='submit' variant='contained' color='primary' disabled={!email} sx={{ my: 1, width: 'fit-content' }}>
              Recevoir le lien par email
            </Button>
            : <Button type='submit' variant='contained' color='primary' disabled={!email || !password} sx={{ my: 1, width: 'fit-content' }}>
              Connexion
            </Button>
          }
        </Grid>
      </Grid>
    </form>
  )
}

export default LoginForm
