import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import {
  Button, Card, CardActions, CardContent, CardHeader, Divider, FormControl, Grid, MenuItem, Select, TextField,
  Typography,
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { QUALIFICATION_COLLECTION, STATUS_APPLICATION_VALIDATED, USER_COLLECTION } from '../../_constants/globals'
import Loading from '../_library/Loading'
import InlineEditor from '../_library/InlineEditor'
import QualificationCard from '../qualifications/QualificationCard'
import { MEMBERS } from '../../_constants/routes'
import Avatar from '../_library/Avatar'
import { listenCollection, updateDocAction } from '../../store/actions/firestoreActions'
import { DatePicker } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router'


const styles = () => ({
  root: {
    margin: '2rem auto',
    maxWidth: 900,
  },
  cardTitle: {
    marginBottom: '1rem',
  },
  card: {
    minWidth: 275,
    marginBottom: '2rem',
  },
  formField: {
    marginBottom: '1rem',
  },
  label: {
    color: 'rgba(0,0,0,.54)',
    display: 'inline-block',
    lineHeight: '20px',
    width: 200,
  },
  field: {
    display: 'inline-block',
  },
  actions: {
    position: 'relative',
    padding: 30,
  },
  validateBtn: {
    position: 'absolute',
    right: 30,
  },
})

const AccountValidation = ({ classes, subscription, updateDocAction, qualifications, userId, profile, listenCollection }) => {
  
  const navigate = useNavigate()
  
  useEffect(() => {
    const listener = listenCollection({
      collection: QUALIFICATION_COLLECTION,
      where: [['userRef', '==', USER_COLLECTION, userId]],
    })
    return () => {
      listener()
    }
  }, [listenCollection, userId])
  
  const validateAccount = () => {
    updateDocAction(USER_COLLECTION, userId, {
      status: STATUS_APPLICATION_VALIDATED,
      roles: ['pilote'],
      activatedByRef: [USER_COLLECTION, profile.id],
    }).then(() => navigate(MEMBERS))
  }
  
  const updateUserAction = (uid, data) => updateDocAction(USER_COLLECTION, uid, data)
  
  if (!subscription) return <Loading />
  else return (
    <div className={classes.root}>
      <Typography variant='body2' className={classes.cardTitle} color='textSecondary' gutterBottom>Identité</Typography>
      <Card className={classes.card} variant='outlined'>
        <CardContent>
          <Grid item xs={12} className={classes.formField}>
            <InlineEditor title='Nom' value={subscription.lastname} handleSubmit={val => updateUserAction(userId, { lastname: val })} />
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <InlineEditor title='Prénom' value={subscription.firstname} handleSubmit={val => updateUserAction(userId, { firstname: val })} />
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <Typography className={classes.label} color='textSecondary' gutterBottom>
              Sexe
            </Typography>
            <FormControl variant='outlined' className={classes.formControl}>
              <Select
                variant='standard'
                id='sex-select'
                value={subscription.gender || 'woman'}
                onChange={e => updateUserAction(userId, { gender: e.target.value })}
                className={classes.sexSelect}
                margin='dense'>
                <MenuItem value={'woman'}>Féminin</MenuItem>
                <MenuItem value={'man'}>Masculin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <Typography className={classes.label} color='textSecondary' gutterBottom>
              Date de naissance
            </Typography>
            <DatePicker
              inputFormat='dd-MM-yyyy'
              margin='dense'
              id='birthdate'
              value={subscription.birthdate ? subscription.birthdate.toDate() : new Date()}
              onChange={val => updateUserAction(userId, { birthdate: val })}
              renderInput={params => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <InlineEditor title='Nationalité' value={subscription.nationality} handleSubmit={val => updateUserAction(userId, { nationality: val })} />
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <Typography className={classes.label} color='textSecondary' gutterBottom>
              Photo
            </Typography>
            <Avatar user={subscription} />
          </Grid>
        </CardContent>
      </Card>
      
      <Typography variant='body2' className={classes.cardTitle} color='textSecondary' gutterBottom>Coordonnées</Typography>
      <Card className={classes.card} variant='outlined'>
        <CardContent>
          <Grid item xs={12} className={classes.formField}>
            <InlineEditor title='Email' value={subscription.email} handleSubmit={val => updateUserAction(userId, { email: val })} />
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <InlineEditor title='Téléphone' value={subscription.phone} handleSubmit={val => updateUserAction(userId, { phone: val })} />
          </Grid>
          <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
          <Typography variant='body1'>Adresse</Typography>
          <Grid item xs={12} className={classes.formField}>
            <InlineEditor title='Rue' value={subscription.address.street} handleSubmit={val => updateUserAction(userId, { address: { street: val } })} />
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <InlineEditor title='Code postal' value={subscription.address.zipcode} handleSubmit={val => updateUserAction(userId, { address: { zipcode: val } })} />
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <InlineEditor title='Ville' value={subscription.address.city} handleSubmit={val => updateUserAction(userId, { address: { city: val } })} />
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <InlineEditor title='Pays' value={subscription.address.country} handleSubmit={val => updateUserAction(userId, { address: { country: val } })} />
          </Grid>
        </CardContent>
      </Card>
      
      <Typography variant='body2' className={classes.cardTitle} color='textSecondary' gutterBottom>Qualifications</Typography>
      {qualifications && qualifications.map(qualification =>
        <QualificationCard qualification={qualification} key={qualification.typeRef.id} />,
      )}
      
      {subscription.onboardingComments && <Card className={classes.card} variant='outlined'>
        <CardHeader
          subheader='Commentaire du nouveau membre'
        />
        <CardContent>
          <Typography variant='body2'>{subscription.onboardingComments}</Typography>
        </CardContent>
      </Card>}
      
      <Card className={classes.card} variant='outlined'>
        <CardActions className={classes.actions}>
          <Typography variant='body1'>Valider la demande d&apos;inscription</Typography>
          <Button
            variant='contained'
            size='large'
            color='primary'
            className={classes.validateBtn}
            onClick={validateAccount}
          >
            Valider
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}


const mapStateToProps = (state, props) => ({
  subscription: state.firestore.users?.find(u => u.id === props.userId),
  qualifications: state.firestore.qualifications?.filter(q => q.userRef.id === props.userId),
  profile: state.auth.profile,
})

AccountValidation.propTypes = {
  classes: PropTypes.object.isRequired,
  subscription: PropTypes.object,
  updateDocAction: PropTypes.func.isRequired,
  qualifications: PropTypes.array,
  userId: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  listenCollection: PropTypes.func.isRequired,
}

export default compose(
  connect(mapStateToProps, { updateDocAction, listenCollection }),
  withStyles(styles),
)(AccountValidation)
