import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Card, CardContent, Typography, Button, CardActions, TextField } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import { signOutAction, updatePasswordAction } from '../../store/actions/authActions'
import { updateDocAction } from '../../store/actions/firestoreActions'
import { USER_COLLECTION } from '../../_constants/globals'


const styles = theme => ({
  root: {
    maxWidth: 900,
    margin: '2rem auto',
  },
  passwordField: {
    marginTop: theme.spacing(2),
  },
})

const Welcome = ({ classes, next, updatePasswordAction, signOutAction, profile, updateDocAction }) => {

  const [password, setPassword] = useState(null)
  const [errors, setErrors] = useState({})

  const onNext = () => {
    if (profile.hasNoPassword){
      if (!password || password.length < 6)
        setErrors(prev => ({ ...prev, password: 'Le mot de passe doit contenir au moins 6 caractères' }))
      else
        updatePasswordAction(password)
          .then(updateDocAction(USER_COLLECTION, profile.id, { hasNoPassword: false }).then(next))
          .catch(signOutAction)
    }
    else next()
  }

  return (
    <Card className={classes.root} variant='outlined'>
      <CardContent>
        <Typography variant='h1'>Bienvenue chez MPV</Typography>
        <Typography variant='body1'>Tu es actuellement sur la page d&apos;adhésion au club.</Typography>
        {profile.hasNoPassword && <>
          <Typography variant='body1'>Commence par choisir un mot de passe et clique sur Suivant</Typography>
          <TextField
            variant='standard'
            label='Mot de passe'
            value={password || ''}
            onChange={e => setPassword(e.target.value)}
            error={Boolean(errors.password)}
            helperText={errors.password}
            type='password'
            className={classes.passwordField}
            onKeyPress={e => e.key === 'Enter' && onNext()} />
        </>}
      </CardContent>
      <CardActions style={{ float: 'right' }}>
        <Button size='large' color='secondary' onClick={signOutAction}>
          Déconnexion
        </Button>
        <Button size='large' color='primary' onClick={onNext}>
          Suivant
        </Button>
      </CardActions>
    </Card>
  )
}

const mapStateToProps = state => ({
  profile: state.auth.profile,
})

Welcome.propTypes = {
  classes: PropTypes.object.isRequired,
  next: PropTypes.func.isRequired,
  updatePasswordAction: PropTypes.func.isRequired,
  updateDocAction: PropTypes.func.isRequired,
  signOutAction: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { updatePasswordAction, signOutAction, updateDocAction }),
)(Welcome)
