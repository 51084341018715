import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, CircularProgress, Button, TableRow, TableCell, Box } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { format, formatDistanceToNow, differenceInCalendarMonths } from 'date-fns'
import DeleteButton from '../_library/DeleteButton'
import { Check, Clear, Edit } from '@mui/icons-material'
import { deleteQualificationAction, updateQualificationAction } from '../../store/actions/qualifcationActions'
import EditQualificationDialog from './EditQualificationDialog'
import useListener from '../../hooks/useListener'
import { listenCollection } from '../../store/actions/firestoreActions'
import { QUALIFICATION_COLLECTION, USER_COLLECTION } from '../../_constants/globals'


const UserQualificationsTable = () => {
  
  const profile = useSelector(state => state.auth.profile)
  const adminMode = useSelector(state => state.data.adminMode)
  const selectedUser = useSelector(state => state.data.selectedUser || state.auth.profile)
  const qualifications = useSelector(state => state.firestore.qualifications?.filter(q => state.data.selectedUser
    ? q.userRef.id === selectedUser?.id
    : q.userRef.id === state.auth.profile.id,
  ))
  const dispatch = useDispatch()
  useListener(() => listenCollection({
    collection: QUALIFICATION_COLLECTION,
    where: [['userRef', '==', USER_COLLECTION, selectedUser.id]],
    orderBy: [
      ['archived', 'asc'],
      ['type.order', 'asc'],
      ['_createdAt', 'desc'],
    ],
  }), [selectedUser], !!selectedUser)
  
  
  const [editQualification, setEditQualification] = useState(null)
  
  // Select only the first element for each typeRef
  // The input must be an array sorted by desc _createdAt
  const data = useMemo(() => qualifications?.filter(q => !q.archived).map(val => {
    const { id, type, identValue, grantDate, expireDate, noAlert, attachmentUrl, checkingComment, checkingDate, checker } = val
    return [
      id,
      type.shortName,
      identValue,
      grantDate,
      expireDate,
      noAlert ? '✖' : '✔',
      attachmentUrl,
      checkingComment,
      checkingDate ? '✔' : '✖',
      checker,
      val,
    ]
  }), [qualifications])
  
  return (
    <Box sx={{
      m: 2,
      '& .MuiTableCell-body': {
        fontSize: '0.9rem',
        p: 1,
      },
    }}>
      {!data
        ? <Grid container item xs={12} justifyContent='center' sx={{ mt: '30px' }}>
          <CircularProgress size={20} />
        </Grid>
        
        : <MUIDataTable
          title={`Validités de ${selectedUser?.firstname}`}
          data={data}
          columns={[
            { name: 'id', options: { filter: false, sort: false, display: 'false' } },
            { name: 'Type', options: { filter: true, sort: true } },
            { name: 'Identifiant', options: { filter: true, sort: true, display: 'false' } },
            {
              name: 'Obtention',
              options: {
                filter: true,
                sort: true,
                display: 'false',
                customBodyRender: date => date && format(date.toDate(), 'dd-MM-yyyy'),
              },
            },
            {
              name: 'Limite de validité',
              options: {
                filter: true,
                sort: true,
                customBodyRender: date => {
                  if (!date) return null
                  let expires
                  const differenceInMonths = date &&  differenceInCalendarMonths(date.toDate(), new Date())
                  if (!date) expires = ''
                  else if (differenceInMonths < 0) expires = '️⛔ expired'
                  else if (differenceInMonths < 3) expires = '⚠️ expires'
                  else expires = '✅️ expires'
                  return expires + ' ' + formatDistanceToNow(date.toDate(), { addSuffix: true }) + ' (' + format(date.toDate(), 'dd-MM-yyyy') + ')'
                },
              },
            },
            { name: 'Alerte ?', options: { filter: true, sort: true, display: 'false' } },
            {
              name: 'Document',
              options: {
                filter: true,
                sort: true,
                // eslint-disable-next-line react/display-name
                customBodyRender: url => url && <a href={url} target='_blank' rel='noopener noreferrer' >Preview</a>,
              },
            },
            { name: 'Commentaire', options: { filter: true, sort: true, display: 'false' } },
            { name: 'Etat', options: { filter: true, sort: true } },
            { name: 'Checker', options: { filter: true, sort: true, display: 'false', customBodyRender: u => u && u.firstname + ' ' + u.lastname } },
            {
              name: 'Actions',
              options: {
                filter: false,
                sort: false,
                // eslint-disable-next-line react/display-name
                customBodyRender: qualification =>
                  <>
                    <DeleteButton deleteAction={() => dispatch(deleteQualificationAction(qualification.id))} />
                    <Button
                      onClick={() => setEditQualification(qualification)}
                      title='modifier la validité'
                      sx={{ minWidth: '40px' }}
                    >
                      <Edit />
                    </Button>
                    {adminMode && // eslint-disable-line react/prop-types
                      <Button
                        onClick={() => dispatch(updateQualificationAction(qualification.id, {
                          checkerId: qualification.checkerRef ? null : profile.id, // eslint-disable-line react/prop-types
                        }))}
                        title='vérifier la validité'
                        sx={{ minWidth: '40px' }}
                      >
                        {qualification.checkerRef ? <Clear /> : <Check /> }
                      </Button>
                    }
                  </>,
              },
            },
          ]}
          options={{
            selectableRows: 'none',
            pagination: false,
            expandableRows: adminMode,
            expandableRowsHeader: adminMode,
            expandableRowsOnClick: adminMode,
            isRowExpandable: dataIndex => data[dataIndex] && !!data[dataIndex][4] && adminMode,
            renderExpandableRow: rowData => {
              const history = qualifications.filter(q => q.type.name === rowData[1] && q.id !== rowData[0])
              return (
                history.map(qualification => <TableRow key={qualification.id} style={{ fontStyle: 'italic' }}>
                  <TableCell>
                    Historique
                  </TableCell>
                  <TableCell>
                    {qualification.type.shortName}
                  </TableCell>
                  <TableCell>
                    {qualification.identValue}
                  </TableCell>
                  <TableCell>
                    {qualification.grantDate && format(qualification.grantDate.toDate(), 'dd-MM-yyyy')}
                  </TableCell>
                  <TableCell>
                    {qualification.expireDate && format(qualification.expireDate.toDate(), 'dd-MM-yyyy')}
                  </TableCell>
                  <TableCell>
                    {qualification.attachmentUrl}
                  </TableCell>
                  <TableCell>
                    {qualification.checkingComments}
                  </TableCell>
                  <TableCell>
                    {qualification.checkingDate ? '✔' : '✖'}
                  </TableCell>
                  <TableCell>
                    {qualification.checker && qualification.checker.firstname + ' ' + qualification.checker.lastname}
                  </TableCell>
                  <TableCell>
                    <DeleteButton deleteAction={() => dispatch(deleteQualificationAction(qualification.id))} />
                  </TableCell>
                </TableRow>)
              )
            },
          }}
        />
      }
      {editQualification && <EditQualificationDialog onClose={() => setEditQualification(null)} qualification={editQualification} />}
    </Box>
  )
}

export default UserQualificationsTable
