import { FLIGHT_CREATE_SUCCESS, ERROR, FLIGHT_UPDATE_SUCCESS } from '../types'
import {
  AIRCRAFT_COLLECTION, AIRPORT_COLLECTION, BILLING_TYPE_COLLECTION, BOOKING_COLLECTION, FLIGHT_COLLECTION, FLIGHT_TYPE_COLLECTION,
  USER_COLLECTION,
} from '../../_constants/globals'
import { collection, doc, addDoc, serverTimestamp, updateDoc, deleteDoc } from 'firebase/firestore'


export const createFlightAction = flight => (dispatch, getState, { db }) => {
  console.log('create flight action', flight)
  if (flight.pilotId) flight.pilotRef = doc(db, USER_COLLECTION, flight.pilotId)
  else flight.pilotRef = doc(db, USER_COLLECTION, getState().auth.profile.id)
  delete flight.pilotId
  if (flight.bookingId) flight.bookingRef = doc(db, BOOKING_COLLECTION, flight.bookingId)
  delete flight.bookingId
  if (flight.instructorId) flight.instructorRef = doc(db, USER_COLLECTION, flight.instructorId)
  delete flight.instructorId
  return addDoc(collection(db, FLIGHT_COLLECTION), {
    ...flight,
    aircraftRef: doc(db, AIRCRAFT_COLLECTION, flight.aircraftId),
    departureRef: doc(db, AIRPORT_COLLECTION, flight.departureId),
    arrivalRef: doc(db, AIRPORT_COLLECTION, flight.arrivalId),
    flightTypeRef: doc(db, FLIGHT_TYPE_COLLECTION, flight.flightTypeId),
    billingTypeRef: doc(db, BILLING_TYPE_COLLECTION, flight.billingTypeId),
    _createdAt: serverTimestamp(),
    createdByRef: doc(db, USER_COLLECTION, getState().auth.profile.id),
  })
    .then(doc => {
      dispatch({ type: FLIGHT_CREATE_SUCCESS })
      return doc
    })
    .catch(err =>
      dispatch({ type: ERROR, payload: err }),
    )
}

export const updateFlightAction = flight => (dispatch, getState, { db }) =>
  updateDoc(doc(db, FLIGHT_COLLECTION, flight.id), {
    ...flight,
    _updatedAt: serverTimestamp(),
    updatedByRef: doc(db, USER_COLLECTION, getState().auth.profile.id),
  })
    .then(() =>
      dispatch({ type: FLIGHT_UPDATE_SUCCESS }),
    )
    .catch(err => {
      console.error(err)
      dispatch({ type: ERROR, err })
    })

export const deleteFlightAction = id => (dispatch, getState, { db }) =>
  deleteDoc(doc(db, FLIGHT_COLLECTION, id))
    .catch(err => {
      console.error(err)
      dispatch({ type: ERROR, err })
    })
