export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const WARNING = 'WARNING'
export const INFO = 'INFO'
export const LOADING = 'LOADING'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const INVITE_MEMBER_SUCCESS = 'INVITE_MEMBER_SUCCESS'
export const INVITE_MEMBER_ERROR = 'INVITE_MEMBER_ERROR'

export const EMAIL_SENT = 'EMAIL_SENT'
export const EMAIL_ERROR = 'EMAIL_ERROR'

export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'
export const UPLOAD_ERROR = 'UPLOAD_ERROR'
export const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE'

// DB LISTEN
export const LISTEN_COLLECTION = 'LISTEN_COLLECTION'
export const LISTEN_SETTING = 'LISTEN_SETTING'
export const LISTEN_ACCOUNT_ENTRIES = 'LISTEN_ACCOUNT_ENTRIES'
export const LISTEN_ACCOUNT_FLUX = 'LISTEN_ACCOUNT_FLUX'
export const LISTEN_ACCOUNTS = 'LISTEN_ACCOUNTS'
export const LISTEN_AIRCRAFTS = 'LISTEN_AIRCRAFTS'
export const LISTEN_AIRPORTS = 'LISTEN_AIRPORTS'
export const LISTEN_AVAILABILIIES = 'LISTEN_AVAILABILIIES'
export const LISTEN_BILLING_TYPES = 'LISTEN_BILLING_TYPES'
export const LISTEN_BOOKINGS = 'LISTEN_BOOKINGS'
export const LISTEN_EMAILS = 'LISTEN_EMAILS'
export const LISTEN_FLIGHT_TYPES = 'LISTEN_FLIGHT_TYPES'
export const LISTEN_FLIGHTS = 'LISTEN_FLIGHTS'
export const LISTEN_PROGRESSBOOKS = 'LISTEN_PROGRESSBOOKS'
export const LISTEN_PROGRESSBOOKS_ITEMS = 'LISTEN_PROGRESSBOOKS_ITEMS'
export const LISTEN_PROGRESSBOOKS_LESSONS = 'LISTEN_PROGRESSBOOKS_LESSONS'
export const LISTEN_PROGRESSBOOKS_TEMPLATES = 'LISTEN_PROGRESSBOOKS_TEMPLATES'
export const LISTEN_QUALIFICATION_TYPES = 'LISTEN_QUALIFICATION_TYPES'
export const LISTEN_QUALIFICATIONS = 'LISTEN_QUALIFICATIONS'
export const LISTEN_USERS = 'LISTEN_USERS'
export const LISTEN_PROFILE = 'LISTEN_PROFILE'

export const FETCH_ROLES = 'FETCH_ROLES'
export const FETCH_LICENSES = 'FETCH_LICENSES'
export const FETCH_SESSIONS = 'FETCH_SESSIONS'
export const FETCH_TAG_SETS = 'FETCH_TAG_SETS'
export const FETCH_TAG_SETS_TEMPLATES = 'FETCH_TAG_SETS_TEMPLATES'
export const FETCH_AVAILABLE_LICENSES = 'FETCH_AVAILABLE_LICENSES'
export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USERS_WITHOUT_LICENSE = 'FETCH_USERS_WITHOUT_LICENSE'
export const FETCH_COMPANIES = 'FETCH_COMPANIES'
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS'

export const FETCH_USER_FLIGHTS = 'FETCH_USER_FLIGHTS'
export const FETCH_AIRCRAFT_FLIGHTS = 'FETCH_AIRCRAFT_FLIGHTS'
export const FETCH_FLIGHT = 'FETCH_FLIGHT'
export const FLIGHT_CREATE_SUCCESS = 'FLIGHT_CREATE_SUCCESS'
export const FLIGHT_UPDATE_SUCCESS = 'FLIGHT_UPDATE_SUCCESS'

export const BOOKING_CREATE_SUCCESS = 'BOOKING_CREATE_SUCCESS'
export const BOOKING_UPDATE_SUCCESS = 'BOOKING_UPDATE_SUCCESS'
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const NOTIFICATIONS_ALLOWED = 'NOTIFICATIONS_ALLOWED'
export const SELECT_AIRCRAFT = 'SELECT_AIRCRAFT'

export const SELECT_YEAR = 'SELECT_YEAR'
export const SELECT_USER = 'SELECT_USER'
export const SELECT_ACCOUNT = 'SELECT_ACCOUNT'
export const SELECT_ACTIVITY = 'SELECT_ACTIVITY'

export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS'
export const TOGGLE_MENU_DRAWER = 'TOGGLE_MENU_DRAWER'
export const TOGGLE_ADMIN_MODE = 'TOGGLE_ADMIN_MODE'
export const AIRCRAFT_UPDATE_SUCCESS = 'AIRCRAFT_UPDATE_SUCCESS'

export const PAGINATION_RUNNING = 'PAGINATION_RUNNING'
export const PAGINATION_SUCCESS = 'PAGINATION_SUCCESS'
export const PAGINATION_ERROR = 'PAGINATION_ERROR'

export const TRANSFER_SUCCESS = 'TRANSFER_SUCCESS'
export const TRANSFER_ERROR = 'TRANSFER_ERROR'

export const CREATE_PROGRESS_BOOK_SUCCESS = 'CREATE_PROGRESS_BOOK_SUCCESS'
export const CREATE_PROGRESS_BOOK_ERROR = 'CREATE_PROGRESS_BOOK_ERROR'
export const SELECT_LESSON = 'SELECT_LESSON'
