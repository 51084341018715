import React, { useMemo } from 'react'
import TabbedPage from '../_library/TabbedPage'
import { PB_ITEM_COLLECTION, PB_TEMPLATE_COLLECTION, PROGRESS_BOOK_COLLECTION } from '../../_constants/globals'
import { listenCollection } from '../../store/actions/firestoreActions'
import { compact } from 'lodash'
import useListener from '../../hooks/useListener'
import ProgressBookDetails from './ProgressBookDetails'
import ProgressBooks from './ProgressBooks'


const ProgressBookPage = () => {
  
  useListener(() => listenCollection({
    collection: PROGRESS_BOOK_COLLECTION,
    orderBy: [['_updatedAt', 'desc']],
  }))
  useListener(() => listenCollection({
    collection: PB_ITEM_COLLECTION,
    orderBy: [['order', 'asc']],
    storeAs: 'items',
  }))
  useListener(() => listenCollection({
    collection: PB_TEMPLATE_COLLECTION,
    orderBy: [['order', 'asc']],
    storeAs: 'templates',
  }))
  
  const tabs = useMemo(() => compact([
    {
      key: 'all',
      label: 'Tous les livrets',
      route: 'all',
      content: <ProgressBooks />,
    },
    {
      key: 'view',
      label: '',
      route: '/:id',
      content: <ProgressBookDetails />,
    },
  ]), [])

  return <TabbedPage tabs={tabs} />
}

export default ProgressBookPage
