import React  from 'react'
import { Card, CardContent, Typography, Divider, CardHeader, Button, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AVAILABILITY_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { addDocAction, listenCollection } from '../../store/actions/firestoreActions'
import Loading from '../_library/Loading'
import { format, startOfToday } from 'date-fns'
import AvailabilityItem from './AvailabilityItem'
import useListener from '../../hooks/useListener'
import CTAButton from '../_library/CTAButton'


const InstructorPanel = () => {
  
  const dispatch = useDispatch()
  const profile = useSelector(state => state.auth.profile)
  const availabilities = useSelector(state => state.firestore.availabilities)
  useListener(() => listenCollection({
    collection: AVAILABILITY_COLLECTION,
    where: [
      ['day', '>=', startOfToday()],
      ['userRef', '==', USER_COLLECTION, profile.id],
    ],
    orderBy: [['day', 'asc']],
  }), [profile.id], !!profile)
  
  const createAvailability = () =>
    dispatch(addDocAction(AVAILABILITY_COLLECTION, {
      day: startOfToday(),
      start: new Date(format(startOfToday(), 'yyyy-MM-dd') + 'T09:00'),
      end: new Date(format(startOfToday(), 'yyyy-MM-dd') + 'T18:00'),
      userRef: [USER_COLLECTION, profile.id],
      user: {
        id: profile.id,
        displayName: profile.firstname + ' ' + profile.lastname,
      },
    }))

  const handleSync = () => {
    console.log('handleSync')
  }
  
  if (!availabilities) return <Loading />
  return (
    <Box sx={{ my: 2, mx: 'auto', maxWidth: '900px' }}>
      <Typography variant='body2' color='textSecondary' gutterBottom mb={1}>Disponibilités à venir</Typography>
      <Card variant='outlined' sx={{ minWidth: '275px', mb: 2, bgcolor: 'background.card' }}>
        <CardContent sx={{ px: 0 }}>
          <CardHeader
            action={
              <Button variant='contained' color='primary' onClick={createAvailability}>
                Ajouter une dispo
              </Button>
            }
          />
          {availabilities.length === 0
            ? <Typography variant='body1' style={{ textAlign: 'center', fontStyle: 'italic' }}>Aucune dispo</Typography>
            : availabilities.map(availability =>
              <AvailabilityItem item={availability} key={availability.id} />,
            )
          }
        </CardContent>
      </Card>
      <Divider light />
      <CTAButton onClick={handleSync} fullWidth sx={{ mt: 2 }}>Google Calendar Sync</CTAButton>
    </Box>
  )
}

export default InstructorPanel
