import React, { useState } from 'react'
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import { Close } from '@mui/icons-material'
import { deleteDocAction, updateDocAction } from '../../store/actions/firestoreActions'
import { SLOT_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '../_library/Avatar'


const Slot = ({ id, user, disabled }) => {
  
  const { users } = useSelector(state => state.data.selectedActivity || {})
  const dispatch = useDispatch()
  
  const [anchorEl, setAnchorEl] = useState(null)
  
  const closeMenu = () => setAnchorEl(null)
  
  const handleDelete = () => dispatch(deleteDocAction(SLOT_COLLECTION, id))
  const handleBook = userId => dispatch(updateDocAction(SLOT_COLLECTION, id, { userRef: [USER_COLLECTION, userId] }))
  
  return (<>
    <Box sx={{ bgcolor: 'grey.500', display: 'inline-block', p: '2px' }}>
      <IconButton
        onClick={e => !disabled && setAnchorEl(e.currentTarget)}
        title={user ? undefined :'Créneau libre'}
        disableRipple={disabled}
        sx={{
          p: .5,
          width: '32px',
          height: '32px',
          bgcolor: 'white',
          cursor: disabled && 'default',
          '&:hover': {
            bgcolor: 'white',
          },
          '&:disabled': {
            bgcolor: 'white',
          },
        }}
      >{user && <Avatar user={user} tooltip={`${user.firstname} ${user.lastname || ''}`} />}</IconButton>
    </Box>
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={closeMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {user
        ? <MenuItem onClick={() => {handleBook(null); closeMenu()}}>
          <ListItemIcon><Close /></ListItemIcon>
          <ListItemText>Libérer</ListItemText>
        </MenuItem>
        : <MenuItem onClick={() => {handleDelete(); closeMenu()}}>
          <ListItemIcon><Close /></ListItemIcon>
          <ListItemText>Supprimer</ListItemText>
        </MenuItem>
      }
      {users?.map(u => <MenuItem key={u.id} onClick={() => {handleBook(u.id); closeMenu()}}>
        <ListItemIcon sx={{ mr: 1 }}><Avatar user={u} /></ListItemIcon>
        <ListItemText>{u.firstname} {u.lastname}</ListItemText>
      </MenuItem>)}
    </Menu>
  </>)
}

Slot.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object,
  disabled: PropTypes.bool,
}

export default Slot
