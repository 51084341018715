import { CREATE_PROGRESS_BOOK_ERROR, CREATE_PROGRESS_BOOK_SUCCESS } from '../types'
import { PROGRESS_BOOK_COLLECTION, USER_COLLECTION, PB_LESSON_COLLECTION, FLIGHT_COLLECTION } from '../../_constants/globals'
import { collection, doc, addDoc, serverTimestamp, updateDoc, deleteDoc, deleteField } from 'firebase/firestore'


/**
 * Creates a new progress book for a given user
 *
 * @param {string} name
 * @param {string} level
 * @param {string} pilotId
 */
export const createProgressBookAction = (name, level, pilotId) => (dispatch, getState, { db }) =>
  addDoc(collection(db, PROGRESS_BOOK_COLLECTION), {
    status: 'open',
    _createdAt: serverTimestamp(),
    _updatedAt: serverTimestamp(),
    startDate: serverTimestamp(),
    pilotRef: doc(db, USER_COLLECTION, pilotId || getState().auth.profile.id),
    name,
    level,
    completeness: {
      total: getState().firestore.items.filter(i => i.level === level).length,
      validatedItems: 0,
    },
  })
    .then(() => dispatch({ type: CREATE_PROGRESS_BOOK_SUCCESS }))
    .catch(err => {
      console.error(err)
      dispatch({ type: CREATE_PROGRESS_BOOK_ERROR })
    })

/**
 * Updates an existing progressBook
 *
 * @param {string} id
 * @param {object} book
 * @returns {function(*, *, {getFirestore: *}): *}
 */
export const updateProgressBookAction = (id, book) => (dispatch, getState, { db }) =>
  updateDoc(doc(db, PROGRESS_BOOK_COLLECTION, id), {
    ...book,
    _updatedAt: serverTimestamp(),
  })

/**
 * Deletes a given progress book
 *
 * @param id
 * @returns {function(*, *, {getFirestore: *}): *}
 */
export const deleteProgressBookAction = id => (dispatch, getState, { db }) =>
  deleteDoc(doc(db, PROGRESS_BOOK_COLLECTION, id))

export const createLessonAction = lesson => (dispatch, getState, { db }) => {
  if (lesson.progressBookId){
    lesson.progressBookRef = doc(db, PROGRESS_BOOK_COLLECTION, lesson.progressBookId)
    delete lesson.progressBookId
  }
  if (lesson.flightId){
    lesson.flightRef = doc(db, FLIGHT_COLLECTION, lesson.flightId)
    delete lesson.flightId
  }
  return addDoc(collection(db, PB_LESSON_COLLECTION), {
    ...lesson,
    _createdAt: serverTimestamp(),
  })
}

export const updateLessonAction = (id, lesson) => (dispatch, getState, { db }) => {
  if (lesson.flightId) {
    lesson.flightRef = doc(db, FLIGHT_COLLECTION, lesson.flightId)
    delete lesson.flightId
  }
  if (lesson.theoreticalItems || lesson.practicalItems) lesson.selectedItemIds = deleteField()
  return updateDoc(doc(db, PB_LESSON_COLLECTION, id), {
    ...lesson,
    _updatedAt: serverTimestamp(),
  })
}

export const deleteLessonAction = id => (dispatch, getState, { db }) =>
  deleteDoc(doc(db, PB_LESSON_COLLECTION, id))
