import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Card, Button, CardActions, CardContent, Skeleton } from '@mui/material'
import { Star } from '@mui/icons-material'
import { QUALIFICATIONS } from '../../_constants/routes'
import EditQualificationDialog from '../qualifications/EditQualificationDialog'
import { chain } from 'lodash'
import { getQualificationStatus } from '../../_helpers/qualificationHelper'
import QualificationIcon from '../_library/QualificationIcon'
import { listenCollection } from '../../store/actions/firestoreActions'
import { QUALIFICATION_COLLECTION, STATUS_ACTIVATED } from '../../_constants/globals'
import { useNavigate } from 'react-router'
import useListener from '../../hooks/useListener'
import { useSelector } from 'react-redux'


export const AdminQualificationCard = ({ sx }) => {
  
  const navigate = useNavigate()
  const qualifications = useSelector(state => state.firestore.qualifications)
  useListener(() => listenCollection({
    collection: QUALIFICATION_COLLECTION,
    where: [['archived', '==', false], ['user.status', '==', STATUS_ACTIVATED]],
  }), [])
  
  const [editQualification, setEditQualification] = useState(null)
  
  const qualificationStatuses = useMemo(() => qualifications && chain(qualifications)
    .groupBy(getQualificationStatus)
    .mapValues(arr => arr.length)
    .toPairs()
    .value(), [qualifications])
  
  if (!qualifications) return <Skeleton height={460} style={{ transform: 'none' }} />
  return (
    <Card sx={{ minWidth: 275, mb: 4, p: 1, position: 'relative', ...sx }}>
      <CardContent>
        <Typography variant='subtitle1' onClick={() => navigate(QUALIFICATIONS)} sx={{
          mb: 2,
          '&:hover': {
            color: 'primary.main',
            cursor: 'pointer',
          },
        }}><Star style={{ verticalAlign: 'sub' }} />&nbsp;Validités</Typography>
        <div>
          {qualificationStatuses.map(([status, number]) => number &&
            <Button
              key={status}
              fullWidth
              startIcon={<QualificationIcon qualificationStatus={status} />}
              onClick={() => navigate(QUALIFICATIONS + `/all?filter=${status}`)}
              sx={{
                justifyContent: 'flex-start',
                color: 'initial',
                textTransform: 'none',
                fontSize: '1rem',
                py: .5,
              }}
            ><strong>{number}</strong>&nbsp;({status})</Button>,
          )}
        </div>
      </CardContent>
      <CardActions sx={{ position: 'absolute', bottom: theme => theme.spacing(.5) }}>
        <Button color='primary' onClick={() => setEditQualification({})}>
          Nouvelle validité
        </Button>
      </CardActions>
      {editQualification && <EditQualificationDialog onClose={() => setEditQualification(null)} qualification={editQualification} />}
    </Card>
  )
}

AdminQualificationCard.propTypes = {
  sx: PropTypes.object,
}

export default AdminQualificationCard
