import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Card, CardContent, Typography, FormControl, Select, MenuItem, Grid } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import InlineEditor from '../_library/InlineEditor'
import TransferList from '../_library/TransferList'
import { updateDocAction } from '../../store/actions/firestoreActions'
import { USER_COLLECTION } from '../../_constants/globals'


const styles = () => ({
  root: {
    margin: '2rem auto',
    maxWidth: 900,
  },
  cardTitle: {
    marginBottom: '1rem',
  },
  card: {
    minWidth: 275,
    marginBottom: '2rem',
  },
  formField: {
    marginBottom: '1rem',
  },
  label: {
    color: 'rgba(0,0,0,.54)',
    display: 'inline-block',
    lineHeight: '20px',
    width: 200,
  },
})

const PilotPanel = ({ classes, profile, updateDocAction, aircrafts, flightTypes }) =>
  <div className={classes.root}>
    <Typography variant='body2' className={classes.cardTitle} color='textSecondary' gutterBottom>Expérience</Typography>
    <Card className={classes.card} variant='outlined'>
      <CardContent>
        <Grid item xs={12} className={classes.formField}>
          <InlineEditor
            title='Heures de vol'
            value={profile.experience?.total}
            handleSubmit={val => updateDocAction(USER_COLLECTION, profile.id, { experience: { ...profile.experience, total: val } })}
          />
        </Grid>
        <Grid item xs={12} className={classes.formField}>
          <InlineEditor
            title='Heures de voltige'
            value={profile.experience?.aerobatics}
            handleSubmit={val => updateDocAction(USER_COLLECTION, profile.id, { experience: { ...profile.experience, aerobatics: val } })}
          />
        </Grid>
      </CardContent>
    </Card>

    <Typography variant='body2' className={classes.cardTitle} color='textSecondary' gutterBottom>Préférences</Typography>
    <Card className={classes.card} variant='outlined'>
      <CardContent>
        <Grid item xs={12} className={classes.formField}>
          <Typography className={classes.label} color='textSecondary' gutterBottom>
            Avion favori
          </Typography>
          <FormControl variant='outlined'>
            <Select
              variant='standard'
              id='aircraft-select'
              value={profile.favoritAircraftId}
              onChange={e => updateDocAction(USER_COLLECTION, profile.id, { favoritAircraftId: e.target.value })}
              margin='dense'>
              {aircrafts && aircrafts.map(aircraft => <MenuItem key={aircraft.id} value={aircraft.id}>{aircraft.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        {flightTypes &&
        <Grid item xs={12} className={classes.formField}>
          <Typography className={classes.label} color='textSecondary' gutterBottom>
            Types de vol
          </Typography>
          <TransferList
            leftInit={flightTypes && flightTypes
              .filter(type => !profile.flightTypes.some(slug => slug === type.slug))
              .map(t => t.name)}
            rightInit={flightTypes && flightTypes
              .filter(type => profile.flightTypes.some(slug => slug === type.slug))
              .map(t => t.name)}
            handleSelect={types => updateDocAction(USER_COLLECTION, profile.id, { flightTypes: types.map(name => flightTypes.find(t => t.name === name).slug) })}
          />
        </Grid>
        }
      </CardContent>
    </Card>
  </div>

const mapStateToProps = state => ({
  profile: state.auth.profile,
  aircrafts: state.firestore.aircrafts,
  flightTypes: state.firestore.flightTypes,
})

PilotPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  updateDocAction: PropTypes.func.isRequired,
  aircrafts: PropTypes.array,
  flightTypes: PropTypes.array,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { updateDocAction }),
)(PilotPanel)
