import {
  SELECT_AIRCRAFT, SELECT_YEAR, SELECT_USER, TOGGLE_MENU_DRAWER, SELECT_LESSON,
  SELECT_ACCOUNT, TOGGLE_ADMIN_MODE, SELECT_ACTIVITY,
} from '../types'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'


export const selectAircraft = aircraft => dispatch => new Promise(resolve => {
  dispatch({
    type: SELECT_AIRCRAFT,
    payload: aircraft,
  })
  resolve()
})

export const selectYear = year => dispatch =>
  dispatch({
    type: SELECT_YEAR,
    payload: year,
  })

export const selectUser = user => (dispatch, getState) =>
  dispatch({
    type: SELECT_USER,
    payload: user || getState().auth.profile,
  })

export const selectAccount = account => dispatch =>
  dispatch({
    type: SELECT_ACCOUNT,
    payload: account,
  })

export const selectLesson = lesson => dispatch =>
  dispatch({
    type: SELECT_LESSON,
    payload: lesson,
  })

export const setMenuDrawerOpen = open => dispatch =>
  dispatch({
    type: TOGGLE_MENU_DRAWER,
    payload: open,
  })

export const setAdminModeAction = val => dispatch =>
  dispatch({
    type: TOGGLE_ADMIN_MODE,
    payload: val,
  })

export const selectActivityAction = activity => dispatch =>
  dispatch({
    type: SELECT_ACTIVITY,
    payload: activity,
  })

export const uploadFileAction = (folder, name, file) => (dispatch, getState, { storage }) => new Promise(resolve => {
  const extension = file.name?.split('.').pop() || file.type.split('/').pop()
  const filename = name
    ? `${name}-${(new Date()).getMilliseconds()}`
    : `${(new Date()).getMilliseconds()}`
  uploadBytes(ref(storage, `${folder}/${filename}.${extension}`), file)
    .then(snapshot => getDownloadURL(ref(storage, snapshot.ref.fullPath)))
    .then(resolve)
})
