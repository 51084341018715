import React, { useEffect } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { selectAircraft } from '../../store/actions/dataActions'


const AircraftSelector = () => {
  
  const profile = useSelector(state => state.auth.profile)
  const aircrafts = useSelector(state => state.firestore.aircrafts?.filter(a => a.activated || state.data.adminMode))
  const selectedAircraft = useSelector(state => state.data.selectedAircraft)
  const dispatch = useDispatch()
  
  useEffect(() => {
    if (!selectedAircraft && profile.favoritAircraftId && aircrafts?.length)
      dispatch(selectAircraft(aircrafts.find(a => a.id === profile.favoritAircraftId) || aircrafts[0]))
  }, [selectedAircraft, profile.favoritAircraftId, aircrafts, selectAircraft])

  return (
    <FormControl variant='standard' sx={{ m: 1, verticalAlign: 'baseline' }}>
      <InputLabel id='aircraft-select-label' sx={{ color: 'white' }}>Avion</InputLabel>
      <Select
        labelId='aircraft-select-label'
        id='aircraft-select'
        value={selectedAircraft ? selectedAircraft.id : ''}
        onChange={e => dispatch(selectAircraft(aircrafts.find(a => a.id === e.target.value)))}
        label='Avion'
        sx={{ minWidth: '120px', borderColor: 'secondary.main' }}
      >
        {aircrafts && aircrafts.map(aircraft => <MenuItem key={aircraft.id} value={aircraft.id}>{aircraft.name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default AircraftSelector
