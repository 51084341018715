import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, Box, Typography, Badge, Divider, IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '../_library/Avatar'
import { updateDocAction } from '../../store/actions/firestoreActions'
import { ACTIVITY_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { deleteField } from 'firebase/firestore'
import HorizPlanning from './HorizPlanning'
import { addDays, isPast, format } from 'date-fns'
import { Star } from '@mui/icons-material'
import { selectUser } from '../../store/actions/dataActions'
import { QUALIFICATIONS } from '../../_constants/routes'
import { useNavigate } from 'react-router'


const Contact = ({ userId, role }) => {
  const { pilotRefs, instructorRefs, id: activityId, organizerRef, vdRefs, megRefs } = useSelector(state => state.data.selectedActivity)
  const user = useSelector(state => state.firestore.users?.find(a => a.id === userId) || {})
  const { photoUrl, firstname, lastname, phone, airworthiness } = user
  const dispatch = useDispatch()
  const qualificationTypes = useSelector(state => state.firestore.qualificationTypes?.filter(t => t.required))
  const qualifications = useSelector(state => state.firestore.qualifications?.filter(q => q.userRef.id === userId))
  const profile = useSelector(state => state.auth.profile)
  const adminMode = useSelector(state => state.data.adminMode)
  const navigate = useNavigate()
  
  const [modalOpen, setModalOpen] = useState(false)
  
  const handleRemove = () => {
    console.log('role', role)
    let data
    if (role === 'Organisateur')
      data = { organizerRef: deleteField() }
    else if (role === 'Instructeur')
      data = { instructorRefs: [USER_COLLECTION, instructorRefs.map(u => u.id).filter(id => id !== userId), 'remove'] }
    else if (role === 'Pilote')
      data = { pilotRefs: [USER_COLLECTION, pilotRefs.map(u => u.id).filter(id => id !== userId), 'remove'] }
    else if (role === 'VD')
      data = { vdRefs: [USER_COLLECTION, vdRefs.map(u => u.id).filter(id => id !== userId), 'remove'] }
    else if (role === 'MEG')
      data = { megRefs: [USER_COLLECTION, megRefs.map(u => u.id).filter(id => id !== userId), 'remove'] }
    return dispatch(updateDocAction(ACTIVITY_COLLECTION, activityId, data))
      .then(() => setModalOpen(false))
  }
  
  const types = useMemo(() => qualifications && qualificationTypes?.map(t => {
    const q = qualifications.find(q => q.typeRef.id === t.id)
    if (!q) return ({ ...t, status: 'error', message: 'Validité manquante' })
    else if (t.fields.includes('expireDate') && (!q.expireDate || isPast(q.expireDate.toDate()))) return ({ ...t, status: 'error', message: 'La validité est expirée' })
    else if (t.fields.includes('attachmentUrl') && !q.attachmentUrl) return ({ ...t, status: 'error', message: 'Il manque la photo du document' })
    else if (!q.checkerRef) return ({ ...t, status: 'warning', message: 'La validité n\'a pas été vérifiée' })
    else if (t.fields.includes('expireDate') && isPast(addDays(q.expireDate.toDate(), -60))) return ({ ...t, status: 'warning', message: 'La validité expire le ' + format(q.expireDate.toDate(), 'dd-MM-yyyy') })
    else return ({ ...t, status: 'success', message: 'OK' })
  }), [qualificationTypes, qualifications])
  
  return (<>
    <Button fullWidth onClick={() => setModalOpen(true)} sx={{ textAlign: 'left', display: 'block', textTransform: 'none', color: 'initial' }}>
      {(adminMode || organizerRef?.id === profile.id)
        ? <Badge color={airworthiness || 'info'} variant='dot' sx={{
          mr: 1,
          float: 'left',
          mt: .5,
          '& .MuiBadge-badge': { right: 8, top: 30, height: 16, width: 16, borderRadius: 8, border: 'solid white 2px' },
        }}>
          <Avatar user={user} />
        </Badge>
        : <Avatar user={user} sx={{ mr: 1, float: 'left', mt: .5 }} />
      }
      <Typography>{firstname} {lastname}</Typography>
      <Typography variant='caption' sx={{ color: 'grey.500' }}>{role}</Typography>
    </Button>
    {modalOpen && <Dialog open onClose={() => setModalOpen(false)}>
      <DialogContent>
        <Box component='img' src={photoUrl} alt='avatar' sx={{ maxWidth: '100%' }} />
        <Box sx={{ display: 'flex', my: 1 }}>
          <Typography mr={2}>{firstname} {lastname}</Typography>
          <a href={'tel:' + phone}>{phone}</a>
        </Box>
        {(adminMode || organizerRef?.id === profile.id) && <>
          <Divider sx={{ my: 1 }}/>
          <HorizPlanning userId={userId} activityId={activityId} />
          <Divider sx={{ my: 1 }}/>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => {dispatch(selectUser(user)); navigate(QUALIFICATIONS + '/user')}}><Star /></IconButton>
            <Typography>Airworthiness</Typography>
          </Box>
          <table>
            <tbody>
              {types?.map(type => <tr key={type.id}>
                <td><Box sx={{ width: 20, height: 20, borderRadius: 10, bgcolor: type.status + '.main' }}/></td>
                <td>{type.name}</td>
                <td>{type.message}</td>
              </tr>)}
            </tbody>
          </table>
        </>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRemove} color='secondary'>Supprimer</Button>
        <Button onClick={() => setModalOpen(false)}>Fermer</Button>
      </DialogActions>
    </Dialog>}
  </>)
}

Contact.propTypes = {
  userId: PropTypes.string,
  role: PropTypes.string,
}

export default Contact
