import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, DialogActions, DialogContent, Grid, DialogTitle, Dialog, CircularProgress, Autocomplete } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { createTransferAction } from '../../store/actions/accountActions'
import { omit } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers'


export const ApproDialog = ({ open, onClose }) => {
  
  const accounts = useSelector(state => state.firestore.accounts)
  const selectedUser = useSelector(state => state.data.selectedUser)
  const selectedAccount = useSelector(state => state.data.selectedAccount)
  const dispatch = useDispatch()
  
  const [debit, setDebit] = useState(null)
  const [credit, setCredit] = useState(null)
  const [amount, setAmount] = useState(0)
  const [comment, setComment] = useState('Virement compte pilote')
  const [accountDate, setAccountDate] = useState(new Date())
  
  const valid = useMemo(() => credit && amount, [credit, amount])
  
  useEffect(() => {
    if (accounts) setDebit(accounts.find(el => el.name === 'Compte courant'))
  }, [accounts])
  
  useEffect(() => {
    if (accounts && selectedAccount) setCredit(omit(accounts.find(el => el.id === selectedAccount.id), ['ownerRef']))
    else if (accounts && selectedUser) setCredit(omit(accounts.find(el => el.id === selectedUser.accountRef.id), ['ownerRef']))
  }, [accounts, selectedUser, selectedAccount])
  
  const handleSubmit = e => {
    e.preventDefault()
    return dispatch(createTransferAction({ debit, credit, amount, comment, accountDate })).then(onClose)
  }
  
  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle id='form-dialog-title'>Approvisionner un compte pilote</DialogTitle>
        <DialogContent>
          {!accounts
            ? <CircularProgress color='inherit' />
            : <Grid container spacing={3}>
              <Grid item container xs={12} spacing={2} style={{ alignItems: 'center' }}>
                <Grid item xs={6}>
                  <Autocomplete
                    id='debit-select'
                    options={accounts}
                    getOptionLabel={option => option.name}
                    renderInput={params =>
                      <TextField variant='standard' {...params} label='Compte à débiter' />
                    }
                    value={debit || null}
                    onChange={(event, newValue) => setDebit(newValue)}
                    isOptionEqualToValue={option => accounts.find(el => el.id === option.id)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant='standard'
                    margin='dense'
                    id='amount'
                    label='Montant'
                    type='number'
                    value={amount}
                    onChange={e => {
                      e.persist()
                      setAmount(parseFloat(e.target.value))
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id='credit-select'
                  options={accounts}
                  getOptionLabel={option => option.name}
                  renderInput={params =>
                    <TextField variant='standard' {...params} label='Compte à créditer' fullWidth />
                  }
                  value={credit}
                  onChange={(event, newValue) => setCredit(newValue)}
                  isOptionEqualToValue={option => accounts.find(el => el.id === option.id)}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  disableToolbar
                  variant='inline'
                  inputFormat='dd-MM-yyyy'
                  margin='dense'
                  id='startDate-date'
                  label='Date du transfer'
                  value={accountDate}
                  onChange={setAccountDate}
                  renderInput={params => <TextField {...params} />}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant='standard'
                  margin='dense'
                  id='comment'
                  label='Commentaire'
                  fullWidth
                  value={comment || ''}
                  multiline
                  onChange={e => setComment(e.target.value)} />
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} >Annuler</Button>
          <Button type='submit' color='primary' disabled={!valid}>Valider</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ApproDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ApproDialog
