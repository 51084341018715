import { TRANSFER_SUCCESS, TRANSFER_ERROR } from '../types'
import { ACCOUNT_COLLECTION, ACCOUNT_ENTRY_COLLECTION, ACCOUNT_FLUX_COLLECTION } from '../../_constants/globals'
import { collection, doc, serverTimestamp, writeBatch, getDoc } from 'firebase/firestore'


/**
 * Gets one entry from DB
 * This function is used to retrieve data when selected entry id is read from url params
 */
export const fetchAccountEntryAction = id => (dispatch, getState, { db }) =>
  getDoc(doc(db, ACCOUNT_ENTRY_COLLECTION, id))
    .then(doc => ({ id: doc.id, ...doc.data() }))

export const createTransferAction = ({ debit, credit, amount, comment, accountDate }) => (dispatch, getState, { db }) => {
  console.log('transfer', { debit, credit, amount, comment, accountDate })
  const batch = writeBatch(db)
  batch.set(doc(collection(db, ACCOUNT_ENTRY_COLLECTION)), {
    accountDate,
    accountRef: doc(db, ACCOUNT_COLLECTION, debit.id),
    comment,
    debit: amount,
    paymentType: 'transfer',
    _createdAt: serverTimestamp(),
  })
  batch.set(doc(collection(db, ACCOUNT_ENTRY_COLLECTION)), {
    accountDate,
    accountRef: doc(db, ACCOUNT_COLLECTION, credit.id),
    comment,
    credit: amount,
    paymentType: 'transfer',
    _createdAt: serverTimestamp(),
  })
  return batch.commit()
    .then(() => dispatch({
      type: TRANSFER_SUCCESS,
    }))
    .catch(err => dispatch({
      type: TRANSFER_ERROR,
      payload: err,
    }))
}

export const createFluxAction = ({ debits, credits, comment, accountDate }) => (dispatch, getState, { db }) => {
  console.log('createFluxAction', { debits, credits, comment, accountDate })
  const batch = writeBatch(db)
  const fluxRef = doc(collection(db, ACCOUNT_FLUX_COLLECTION))
  batch.set(fluxRef, {
    accountDate,
    comment,
    _createdAt: serverTimestamp(),
    balanced: debits.reduce((acc, val) => acc + val.amount, 0) === credits.reduce((acc, val) => acc + val.amount, 0),
  })
  debits.map(debit =>
    batch.set(doc(collection(db, ACCOUNT_ENTRY_COLLECTION)), {
      accountDate,
      accountRef: doc(db, ACCOUNT_COLLECTION, debit.account.id),
      comment,
      debit: debit.amount,
      paymentType: 'flux',
      _createdAt: serverTimestamp(),
      fluxRef,
    }),
  )
  credits.map(credit =>
    batch.set(doc(collection(db, ACCOUNT_ENTRY_COLLECTION)), {
      accountDate,
      accountRef: doc(db, ACCOUNT_COLLECTION, credit.account.id),
      comment,
      credit: credit.amount,
      paymentType: 'flux',
      _createdAt: serverTimestamp(),
      fluxRef,
    }),
  )
  return batch.commit()
}
