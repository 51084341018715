import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Select, MenuItem, useTheme, useMediaQuery, Box } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { addMonths, format } from 'date-fns'
import { deleteFlightAction, updateFlightAction } from '../../store/actions/flightActions'
import DeleteButton from '../_library/DeleteButton'
import { Check, Clear, EditSharp } from '@mui/icons-material'
import Loading from '../_library/Loading'
import EditFlightDialog from './EditFlightDialog'
import { pick } from 'lodash'
import { listenFlightsAction } from '../../store/actions/firestoreActions'
import useListener from '../../hooks/useListener'


const STORAGE_KEY = 'flightLogTableState'
const FlightLogTable = ({ className }) => {
  
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const adminMode = useSelector(state => state.data.adminMode)
  const flights = useSelector(state => state.firestore.flights?.filter(f => f.aircraftRef.id === state.data.selectedAircraft?.id))
  const selectedAircraft = useSelector(state => state.data.selectedAircraft)
  const dispatch = useDispatch()
  
  const [year, setYear] = useState((new Date()).getFullYear())
  const [month, setMonth] = useState((new Date()).getMonth())
  const [selectedFlight, selectFlight] = useState(null)
  
  useListener(() => listenFlightsAction(year), [year])
  
  const years = useMemo(() => [...Array((new Date()).getFullYear() - 2017 + 1).keys()].map(y => y + 2017), [])
  const months = useMemo(() => [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ], [])
  
  const data = useMemo(() =>
    flights?.filter(f => f.startDate.toDate() > new Date(year, month) && f.startDate.toDate() < addMonths(new Date(year, month), 1)).map(val => {
      const {
        startDate, pilot, instructor, flightType, departure, arrival, fuelBefore, fuelAfter, landings,
        counterDeparture, counterArrival, duration, comments, id, validated,
      } = val
      return [
        flightType ? format(startDate.toDate(), 'dd-MM-yyyy HH:mm') : '',
        pilot && (pilot.firstname + ' ' + pilot.lastname),
        instructor ? (instructor.firstname + ' ' + instructor.lastname) : '',
        flightType && flightType.name,
        duration,
        departure && departure.ICAO,
        arrival && arrival.ICAO,
        fuelBefore,
        fuelAfter,
        landings,
        counterDeparture,
        counterArrival,
        comments,
        { id, validated },
      ]
    }), [flights, year, month])
  
  const localState = window.localStorage.getItem(STORAGE_KEY) && JSON.parse(window.localStorage.getItem(STORAGE_KEY))
  
  if (!flights || !selectedAircraft) return <Loading />
  return (
    <Box className={className} sx={{ m: { sm: 2 } }}>
      <Select
        variant='standard'
        id='year'
        value={year}
        onChange={e => setYear(e.target.value)}
        sx={{ ml: 2, mb: { sm: 1 } }}
      >
        {years.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
      </Select>
      <Select
        variant='standard'
        id='month'
        value={month}
        onChange={e => setMonth(e.target.value)}
        sx={{ ml: 2, mb: { sm: 1 } }}
      >
        {months.map((month, key) => <MenuItem key={key} value={key}>{month}</MenuItem>)}
      </Select>
      <MUIDataTable
        data={data}
        columns={[
          {
            name: 'Date et heure',
            options: {
              display: localState?.columns[0].display || 'true',
              filter: true,
              filterList: localState?.filterList[0] || [],
              sortDescFirst: true,
              sortThirdClickReset: true,
            },
          },
          {
            name: 'Pilote',
            options: {
              display: localState?.columns[1].display || 'true',
              filter: true,
              filterList: localState?.filterList[1] || [],
              sortThirdClickReset: true,
            },
          },
          {
            name: 'Instructeur',
            options: {
              display: localState?.columns[2].display || 'true',
              filter: true,
              filterList: localState?.filterList[2] || [],
              sortThirdClickReset: true,
            },
          },
          {
            name: 'Type de vol',
            options: {
              display: localState?.columns[3].display || 'true',
              filter: true,
              filterList: localState?.filterList[3] || [],
              sortThirdClickReset: true,
            },
          },
          {
            name: 'Durée',
            options: {
              display: localState?.columns[4].display || 'true',
              filter: true,
              filterList: localState?.filterList[4] || [],
              sortThirdClickReset: true,
            },
          },
          {
            name: 'Départ',
            options: {
              display: localState?.columns[5].display || 'true',
              filter: true,
              filterList: localState?.filterList[5] || [],
              sortThirdClickReset: true,
            },
          },
          {
            name: 'Arrivée',
            options: {
              display: localState?.columns[6].display || 'true',
              filter: true,
              filterList: localState?.filterList[6] || [],
              sortThirdClickReset: true,
            },
          },
          {
            name: 'Essence départ',
            options: {
              display: localState?.columns[7].display || 'false',
              filter: true,
              filterList: localState?.filterList[7] || [],
              sortThirdClickReset: true,
            },
          },
          {
            name: 'Essence arrivée',
            options: {
              display: localState?.columns[8].display || 'false',
              filter: true,
              filterList: localState?.filterList[8] || [],
              sortThirdClickReset: true,
            },
          },
          {
            name: 'Atterrissages',
            options: {
              display: localState?.columns[9].display || isUpMd,
              filter: true,
              filterList: localState?.filterList[9] || [],
              sortThirdClickReset: true,
            },
          },
          {
            name: 'Horamètre début',
            options: {
              display: localState?.columns[10].display || 'true',
              filter: true,
              filterList: localState?.filterList[10] || [],
              sortThirdClickReset: true,
              customBodyRenderLite: dataIndex => ( // eslint-disable-line react/display-name
                <span style={{
                  color: dataIndex > 0 && data[dataIndex][10] !== data[dataIndex - 1][11]
                    ? 'red'
                    : 'inherit',
                }}>{data[dataIndex][10]}</span>
              ),
            },
          },
          {
            name: 'Horamètre fin',
            options: {
              display: localState?.columns[11].display || 'true',
              filter: true,
              filterList: localState?.filterList[11] || [],
              sortThirdClickReset: true,
            },
          },
          {
            name: 'Commentaires',
            options: {
              display: localState?.columns[12].display || isUpMd,
              filter: true,
              filterList: localState?.filterList[12] || [],
              sortThirdClickReset: true,
            },
          },
          {
            name: 'Actions',
            options: {
              download: false,
              filter: false,
              sort: false,
              viewColumns: false,
              display: adminMode,
              // eslint-disable-next-line
              customBodyRender: ({ id, validated }) => adminMode && <>
                <Button onClick={() => selectFlight(id)}><EditSharp /></Button>
                <DeleteButton deleteAction={() => dispatch(deleteFlightAction(id))} />
                <Button onClick={() => dispatch(updateFlightAction({ id, validated: !validated }))} title='valider le vol'>
                  {validated ? <Clear /> : <Check />}
                </Button>
              </>,
            },
          },
        ]}
        options={{
          selectableRows: 'none',
          search: false,
          onTableChange: (action, tableState) => {
            if (action === 'propsUpdate') return
            window.localStorage.setItem(STORAGE_KEY, JSON.stringify(pick(tableState, ['columns', 'filterList', 'sortOrder', 'rowsPerPage'])))
          },
          sortOrder: localState?.sortOrder || {},
          rowsPerPage: localState?.rowsPerPage || 10,
          jumpToPage: true,
          rowsPerPageOptions: [10, 50, 100],
          responsive: 'simple',
        }}
      />
      {selectedFlight !== null &&
        <EditFlightDialog flight={flights.find(f => f.id === selectedFlight)} onClose={() => selectFlight(null)} />
      }
    </Box>
  )
}

FlightLogTable.propTypes = {
  className: PropTypes.string,
}

export default FlightLogTable
