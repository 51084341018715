import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl,
  FormControlLabel, FormGroup, FormLabel, Grid, Link, MenuItem, Select, Typography, Collapse, Box,
  Modal, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import DeleteButton from '../_library/DeleteButton'
import InlineEditor from '../_library/InlineEditor'
import Avatar from '../_library/Avatar'
import { ROLE_ADMIN, ROLE_INSTRUCTOR, ROLE_PILOT, ROLE_SUPER_ADMIN, STATUS_ACTIVATED, STATUS_EXTERN, STATUS_FORMER, STATUS_HONOR,
  STATUS_VISITOR, USER_COLLECTION } from '../../_constants/globals'
import Loading from '../_library/Loading'
import { getGravatar } from '../../_helpers/dataHelper'
import QualificationIcon from '../_library/QualificationIcon'
import { selectAccount, selectUser } from '../../store/actions/dataActions'
import { ACCOUNT, QUALIFICATIONS } from '../../_constants/routes'
import { format } from 'date-fns'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { getQualificationStatus } from '../../_helpers/qualificationHelper'
import { deleteDocAction, getDocAction, listenAccountEntryAction, updateDocAction } from '../../store/actions/firestoreActions'
import { resetPasswordAction } from '../../store/actions/authActions'
import { DatePicker } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router'
import useListener from '../../hooks/useListener'


const labelStyle = {
  color: 'rgba(0, 0, 0, .54)',
  display: 'inline-block',
  lineHeight: '20px',
  width: 200,
}

const STATUSES = [STATUS_ACTIVATED, STATUS_EXTERN, STATUS_HONOR, STATUS_FORMER, STATUS_VISITOR]
const ROLES = [ROLE_PILOT, ROLE_INSTRUCTOR, ROLE_ADMIN]
const EditUserDialog = ({ user, onClose }) => {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const profile = useSelector(state => state.auth.profile)
  const billingTypes = useSelector(state => state.firestore.billingTypes)
  const qualifications = useSelector(state => state.firestore.qualifications?.filter(q => q.userRef.id === user.id))
  const accountEntries = useSelector(state => state.firestore.accountEntries || [])
  
  const [expandQualificationCard, setExpandQualificationCard] = useState(true)
  const [expandAccountCard, setExpandAccountCard] = useState(true)
  const [openAvatar, setOpenAvatar] = useState(false)
  const [account, setAccount] = useState(null)
  
  useListener(() => listenAccountEntryAction(account, { limit: 10 }), [account], !!account)
  
  useEffect(() => {
    dispatch(getDocAction(user.accountRef)).then(setAccount)
  }, [user.accountRef])
  
  if (!user || !account) return <Loading />
  return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title' sx={{ display: 'flex' }}>
        <Avatar user={user} onClick={() => setOpenAvatar(true)} />&nbsp;{user.firstname} {user.lastname}
      </DialogTitle>
      <DialogContent>
        <Typography variant='body2' color='textSecondary' gutterBottom>
          <Link
            onClick={() => {dispatch(selectUser(user)); navigate(QUALIFICATIONS + '/user')}}
            underline='hover'
          >Validités</Link>
          <Button color='primary' onClick={() => setExpandQualificationCard(prev => !prev)} sx={{ float: 'right' }}>
            {expandQualificationCard
              ? <ArrowDropUp />
              : <ArrowDropDown />
            }
          </Button>
        </Typography>
        <Card variant='outlined' sx={{ minWidth: '275px', mb: 3 }}>
          <CardContent>
            <Collapse in={expandQualificationCard} style={{ width: '100%' }}>
              {qualifications?.length
                ? <Box component='table' sx={{
                  '& td': {
                    py: 0,
                    px: 1,
                  },
                }}>
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Vérifiée par</th>
                      <th>Expire le</th>
                    </tr>
                  </thead>
                  <tbody>
                    {qualifications?.map(qualification =>
                      <tr key={qualification.id}>
                        <td><QualificationIcon qualificationStatus={getQualificationStatus(qualification)} /></td>
                        <td><b>{qualification.type.shortName}</b></td>
                        <td>{qualification.checker?.firstname}</td>
                        <td>{qualification.expireDate && format(qualification.expireDate.toDate(), 'dd-MM-yyyy')}</td>
                      </tr>,
                    )}
                  </tbody>
                </Box>
                : 'Aucune validité'
              }
            </Collapse>
          </CardContent>
        </Card>
        
        <Typography variant='body2' color='textSecondary' gutterBottom>
          <Link
            onClick={() => {dispatch(selectAccount(account)); navigate(ACCOUNT)}}
            underline='hover'
          >Compte pilote</Link> (extrait)
          <Button color='primary' onClick={() => setExpandAccountCard(prev => !prev)} sx={{ float: 'right' }}>
            {expandAccountCard
              ? <ArrowDropUp />
              : <ArrowDropDown />
            }
          </Button>
        </Typography>
        <Card variant='outlined' sx={{ minWidth: '275px', mb: 3 }}>
          <CardContent>
            <Collapse in={expandAccountCard} sx={{ width: '100%' }}>
              <Box component='table' sx={{
                fontSize: '.8rem',
                '& td': {
                  py: 0,
                  px: 1,
                },
              }}>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Débit</th>
                    <th>Crédit</th>
                  </tr>
                </thead>
                <tbody>
                  {[...accountEntries].reverse().map((entry, index) =>
                    <Box component='tr' key={entry.id} sx={{ bgcolor: index % 2 === 0 && 'grey.50' }}>
                      <td>{entry.accountDate && format(entry.accountDate.toDate(), 'dd-MM-yyyy')}</td>
                      <td>{entry.paymentType}</td>
                      <td>{entry.comment}</td>
                      <td>{entry.balance < 0 ? entry.balance : entry.debit || ''}</td>
                      <td>{entry.balance >= 0 ? entry.balance : entry.credit || ''}</td>
                    </Box>,
                  )}
                  <Box component='tr' sx={{ bgcolor: accountEntries.length % 2 === 0 && 'grey.50' }}>
                    <td></td>
                    <td></td>
                    <td><b>Total</b></td>
                    <Box component='td' sx={{ color: account?.balance > 0 ? 'success.main' : 'error.main' }}><b>{account?.balance.toFixed(2)}</b></Box>
                    <td></td>
                  </Box>
                </tbody>
              </Box>
            </Collapse>
          </CardContent>
        </Card>
        
        <Typography variant='body2' color='textSecondary' gutterBottom>Gestion</Typography>
        <Card variant='outlined' sx={{ minWidth: '275px', mb: 3 }}>
          <CardContent>
            <Grid item xs={12}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>Statut</Typography>
              <FormControl variant='outlined'>
                <Select
                  variant='standard'
                  id='status-select'
                  value={user.status}
                  onChange={e => dispatch(updateDocAction(USER_COLLECTION, user.id, { status: e.target.value })).then(onClose)}
                  margin='dense'>
                  {STATUSES.map(status => <MenuItem key={status} value={status}>{status}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant='standard' component='fieldset'>
                <FormLabel sx={{ ...labelStyle, position: 'absolute' }}>Types de facturation</FormLabel>
                <FormGroup style={{ marginLeft: 200 }}>
                  {billingTypes?.map(billingType =>
                    <FormControlLabel
                      control={<Checkbox
                        checked={user.billingTypes?.includes(billingType.slug)}
                        onChange={event => dispatch(updateDocAction(USER_COLLECTION, user.id, {
                          billingTypes: event.target.checked ? [...user.billingTypes, billingType.slug] : user.billingTypes.filter(t => t !== billingType.slug),
                        }))}
                        name={billingType.id}
                        color='primary'
                      />}
                      label={billingType.name}
                      key={billingType.id}
                    />,
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant='standard' component='fieldset'>
                <FormLabel sx={{ ...labelStyle, position: 'absolute' }}>Roles</FormLabel>
                <FormGroup sx={{ ml: '200px' }}>
                  {ROLES.map(role =>
                    <FormControlLabel
                      control={<Checkbox
                        checked={user.roles?.includes(role)}
                        onChange={event => dispatch(updateDocAction(USER_COLLECTION, user.id, { roles: event.target.checked ? [...(user.roles || []), role] : user.roles.filter(r => r !== role) }))}
                        name={role}
                        color='primary'
                      />}
                      label={role}
                      key={role}
                    />,
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
          </CardContent>
        </Card>
        
        <Typography variant='body2' color='textSecondary' gutterBottom>Identité</Typography>
        <Card variant='outlined' sx={{ minWidth: '275px', mb: 3 }}>
          <CardContent>
            <Grid item xs={12}>
              <InlineEditor title='Nom' value={user.lastname} handleSubmit={val => dispatch(updateDocAction(USER_COLLECTION, user.id, { lastname: val }))} />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor title='Prénom' value={user.firstname} handleSubmit={val => dispatch(updateDocAction(USER_COLLECTION, user.id, { firstname: val }))} />
            </Grid>
            <Grid item xs={12}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>Sexe</Typography>
              <FormControl variant='outlined'>
                <Select
                  variant='standard'
                  id='sex-select'
                  value={user.gender || 'woman'}
                  onChange={e => dispatch(updateDocAction(USER_COLLECTION, user.id, { gender: e.target.value }))}
                  margin='dense'>
                  <MenuItem value='woman'>Féminin</MenuItem>
                  <MenuItem value='man'>Masculin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>Date de naissance</Typography>
              <DatePicker
                inputFormat='dd-MM-yyyy'
                margin='dense'
                id='birthdate'
                value={user.birthdate ? user.birthdate.toDate() : new Date()}
                onChange={val => dispatch(updateDocAction(USER_COLLECTION, user.id, { birthdate: val }))}
                renderInput={params => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor title='Nationalité' value={user.nationality} handleSubmit={val => dispatch(updateDocAction(USER_COLLECTION, user.id, { nationality: val }))} />
            </Grid>
            <Grid item xs={12}>
              <Typography color='textSecondary' gutterBottom sx={labelStyle}>Photo</Typography>
              <Avatar user={user} />
            </Grid>
          </CardContent>
        </Card>
        
        <Typography variant='body2' color='textSecondary' gutterBottom>Coordonnées</Typography>
        <Card variant='outlined' sx={{ minWidth: '275px', mb: 3 }}>
          <CardContent>
            <Grid item xs={12}>
              <InlineEditor title='Email' value={user.email} handleSubmit={val => dispatch(updateDocAction(USER_COLLECTION, user.id, { email: val }))} />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor title='Téléphone' value={user.phone} handleSubmit={val => dispatch(updateDocAction(USER_COLLECTION, user.id, { phone: val }))} />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user.hideCoordinates || false}
                    onChange={e => dispatch(updateDocAction(USER_COLLECTION, user.id, { hideCoordinates: e.target.checked }))}
                    color='primary'
                  />
                }
                label={user.firstname + ' souhaite cacher ses coordonnées'}
              />
            </Grid>
            {user.email && <Grid item xs={12}>
              <Button onClick={() => dispatch(resetPasswordAction(user.email))} variant='text'>
                Envoyer un email de reset mot de passe à {user.firstname}
              </Button>
            </Grid>}
            <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
            <Typography variant='body1'>Adresse</Typography>
            <Grid item xs={12}>
              <InlineEditor title='Rue' value={user.address?.street} handleSubmit={val => dispatch(updateDocAction(USER_COLLECTION, user.id, { address: { ...user.address, street: val } }))} />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor title='Code postal' value={user.address?.zipcode} handleSubmit={val => dispatch(updateDocAction(USER_COLLECTION, user.id, { address: { ...user.address, zipcode: val } }))} />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor title='Ville' value={user.address?.city} handleSubmit={val => dispatch(updateDocAction(USER_COLLECTION, user.id, { address: { ...user.address, city: val } }))} />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor title='Pays' value={user.address?.country} handleSubmit={val => dispatch(updateDocAction(USER_COLLECTION, user.id, { address: { ...user.address, country: val } }))} />
            </Grid>
          </CardContent>
        </Card>
        
        <Typography variant='body2' color='textSecondary' gutterBottom>Expérience</Typography>
        <Card variant='outlined' sx={{ minWidth: '275px', mb: 3 }}>
          <CardContent>
            <Grid item xs={12}>
              <InlineEditor
                title='Heures de vol'
                value={user.experience?.total}
                handleSubmit={val => dispatch(updateDocAction(USER_COLLECTION, user.id, { experience: { ...user.experience, total: val } }))}
              />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor
                title='Heures de voltige'
                value={user.experience?.aerobatics}
                handleSubmit={val => dispatch(updateDocAction(USER_COLLECTION, user.id, { experience: { ...user.experience, aerobatics: val } }))}
              />
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        {profile.roles.includes(ROLE_SUPER_ADMIN) && <DeleteButton deleteAction={() => dispatch(deleteDocAction(USER_COLLECTION, user.id)).then(onClose)} text='Supprimer' />}
        <Button onClick={onClose} color='primary'>Fermer</Button>
      </DialogActions>
      {openAvatar && <Modal open onClose={() => setOpenAvatar(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: '400px',
          '& img': {
            width: '100%',
          },
        }}>
          <img src={user.photoUrl || getGravatar(user.email)} alt='Avatar' />
        </Box>
      </Modal>}
    </Dialog>
  )
}

EditUserDialog.propTypes = {
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditUserDialog
