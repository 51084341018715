import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { REGEX_MAIL } from '../../_constants/misc'
import { inviteMemberAction } from '../../store/actions/usersActions'
import { STATUS_VISITOR } from '../../_constants/globals'


const styles = theme => ({
  root: {
    '& .MuiInput-underline::before': {
      borderBottomColor: 'rgba(0,0,0,.1)',
    },
  },
  select: {
    width: '100%',
  },
  avatar: {
    marginRight: 10,
    display: 'inline-flex',
  },
  aircraftBtn: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        cursor: 'default',
      },
    },
  },
})

const InviteMemberDialog = ({ classes, onClose, inviteMemberAction }) => {

  const [firstname, setFirstname] = useState(null)
  const [lastname, setLastname] = useState(null)
  const [email, setEmail] = useState(null)
  const [errors, setErrors] = useState({})

  const handleSubmit = () => {
    if (!firstname) setErrors(prev => ({ ...prev, firstname: 'Un prénom doit être renseigné' }))
    else if (email && !email.match(REGEX_MAIL)) setErrors(prev => ({ ...prev, email: 'Le format de l\'email n\'est pas bon' }))
    else
      inviteMemberAction({
        email: email?.toLowerCase().trim() || null,
        firstname: firstname.trim(),
        lastname: lastname.trim(),
        status: !email ? STATUS_VISITOR : null,
      }).then(onClose)
  }

  return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-title' className={classes.root}>
      <DialogTitle id='form-dialog-title'>Inviter un nouveau membre</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              variant='standard'
              margin='dense'
              id='firstname'
              label='Prénom'
              fullWidth
              value={firstname || ''}
              onChange={e => setFirstname(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && handleSubmit()}
              error={Boolean(errors.firstname)}
              helperText={errors.firstname} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant='standard'
              margin='dense'
              id='lastname'
              label='Nom'
              fullWidth
              value={lastname || ''}
              onChange={e => setLastname(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && handleSubmit()}
              error={Boolean(errors.lastname)}
              helperText={errors.lastname} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='standard'
              margin='dense'
              id='email'
              label='Email'
              fullWidth
              value={email || ''}
              onChange={e => setEmail(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && handleSubmit()}
              error={Boolean(errors.email)}
              helperText={errors.email} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Annuler
        </Button>
        <Button onClick={handleSubmit} color='primary'>
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  )
}

InviteMemberDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  inviteMemberAction: PropTypes.func.isRequired,
}

export default compose(
  connect(null, { inviteMemberAction }),
  withStyles(styles),
)(InviteMemberDialog)
