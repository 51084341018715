import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import Avatar from './Avatar'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { uploadProfilePicture } from '../../store/actions/usersActions'


const styles = theme => ({
  title: {
    color: 'rgba(0,0,0,.54)',
    display: 'inline-block',
    lineHeight: '20px',
    width: 200,
  },
  value: {
    display: 'inline-block',
    color: theme.palette.common.black,
  },
  editButton: {
    cursor: 'pointer',
    position: 'relative',
  },
  dialogButton: {
    fontWeight: 600,
  },
  inputfile: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '-1',
  },
  spinner: {
    width: 35,
    height: 35,
    position: 'absolute',
    left: 'calc(50% - 17px)',
    top: 'calc(50% - 17px)',
    backgroundColor: 'rgba(255,255,255,.5)',
  },
})

const AvatarEditor = ({ classes, profile, uploadProfilePicture, className }) => {

  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const [prevPhotoUrl] = useState(profile.photoUrl)
  useEffect(() => {
    if (profile.photoUrl !== prevPhotoUrl){
      setLoading(false)
    }
  }, [profile.photoUrl, prevPhotoUrl])

  const submit = event => {
    setLoading(true)
    uploadProfilePicture(
      event.target.files[0],
      profile.id,
    ).on('state_changed',
      function progress(snapshot) {
        setProgress(100 * snapshot.bytesTransferred / snapshot.totalBytes)
      },
      function error(err) {
        console.error(err)
      },
      function complete() {
        console.log('upload complete')
      },
    )
  }

  return (
    <div>
      <label
        htmlFor='file'
        className={classes.editButton}
        style={{ minWidth: '40px' }}
      >
        <Avatar file={profile.photoUrl} className={className} />
        {loading && <CircularProgress className={classes.spinner} color='inherit' value={progress} variant='determinate' />}
      </label>
      <input
        type='file'
        name='file'
        id='file'
        className={classes.inputfile}
        onChange={submit}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  profile: state.auth.profile,
})

AvatarEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  uploadProfilePicture: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { uploadProfilePicture }),
)(AvatarEditor)
