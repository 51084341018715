import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Card, Button, CardActions, CardContent, Avatar, CardHeader, Box } from '@mui/material'
import { format, formatDistanceToNow } from 'date-fns'
import { History, Announcement } from '@mui/icons-material'
import CommentAircraftDialog from './CommentAircraftDialog'
import PotentialGraph from '../aircrafts/PotentialGraph'


const framedContentStyle = {
  bgcolor: 'grey.100',
  p: '5px',
  borderRadius: '5px',
}
export const AircraftCard = ({ aircraft, sx }) => {

  const [openCommentDialog, setOpenCommentDialog] = useState(false)

  return (
    <Card sx={{ minWidth: 275, mb: 2, pt: 0, px: 1, pb: '30px', position: 'relative', height: 460, ...sx }}>
      <CardHeader
        avatar={<Avatar alt={aircraft.name} src={aircraft.photoUrl} />}
        title={aircraft.name}
        subheader={`${aircraft.activated ? 'activé' : 'masqué'}, ${aircraft.bookable ? 'réservable' : 'vérouillé'}`}
      />
      <CardContent>
        {aircraft.comments && <Box sx={framedContentStyle}>
          <Typography variant='body1'><strong><Announcement
            style={{ verticalAlign: 'bottom' }} />&nbsp;Remarques</strong></Typography>
          <Typography variant='body1'>{aircraft.comments}</Typography>
        </Box>}

        {aircraft.followUp && <Box sx={{ m: 1, mb: 4 }}>
          <Typography variant='body1'><strong>Potentiel</strong></Typography>
          <PotentialGraph aircraft={aircraft} sx={{ mt: 1 }} />
        </Box>}

        {aircraft.lastFlight && <Box sx={framedContentStyle}>
          <Typography variant='body1'><strong><History style={{ verticalAlign: 'bottom' }} />&nbsp;Dernier
            vol</strong></Typography>
          <Typography variant='body1'>
            {formatDistanceToNow(aircraft.lastFlight.startDate.toDate(), { addSuffix: true }) + ' (' + format(aircraft.lastFlight.startDate.toDate(), 'dd-MM-yyyy') + ')'}
          </Typography>
          <Typography variant='body1'>
            {aircraft.lastFlight.pilot}{aircraft.lastFlight.instructor && ` avec ${aircraft.lastFlight.instructor}`}
          </Typography>
          {aircraft.lastFlight.arrival && <Typography variant='body1'>à destination de {aircraft.lastFlight.arrival}</Typography>}
        </Box>}

        {openCommentDialog && <CommentAircraftDialog aircraft={aircraft} onClose={() => setOpenCommentDialog(false)} />}
      </CardContent>
      <CardActions sx={{ position: 'absolute', bottom: theme => theme.spacing(.5) }}>
        <Button color='primary' onClick={() => setOpenCommentDialog(true)}>
          Commenter
        </Button>
      </CardActions>
    </Card>
  )
}

AircraftCard.propTypes = {
  aircraft: PropTypes.object.isRequired,
  sx: PropTypes.object,
}

export default AircraftCard
