import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectYear } from '../../store/actions/dataActions'
import { getYear } from 'date-fns'


const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    verticalAlign: 'baseline',
  },
  label: {
    color: 'white',
  },
  select: {
    minWidth: 120,
    borderColor: theme.palette.secondary.main,
  },
})

export const YearSelector = ({ classes, selectedYear, selectYear }) => {

  const years = [...Array(getYear(Date.now()) - 2017 + 1).keys()].map(y => y + 2017).reverse()

  return (
    <FormControl variant='standard' className={classes.formControl}>
      <InputLabel id='year-select-label' className={classes.label}>Année</InputLabel>
      <Select
        variant='standard'
        labelId='year-select-label'
        id='year-select'
        value={selectedYear || ''}
        onChange={e => selectYear(e.target.value)}
        className={classes.select}>
        {years.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
        <MenuItem value='all'>Toutes les années</MenuItem>
      </Select>
    </FormControl>
  )
}

const mapStateToProps = state => ({
  selectedYear: state.data.selectedYear,
})

YearSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedYear: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  selectYear: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { selectYear }),
)(YearSelector)
