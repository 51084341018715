import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducers from './store/reducers/rootReducer'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getFunctions } from 'firebase/functions'
import { getStorage } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging } from 'firebase/messaging'
import './i18n'
import packageFile from '../package.json'
import { loadReduxState } from './_helpers/localStorageHelper'
import theme from './theme'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import frLocale from 'date-fns/locale/fr'
import './index.css'


window.version = packageFile.version

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
})
const auth = getAuth()
const db = getFirestore()
const functions = getFunctions(app, 'europe-west1')
const storage = getStorage(app)
const analytics = getAnalytics(app)
const messaging = getMessaging(app)

const store = createStore(
  reducers,
  loadReduxState(),
  applyMiddleware(thunk.withExtraArgument({ auth, db, functions, storage, analytics, messaging })),
)

const container = document.getElementById('root')
const root = createRoot(container)

console.log("%c\n\n" +
  "███╗   ███╗ ███████╗ ██╗     ██╗   ██╗  \n" +
  "████╗ ████║ ██╔══██║ ╚██╗   ██╔╝ ██████╗\n" +
  "██╔████╔██║ ███████║  ╚██╗ ██╔╝  ╚═██╔═╝\n" +
  "██║╚██╔╝██║ ██╔════╝   ╚████╔╝     ╚═╝  \n" +
  "██║ ╚═╝ ██║ ██║         ╚██╔╝           \n" +
  "╚═╝     ╚═╝ ╚═╝          ╚═╝            \n\n" +
  "Open source project\n" +
  "Authored by Romain Coeur\n" +
  "", "font-family:monospace;color:#1976d2;font-size:12px;")

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
            <CssBaseline />
            <App />
          </LocalizationProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
