import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Button, CardActions, FormControl, FormHelperText, MenuItem, Select, FormGroup, Checkbox, FormControlLabel, Slider,
  Card, CardContent, Typography, Divider, Grid, TextField } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { useDispatch, useSelector } from 'react-redux'
import { submitExperience, listenCollection } from '../../store/actions/firestoreActions'
import FileUpload from '../_library/FileUpload'
import {
  COMPETITION_LEVEL_ESPOIR, COMPETITION_LEVEL_PROMOTION, COMPETITION_LEVEL_N2, COMPETITION_LEVEL_N1, COMPETITION_LEVEL_ELITE,
  QUALIFICATION_COLLECTION, QUALIFICATION_SUBTYPES, QUALIFICATION_TYPES, USER_COLLECTION,
} from '../../_constants/globals'
import * as _ from 'lodash'
import Loading from '../_library/Loading'
import { DatePicker } from '@mui/x-date-pickers'
import useListener from '../../hooks/useListener'


const styles = theme => ({
  root: {
    margin: '2rem auto',
    maxWidth: 900,
  },
  formControl: {
    verticalAlign: 'initial',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 20,
    },
  },
  formField: {
    marginBottom: '1rem',
  },
  label: {
    color: 'rgba(0,0,0,.54)',
    display: 'inline-block',
    lineHeight: '20px',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 20,
    },
  },
  fileInput: {
    display: 'block',
    width: 'fit-content',
    marginTop: 10,
  },
})

const competitionLevels = [
  COMPETITION_LEVEL_ESPOIR,
  COMPETITION_LEVEL_PROMOTION,
  COMPETITION_LEVEL_N2,
  COMPETITION_LEVEL_N1,
  COMPETITION_LEVEL_ELITE,
]

const getAerobaticLevel = ({ fcl800, bfv1, bfv2, bfv3 }) => {
  if (bfv3) return 4
  if (bfv2) return 3
  if (bfv1) return 2
  if (fcl800) return 1
  return 0
}

const ExperienceForm = ({ classes, next, back }) => {
  
  const profile = useSelector(state => state.auth.profile)
  const qualifications = useSelector(state => state.firestore.qualifications)
  const dispatch = useDispatch()
  useListener(() => listenCollection({
    collection: QUALIFICATION_COLLECTION,
    where: [['userRef', '==', USER_COLLECTION, profile.id]],
    orderBy: [['_createdAt', 'desc']],
  }), [profile.id])
  
  const license = qualifications && qualifications.find(q => q.type?.shortName === QUALIFICATION_TYPES.LICENSE.shortName)
  const sep = qualifications && qualifications.find(q => q.type?.shortName === QUALIFICATION_TYPES.SEP.shortName)
  const tw = qualifications && qualifications.find(q => q.type?.shortName === QUALIFICATION_TYPES.TW.shortName)
  const vp = qualifications && qualifications.find(q => q.type?.shortName === QUALIFICATION_TYPES.VP.shortName)
  const visite = qualifications && qualifications.find(q => q.type?.shortName === QUALIFICATION_TYPES.VISITE.shortName)
  const ffa = qualifications && qualifications.find(q => q.type?.shortName === QUALIFICATION_TYPES.FFA.shortName)
  const fcl800 = qualifications && qualifications.find(q => q.type?.shortName === QUALIFICATION_TYPES.FCL800.shortName)
  const bfv1 = qualifications && qualifications.find(q => q.type?.shortName === QUALIFICATION_TYPES.BFV1.shortName)
  const bfv2 = qualifications && qualifications.find(q => q.type?.shortName === QUALIFICATION_TYPES.BFV2.shortName)
  const bfv3 = qualifications && qualifications.find(q => q.type?.shortName === QUALIFICATION_TYPES.BFV3.shortName)
  
  const [licenseType, setLicenseType] = useState(null)
  const [licenseNumber, setLicenseNumber] = useState(null)
  const [licenseAttachment, setLicenseAttachment] = useState(null)
  const [sepEnd, setSepEnd] = useState(null)
  const [sepAttachment, setSepAttachment] = useState(null)
  const [twCheck, setTwCheck] = useState(false)
  const [vpCheck, setVpCheck] = useState(false)
  const [visiteType, setVisiteType] = useState(null)
  const [visiteEnd, setVisiteEnd] = useState(null)
  const [visiteAttachment, setVisiteAttachment] = useState(null)
  const [ffaNumber, setFfaNumber] = useState(null)
  const [ffaClub, setFfaClub] = useState(null)
  const [ffaEnd, setFfaEnd] = useState(null)
  const [ffaAttachment, setFfaAttachment] = useState(null)
  const [totalHours, setTotalHours] = useState(profile.experience?.total)
  const [aerobaticHours, setAerobaticHours] = useState(profile.experience?.aerobatics)
  const [displayFCL800Input, setDisplayFCL800Input] = useState(false)
  const [displayBFV1Input, setDisplayBFV1Input] = useState(false)
  const [displayBFV2Input, setDisplayBFV2Input] = useState(false)
  const [displayBFV3Input, setDisplayBFV3Input] = useState(false)
  const [FCL800Attachment, setFCL800Attachment] = useState(fcl800?.attachmentUrl)
  const [BFV1Attachment, setBFV1Attachment] = useState(bfv1?.attachmentUrl)
  const [BFV2Attachment, setBFV2Attachment] = useState(bfv2?.attachmentUrl)
  const [BFV3Attachment, setBFV3Attachment] = useState(bfv3?.attachmentUrl)
  const [competitionLevel, setCompetitionLevel] = useState(profile.competition ? competitionLevels.findIndex(val => val === profile.competition.level) : 0)
  const [competitionPlace, setCompetitionPlace] = useState(profile.competition?.qualificationPlace || '')
  const [competitionYear, setCompetitionYear] = useState(profile.competition?.qualificationYear || '')
  const [errors] = useState({})
  
  useEffect(() => {
    if (license) {
      setLicenseType(_.find(QUALIFICATION_SUBTYPES, { id: license.subtype }))
      setLicenseNumber(license.identValue)
      setLicenseAttachment(license.attachmentUrl)
    }
    if (sep) {
      setSepEnd(sep.expireDate?.toDate())
      setSepAttachment(sep.attachmentUrl)
    }
    if (tw) setTwCheck(true)
    if (vp) setVpCheck(true)
    if (visite) {
      setVisiteType(_.find(QUALIFICATION_SUBTYPES, { id: visite.subtype }))
      visite.expireDate && setVisiteEnd(visite.expireDate?.toDate())
      visite.attachmentUrl && setVisiteAttachment(visite.attachmentUrl)
    }
    if (ffa) {
      setFfaNumber(ffa.identValue)
      setFfaClub(ffa.club)
      ffa.expireDate && setFfaEnd(ffa.expireDate?.toDate())
      ffa.attachmentUrl && setFfaAttachment(ffa.attachmentUrl)
    }
    if (fcl800) {
      setDisplayFCL800Input(true)
      setFCL800Attachment(fcl800.attachmentUrl)
    }
    if (bfv1) {
      setDisplayBFV1Input(true)
      setBFV1Attachment(bfv1.attachmentUrl)
    }
    if (bfv2) {
      setDisplayBFV2Input(true)
      setBFV2Attachment(bfv2.attachmentUrl)
    }
    if (bfv3) {
      setDisplayBFV3Input(true)
      setBFV3Attachment(bfv3.attachmentUrl)
    }
  }, [license, bfv1, bfv2, bfv3, fcl800, ffa, sep, tw, visite, vp])
  
  const onChangeAerobaticLevel = value => {
    if (value >= 1) setDisplayFCL800Input(true)
    else setDisplayFCL800Input(false)
    if (value === 2) setDisplayBFV1Input(true)
    else setDisplayBFV1Input(false)
    if (value === 3) setDisplayBFV2Input(true)
    else setDisplayBFV2Input(false)
    if (value === 4) setDisplayBFV3Input(true)
    else setDisplayBFV3Input(false)
  }
  
  const handleSubmit = () => {
    const data = {}
    
    const licenseTmp = _.clone(license) || {}
    if (licenseType) licenseTmp.subtype = licenseType.id
    if (licenseNumber) licenseTmp.identValue = licenseNumber
    if (licenseAttachment) licenseTmp.attachmentUrl = licenseAttachment
    if (!_.isEmpty(licenseTmp)) data.license = licenseTmp
    
    const sepTmp = _.clone(sep) || {}
    if (sepEnd) sepTmp.expireDate = sepEnd
    if (sepAttachment) sepTmp.attachmentUrl = sepAttachment
    if (!_.isEmpty(sepTmp)) data.sep = sepTmp
    
    if (twCheck) data.tw = tw || {}
    if (vpCheck) data.vp = vp || {}
    
    const visiteTmp = _.clone(visite) || {}
    if (visiteType) visiteTmp.subtype = visiteType.id
    if (visiteEnd) visiteTmp.expireDate = visiteEnd
    if (visiteAttachment) visiteTmp.attachmentUrl = visiteAttachment
    if (!_.isEmpty(visiteTmp)) data.visite = visiteTmp
    
    const ffaTmp = _.clone(ffa) || {}
    if (ffaNumber) ffaTmp.identValue = ffaNumber
    if (ffaClub) ffaTmp.club = ffaClub
    if (ffaEnd) ffaTmp.expireDate = ffaEnd
    if (ffaAttachment) ffaTmp.attachmentUrl = ffaAttachment
    if (!_.isEmpty(ffaTmp)) data.ffa = ffaTmp
    
    const experienceTmp = profile.experience || {}
    if (totalHours) experienceTmp.total = totalHours
    if (aerobaticHours) experienceTmp.aerobatics = aerobaticHours
    if (!_.isEmpty(experienceTmp)) data.experience = experienceTmp
    
    const fcl800Tmp = _.clone(fcl800) || {}
    if (FCL800Attachment) fcl800Tmp.attachmentUrl = FCL800Attachment
    if (!_.isEmpty(fcl800Tmp)) data.fcl800 = fcl800Tmp
    
    const bfv1Tmp = _.clone(bfv1) || {}
    if (BFV1Attachment) bfv1Tmp.attachmentUrl = BFV1Attachment
    if (!_.isEmpty(bfv1Tmp)) data.bfv1 = bfv1Tmp
    
    const bfv2Tmp = _.clone(bfv2) || {}
    if (BFV2Attachment) bfv2Tmp.attachmentUrl = BFV2Attachment
    if (!_.isEmpty(bfv2Tmp)) data.bfv2 = bfv2Tmp
    
    const bfv3Tmp = _.clone(bfv3) || {}
    if (BFV3Attachment) bfv3Tmp.attachmentUrl = BFV3Attachment
    if (!_.isEmpty(bfv3Tmp)) data.bfv3 = bfv3Tmp
    
    const competitionTmp = profile.competition || {}
    if (competitionLevel) competitionTmp.level = competitionLevels[competitionLevel]
    if (competitionPlace) competitionTmp.qualificationPlace = competitionPlace
    if (competitionYear) competitionTmp.qualificationYear = competitionYear
    if (!_.isEmpty(competitionTmp)) data.competition = competitionTmp
    
    return dispatch(submitExperience(data)).then(next)
  }
  
  if (!qualifications) return <Loading />
  
  return (
    <Card className={classes.root} variant='outlined'>
      <CardContent>
        <Grid item container xs={12} className={classes.formField}>
          <Grid item sm={3} xs={12}>
            <Typography className={classes.label} color='textSecondary'>
              Licence aéronautique
            </Typography>
          </Grid>
          <Grid item sm={9} xs={12}>
            <FormControl variant='outlined' className={classes.formControl} error={Boolean(errors.gender)}>
              <Select
                variant='standard'
                id='license-type-select'
                value={licenseType || 'no'}
                onChange={e => setLicenseType(e.target.value)}
                margin='dense'>
                <MenuItem value={'no'}><em>Aucune license</em></MenuItem>
                {_.chain(QUALIFICATION_SUBTYPES)
                  .filter(sub => sub.type === QUALIFICATION_TYPES.LICENSE.id)
                  .map(subtype => <MenuItem key={subtype.id} value={subtype}>{subtype.label}</MenuItem>)
                  .value()}
              </Select>
              {errors.licenseType && <FormHelperText>{errors.licenseType}</FormHelperText>}
            </FormControl>
            <TextField
              variant='standard'
              label='Numéro'
              value={licenseNumber || ''}
              onChange={e => setLicenseNumber(e.target.value)}
              error={Boolean(errors.licenseNumber)}
              helperText={errors.licenseNumber}
              className={classes.formControl} />
            <FileUpload
              folder='PPL'
              placeholder='Photo de la licence'
              fileName={profile.lastname}
              onComplete={setLicenseAttachment}
              fileUrl={licenseAttachment}
              sx={{ verticalAlign: 'middle', display: 'inline-block' }}
            />
          </Grid>
        </Grid>
        <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
        <Grid item container xs={12} className={classes.formField}>
          <Grid item sm={3} xs={12}>
            <Typography className={classes.label} color='textSecondary'>
              Fin de validité SEP
            </Typography>
          </Grid>
          <Grid item sm={9} xs={12}>
            <DatePicker
              inputFormat='dd-MM-yyyy'
              margin='dense'
              id='sepEnd'
              value={sepEnd || null}
              onChange={setSepEnd}
            />
            <FileUpload
              folder='sep'
              placeholder='Photo de la SEP'
              fileName={profile.lastname}
              onComplete={setSepAttachment}
              fileUrl={sepAttachment}
              sx={{ verticalAlign: 'middle', display: 'inline-block', ml: 1 }}
            />
          </Grid>
        </Grid>
        <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
        <Grid item container xs={12} className={classes.formField}>
          <Grid item sm={3} xs={12}>
            <Typography className={classes.label} color='textSecondary'>
              Variantes / Classes
            </Typography>
          </Grid>
          <Grid item sm={9} xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={twCheck} onChange={e => setTwCheck(e.target.checked)} name='tw' />}
                label='TW (Train classique)'
              />
              <FormControlLabel
                control={<Checkbox checked={vpCheck} onChange={e => setVpCheck(e.target.checked)} name='vp' />}
                label='VP (Hélices à pas variable)'
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
        <Grid item container xs={12} className={classes.formField}>
          <Grid item sm={3} xs={12}>
            <Typography className={classes.label} color='textSecondary'>
              Visite médicale
            </Typography>
          </Grid>
          <Grid item sm={9} xs={12}>
            <FormControl variant='outlined' className={classes.formControl} error={Boolean(errors.gender)}>
              <Select
                variant='standard'
                id='visite-type-select'
                value={visiteType || 'no'}
                onChange={e => setVisiteType(e.target.value)}
                margin='dense'>
                <MenuItem value='no'><em>Pas de médical</em></MenuItem>
                {_.chain(QUALIFICATION_SUBTYPES)
                  .filter(sub => sub.type === QUALIFICATION_TYPES.VISITE.id)
                  .map(subtype => <MenuItem key={subtype.id} value={subtype}>{subtype.label}</MenuItem>)
                  .value()}
              </Select>
              {errors.visiteType && <FormHelperText>{errors.visiteType}</FormHelperText>}
            </FormControl>
            <DatePicker
              label='Validité'
              inputFormat='dd-MM-yyyy'
              margin='dense'
              id='visiteValidity'
              value={visiteEnd || null}
              onChange={setVisiteEnd}
              componentsProps={{ textField: { error: !!errors.visiteEnd, helperText: errors.visiteEnd } }}
            />
            <FileUpload
              folder='visite'
              placeholder='Photo de la visite'
              fileName={profile.lastname}
              onComplete={setVisiteAttachment}
              fileUrl={visiteAttachment}
              sx={{ verticalAlign: 'middle', display: 'inline-block', ml: 1 }}
            />
          </Grid>
        </Grid>
        <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
        <Grid item container xs={12} className={classes.formField}>
          <Grid item sm={3} xs={12}>
            <Typography className={classes.label} color='textSecondary'>
              Licence FFA
            </Typography>
          </Grid>
          <Grid item sm={9} xs={12}>
            <TextField
              variant='standard'
              label='Numéro'
              value={ffaNumber || ''}
              onChange={e => setFfaNumber(e.target.value)}
              error={Boolean(errors.ffaNumber)}
              helperText={errors.ffaNumber}
              className={classes.formControl} />
            <TextField
              variant='standard'
              label='Aéroclub d&apos;affiliation'
              value={ffaClub || ''}
              onChange={e => setFfaClub(e.target.value)}
              error={Boolean(errors.ffaClub)}
              helperText={errors.ffaClub}
              className={classes.formControl} />
          </Grid>
          <Grid item sm={3} xs={false}>
          </Grid>
          <Grid item sm={9} xs={12}>
            <DatePicker
              label='Validité'
              inputFormat='dd-MM-yyyy'
              margin='dense'
              id='ffaValidity'
              value={ffaEnd || null}
              onChange={setFfaEnd}
              componentsProps={{ textField: { error: !!errors.ffaEnd, helperText: errors.ffaEnd } }}
              sx={{ mt: 1 }}
            />
            <FileUpload
              folder='Licence'
              placeholder='Photo de la licence'
              fileName={profile.lastname}
              onComplete={setFfaAttachment}
              fileUrl={ffaAttachment}
              sx={{ verticalAlign: 'middle', display: 'inline-block', mt: 1, ml: 1 }}
            />
          </Grid>
        </Grid>
        <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
        <Grid item container xs={12} className={classes.formField}>
          <Grid item sm={3} xs={12}>
            <Typography className={classes.label} color='textSecondary'>
              Heures de vol
            </Typography>
          </Grid>
          <Grid item sm={9} xs={12}>
            <TextField
              variant='standard'
              label='Totales'
              type='number'
              value={totalHours || 0}
              onChange={e => setTotalHours(e.target.value)}
              error={Boolean(errors.totalHours)}
              helperText={errors.totalHours}
              className={classes.formControl} />
            <TextField
              variant='standard'
              label='dont voltige'
              type='number'
              value={aerobaticHours || 0}
              onChange={e => setAerobaticHours(e.target.value)}
              error={Boolean(errors.aerobaticHours)}
              helperText={errors.aerobaticHours}
              className={classes.formControl} />
          </Grid>
        </Grid>
        <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
        <Grid item container xs={12} className={classes.formField}>
          <Grid item sm={3} xs={12}>
            <Typography color='textSecondary'>
              Niveau en voltige
            </Typography>
          </Grid>
          <Grid item sm={9} xs={12}>
            <Slider
              defaultValue={getAerobaticLevel({ fcl800, bfv1, bfv2, bfv3 })}
              aria-labelledby='discrete-slider-restrict'
              valueLabelDisplay='off'
              marks={[
                { value: 0, label: 'Stagiaire' },
                { value: 1, label: 'FCL800' },
                { value: 2, label: 'BFV1' },
                { value: 3, label: 'BFV2' },
                { value: 4, label: 'BFV3' },
              ]}
              max={4}
              className={classes.formControl}
              style={{ width: 500, maxWidth: '100%' }}
              onChange={ (event, value) => onChangeAerobaticLevel(value)}
            />
            {displayFCL800Input && <FileUpload
              folder='FCL800'
              placeholder='Photo du FCL800'
              fileName={profile.lastname}
              onComplete={setFCL800Attachment}
              fileUrl={FCL800Attachment}
              className={classes.fileInput}
            />}
            {displayBFV1Input && <FileUpload
              folder='BFV1'
              placeholder='Photo du BFV1'
              fileName={profile.lastname}
              className={classes.fileInput}
              onComplete={setBFV1Attachment}
              fileUrl={BFV1Attachment}
            />}
            {displayBFV2Input && <FileUpload
              folder='BFV2'
              placeholder='Photo du BFV2'
              fileName={profile.lastname}
              className={classes.fileInput}
              onComplete={setBFV2Attachment}
              fileUrl={BFV2Attachment}
            />}
            {displayBFV3Input && <FileUpload
              folder='BFV3'
              placeholder='Photo du BFV3'
              fileName={profile.lastname}
              className={classes.fileInput}
              onComplete={setBFV3Attachment}
              fileUrl={BFV3Attachment}
            />}
          </Grid>
        </Grid>
        <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
        <Grid item container xs={12} className={classes.formField}>
          <Grid item sm={3} xs={12}>
            <Typography className={classes.label} color='textSecondary'>
              Niveau en compétition
            </Typography>
          </Grid>
          <Grid item sm={9} xs={12}>
            <Slider
              value={competitionLevel}
              aria-labelledby='discrete-slider-restrict'
              valueLabelDisplay='off'
              max={4}
              marks={[
                { value: 0, label: 'Espoir' },
                { value: 1, label: 'Promotion' },
                { value: 2, label: 'National 2' },
                { value: 3, label: 'Advanced-Excellence' },
                { value: 4, label: 'Elite' },
              ]}
              className={classes.formControl}
              style={{ width: 500, maxWidth: '100%' }}
              onChange={(event, value) => setCompetitionLevel(value)}
            />
            {competitionLevel !== 0 && <TextField
              variant='standard'
              label='Qualifié à'
              value={competitionPlace}
              onChange={e => setCompetitionPlace(e.target.value)}
              error={Boolean(errors.competitionPlace)}
              helperText={errors.competitionPlace}
              className={classes.formControl} />}
            {competitionLevel !== 0 && <TextField
              variant='standard'
              label='Année'
              type='number'
              value={competitionYear}
              onChange={e => setCompetitionYear(e.target.value)}
              error={Boolean(errors.competitionYear)}
              helperText={errors.competitionYear}
              className={classes.formControl} />}
          </Grid>
        </Grid>
        <Divider variant='fullWidth' light style={{ margin: '1rem -16px' }} />
      </CardContent>
      <CardActions style={{ float: 'right' }}>
        <Button size='large' onClick={back}>
          Précédent
        </Button>
        <Button size='large' color='primary' onClick={handleSubmit}>
          Valider
        </Button>
      </CardActions>
    </Card>
  )
}

ExperienceForm.propTypes = {
  classes: PropTypes.object.isRequired,
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles),
)(ExperienceForm)
