import React, { useMemo, useRef, useState } from 'react'
import { Box, Link, MenuItem, Select, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { PB_LESSON_COLLECTION, PROGRESS_BOOK_COLLECTION, QUALIFICATION_COLLECTION, QUALIFICATION_TYPE_COLLECTION, ROLE_CHEF_PILOTE,
  ROLE_INSTRUCTOR } from '../../_constants/globals'
import ItemsCard from './ItemsCard'
import DeleteButton from '../_library/DeleteButton'
import { deleteProgressBookAction } from '../../store/actions/progressBookActions'
import Loading from '../_library/Loading'
import PilotCard from './PilotCard'
import { PROGRESS_BOOKS } from '../../_constants/routes'
import LessonsCard from './LessonsCard'
import StatCard from './StatCard'
import SelectedLessonManager from './SelectedLessonManager'
import GeneralImpressionCard from './GeneralImpressionCard'
import { listenCollection, updateDocAction } from '../../store/actions/firestoreActions'
import ClosureDialog from './ClosureDialog'
import CTAButton from '../_library/CTAButton'
import { useMatch, useNavigate } from 'react-router'
import useListener from '../../hooks/useListener'
import { ScrollSync } from 'react-scroll-sync'


const ProgressBookDetails = () => {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const match = useMatch(PROGRESS_BOOKS + '/:id')
  const lessons = useSelector(state => state.firestore.lessons)
  const progressBook = useSelector(state => state.firestore.progressBooks?.find(b => b.id === match.params.id))
  const adminMode = useSelector(state => state.data.adminMode)
  const items = useSelector(state => state.firestore.items)
  const roles = useSelector(state => state.auth.profile.roles)
  const licence = useSelector(state => state.firestore.licence && state.firestore.licence[0])
  
  useListener(() => listenCollection({
    collection: QUALIFICATION_COLLECTION,
    where: [
      ['userRef', '==', progressBook.pilotRef.parent.id, progressBook.pilotRef.id],
      ['typeRef', '==', QUALIFICATION_TYPE_COLLECTION, 'CtRXmGg4PAZQjjsKST0O'],
    ],
    storeAs: 'licence',
  }), [progressBook?.pilotRef], !!progressBook?.pilotRef)
  useListener(() => listenCollection({
    collection: PB_LESSON_COLLECTION,
    where: [['pilotRef', '==', progressBook.pilotRef.parent.id, progressBook.pilotRef.id]],
    orderBy: [['date', 'asc']],
    storeAs: 'lessons',
  }), [progressBook?.pilotRef], !!progressBook?.pilotRef)
  
  const [closureDialogOpen, setClosureDialogOpen] = useState(null)
  
  const theoreticalCard = useRef(null)
  const practicalCard = useRef(null)
  const generalImpressionCard = useRef(null)
  
  const filteredItems = useMemo(() => progressBook && items?.filter(i => i.level === progressBook.level), [progressBook, items])
  const filteredLessons = useMemo(
    () => lessons && progressBook && lessons
      .filter(l => l.progressBookRef?.id === progressBook?.id)
      .map(l => l.selectedItemIds ? { ...l, theoreticalItems: {}, practicalItems: {}, generalImpression: {} } : l),
    [lessons, progressBook],
  )
  
  const handleDelete = () => dispatch(deleteProgressBookAction(progressBook.id)).then(() => navigate(PROGRESS_BOOKS))
  
  const scrollTo = id => {
    switch (id){
      case 'theoreticalCard':
        theoreticalCard.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        break
      case 'practicalCard':
        practicalCard.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        break
      case 'generalImpressionCard':
        generalImpressionCard.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        break
    }
  }
  
  if (!progressBook) return <Loading />
  else return (
    <Box sx={{ p: 1, pb: '120px' }}>
      {adminMode && <Select
        id='progressBook-status-select'
        variant='standard'
        value={progressBook.status}
        label='Statut'
        onChange={e => dispatch(updateDocAction(PROGRESS_BOOK_COLLECTION, progressBook.id, { status: e.target.value }))}
        sx={{ float: 'right' }}
      >
        <MenuItem value='open'>open</MenuItem>
        <MenuItem value='standBy'>standBy</MenuItem>
      </Select>}
      <Typography variant='h1'>{progressBook.name}</Typography>
      <Typography variant='subtitle1'>Ce livret de formation est basé sur la formation FCL800 editée par la FFA.
        Cependant, à des fins pédagogiques, d&apos;adaptation aux capacités de l&apos;élève et aux conditions météorologiques du jour ou
        toute autre raison que l&apos;instructeur estimera, l&apos;ordre des items travaillés pourra être adapté par
        l&apos;instructeur.</Typography>
      <PilotCard progressBook={progressBook} licence={licence} />
      <StatCard progressBook={progressBook} />
      <ScrollSync>
        <>
          {(roles?.includes(ROLE_INSTRUCTOR) || adminMode) && <div ref={theoreticalCard}>
            <ItemsCard
              progressBook={progressBook}
              items={filteredItems?.filter(i => i.type === 'theoretical')}
              type='theoretical'
              lessons={filteredLessons}
            />
          </div>}
          {(roles?.includes(ROLE_INSTRUCTOR) || adminMode) && <div ref={practicalCard}>
            <ItemsCard
              progressBook={progressBook}
              type='practical'
              items={filteredItems?.filter(i => i.type === 'practical')}
              lessons={filteredLessons}
            />
          </div>}
          {(roles?.includes(ROLE_INSTRUCTOR) || adminMode) && <div ref={generalImpressionCard}>
            <GeneralImpressionCard
              progressBook={progressBook}
              lessons={filteredLessons}
            />
          </div>}
        </>
      </ScrollSync>
      {filteredLessons && filteredItems && <LessonsCard lessons={filteredLessons} items={filteredItems} />}
      
      {filteredLessons?.length === 0 && <DeleteButton deleteAction={handleDelete} text='Supprimer le livret' sx={{ width: '100%' }}  />}
      {filteredLessons?.length !== 0 && progressBook.status === 'open' && <CTAButton sx={{ display: 'block', width: '100%' }} onClick={() => setClosureDialogOpen(true)}>Terminer le livret...</CTAButton>}
      {filteredLessons?.length !== 0 && roles.includes(ROLE_INSTRUCTOR) && progressBook.status === 'closing' &&
        <CTAButton
          onClick={() => dispatch(updateDocAction(PROGRESS_BOOK_COLLECTION, progressBook.id, { status: 'instructorValidated' }, true))}
          sx={{ display: 'block', width: '100%' }}
        >Valider le livret</CTAButton>
      }
      {filteredLessons?.length !== 0 && roles.includes(ROLE_CHEF_PILOTE) && progressBook.status === 'instructorValidated' &&
        <CTAButton
          onClick={() => dispatch(updateDocAction(PROGRESS_BOOK_COLLECTION, progressBook.id, { status: 'validated' }, true))}
          sx={{ display: 'block', width: '100%' }}
        >Valider le livret</CTAButton>
      }
      {(roles?.includes(ROLE_INSTRUCTOR) || adminMode) &&
        <SelectedLessonManager progressBook={progressBook} lessonsWithItems={filteredLessons} scrollTo={scrollTo} />
      }
      {progressBook.pdfUrl && <Link
        href={progressBook.pdfUrl}
        target='_blank'
        rel='noopener noreferrer'
        download
        style={{ cursor: 'pointer' }}
        underline='hover'>Télécharger le livret</Link>}
      {closureDialogOpen && <ClosureDialog onClose={() => setClosureDialogOpen(false)} progressBook={progressBook} />}
    </Box>
  )
}

export default ProgressBookDetails
