import { DELETE_NOTIFICATION, INFO, NOTIFICATIONS_ALLOWED, RESET_NOTIFICATIONS } from '../types'
import { getToken, onMessage } from 'firebase/messaging'
import { arrayUnion, doc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { USER_COLLECTION } from '../../_constants/globals'

export const resetNotifications = () => dispatch => dispatch({ type: RESET_NOTIFICATIONS })

export const deleteNotification = key => dispatch => dispatch({ type: DELETE_NOTIFICATION, payload: key })

export const requestTokenAction = async (dispatch, getState, { messaging, db }) => {
  const permission = await Notification?.requestPermission()
  if (permission === 'granted') {
    const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
    if (currentToken) {
      await updateDoc(doc(db, USER_COLLECTION, getState().auth.profile.id), {
        fmcTokens: arrayUnion(currentToken),
        _updatedAt: serverTimestamp(),
      })
      dispatch({ type: NOTIFICATIONS_ALLOWED, payload: true })
      onMessage(messaging, payload => {
        console.log('Message received. ', payload)
        dispatch({ type: INFO, payload: payload.notification.body })
      })
      
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.')
      dispatch({ type: NOTIFICATIONS_ALLOWED, payload: false })
    }
    return currentToken
  } else {
    dispatch({ type: NOTIFICATIONS_ALLOWED, payload: false })
    return null
  }
}
