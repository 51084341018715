import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, Stepper, Step, StepLabel, Box, useTheme, useMediaQuery, MobileStepper } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { compose } from 'recompose'
import CreateFlightForm from './CreateFlightForm'
import { useDispatch, useSelector } from 'react-redux'
import LogbookGuide from './LogbookGuide'
import LessonSetup from '../progressBooks/LessonSetup'
import LessonSummary from '../progressBooks/LessonSummary'
import { onSnapshot } from 'firebase/firestore'
import { getRef, listenCollection } from '../../store/actions/firestoreActions'
import { FLIGHT_COLLECTION, PROGRESS_BOOK_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import StatusReport from './StatusReport'
import useListener from '../../hooks/useListener'


const styles = () => ({
  root: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 700,
    },
    '& .MuiInput-underline::before': {
      borderBottomColor: 'rgba(0,0,0,.1)',
    },
  },
})

export const NewFlightDialog = ({ classes, open, onClose, defaultFlightId }) => {
  
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const adminMode = useSelector(state => state.data.adminMode)
  const openBooks = useSelector(state => state.firestore.openBooks)
  const dispatch = useDispatch()
  
  const [activeStep, setActiveStep] = useState(defaultFlightId ? 1 : 0)
  const [flightRef, setFlightRef] = useState(defaultFlightId ? dispatch(getRef(FLIGHT_COLLECTION, defaultFlightId)) : null)
  const [flight, setFlight] = useState(null)
  
  useListener(() => listenCollection({
    collection: PROGRESS_BOOK_COLLECTION,
    where: [
      ['pilotRef', '==', USER_COLLECTION, flight.pilotRef.id],
      ['status', '==', 'open'],
    ],
    orderBy: [['_createdAt', 'asc']],
    storeAs: 'openBooks',
  }), [flight], !!flight?.pilotRef)
  
  const progressBookTab = useMemo(() => openBooks?.length > 0 && !!flight?.instructorRef && !adminMode, [openBooks, flight, adminMode])
  
  const steps = useMemo(() => {
    const commonSteps = ['Création du vol', 'Carnet de route', 'Etat de l\'avion']
    if (progressBookTab) commonSteps.push('Livret de progression')
    return commonSteps
  }, [progressBookTab])
  
  useEffect(() => {
    if (flightRef){
      const flightListener = onSnapshot(flightRef, doc => {setFlight({ id: doc.id, ...doc.data() })})
      return () => {
        flightListener()
      }
    }
  }, [flightRef])
  
  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <CreateFlightForm next={() => setActiveStep(activeStep + 1)} onClose={onClose} setFlightRef={setFlightRef} />
      case 1:
        return <LogbookGuide flight={flight} next={() => setActiveStep(activeStep + 1)} />
      case 2:
        return <StatusReport flight={flight} next={() => progressBookTab ? setActiveStep(activeStep + 1) : onClose()} />
      case 3:
        return flight.lessonRef
          ? <LessonSummary lessonId={flight.lessonRef.id} next={onClose} back={() => setActiveStep(val => val - 1)} />
          : <LessonSetup openBooks={openBooks} flight={flight} next={onClose} back={() => setActiveStep(val => val - 1)} />
      default:
        return 'Unknown stepIndex'
    }
  }
  
  return (
    <Dialog
      open={open || false}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      fullScreen={!isUpMd}
      className={classes.root}
    >
      <DialogTitle id='form-dialog-title'>Nouveau vol</DialogTitle>
      {isUpMd
        ? <Stepper activeStep={activeStep}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        : <MobileStepper
          steps={steps.length}
          activeStep={activeStep}
          backButton={<div />}
          nextButton={<div />}
        />
      }
      <Box p={1} pb={{ xs: '25px', md: 1 }}>
        {getStepContent(activeStep)}
      </Box>
    </Dialog>
  )
}

NewFlightDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  defaultFlightId: PropTypes.string,
}

export default compose(
  withStyles(styles),
)(NewFlightDialog)
