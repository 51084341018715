/**
 * Cross Project constants
 *
 * This file purpose is to centralise all constants used across project
 * It is part of the project root folder and scripts may copy it in some subfolders if needed
 */
module.exports = {
  FIREBASE_LOCATION: 'europe-west1',

  SETTINGS_COLLECTION: '_settings',
  ACCOUNT_ENTRY_COLLECTION: 'accountEntries',
  ACCOUNT_FLUX_COLLECTION: 'accountFlux',
  ACCOUNT_COLLECTION: 'accounts',
  ACTIVITY_COLLECTION: 'activities',
  AIRCRAFT_COLLECTION: 'aircrafts',
  AIRPORT_COLLECTION: 'airports',
  AVAILABILITY_COLLECTION: 'availabilities',
  BILLING_TYPE_COLLECTION: 'billingTypes',
  BOOKING_COLLECTION: 'bookings',
  CHAT_COLLECTION: 'chats',
  EMAIL_COLLECTION: 'emails',
  FLIGHT_TYPE_COLLECTION: 'flightTypes',
  FLIGHT_COLLECTION: 'flights',
  SLOT_COLLECTION: 'slots',
  MECA_REPORT_COLLECTION: 'mecaReports',
  NOTIFICATION_COLLECTION: 'notifications',
  PROGRESS_BOOK_COLLECTION: 'progressBooks',
  PB_LESSON_COLLECTION: 'progressBooks_lessons',
  PB_ITEM_COLLECTION: 'progressBooks_pbItems',
  PB_TEMPLATE_COLLECTION: 'progressBooks_templates',
  QUALIFICATION_COLLECTION: 'qualifications',
  QUALIFICATION_TYPE_COLLECTION: 'qualificationTypes',
  REQUEST_COLLECTION: 'requests',
  USER_COLLECTION: 'users',

  QUALIFICATION_TYPES: {
    LICENSE: { id: 'license', label: 'Licence aéronautique', shortName: 'licence' },
    FI: { id: 'fi', label: 'Flight Instructor (FI)', shortName: 'FI' },
    FE: { id: 'fe', label: 'Flight Examinator (FE)', shortName: 'FE' },
    SEP: { id: 'sep', label: 'Single Engine Piston (SEP)', shortName: 'SEP' },
    VP: { id: 'vp', label: 'Variante Pas Variable (VP)', shortName: 'VP' },
    TW: { id: 'tw', label: 'Variante Train Classique (TW)', shortName: 'TW' },
    VISITE: { id: 'visite', label: 'Visite Médicale', shortName: 'Médical' },
    FCL800: { id: 'fcl800', label: 'FCL800', shortName: 'FCL800' },
    BFV1: { id: 'bfv1', label: 'BFV1', shortName: 'BFV1' },
    BFV2: { id: 'bfv2', label: 'BFV2', shortName: 'BFV2' },
    BFV3: { id: 'bfv3', label: 'BFV3', shortName: 'BFV3' },
    FFA: { id: 'ffa', label: 'Licence Fédérale', shortName: 'FFA' },
    COTISATION: { id: 'cotisation', label: 'Cotisation', shortName: 'Cotisation' },
  },
  QUALIFICATION_SUBTYPES: [
    { type: 'license', id: 'bb', label: 'Brevet de base (BB)' },
    { type: 'license', id: 'lapl', label: 'Light Aircraft Pilot Licence (LAPL)' },
    { type: 'license', id: 'ppl', label: 'Private Pilote License (PPL)' },
    { type: 'license', id: 'cpl', label: 'Commercial Pilot Licence (CPL)' },
    { type: 'license', id: 'atpl', label: 'Airline Transport Pilot License (ATPL)' },
    { type: 'visite', id: 'class1', label: 'Classe 1' },
    { type: 'visite', id: 'class2', label: 'Classe 2' },
  ],

  COMPETITION_LEVEL_ESPOIR: 'espoir',
  COMPETITION_LEVEL_PROMOTION: 'promotion',
  COMPETITION_LEVEL_N2: 'n2',
  COMPETITION_LEVEL_N1: 'n1',
  COMPETITION_LEVEL_ELITE: 'elite',

  STATUS_ACTIVATED: 'Actif',
  STATUS_EXTERN: 'Extérieur',
  STATUS_FORMER: 'Ancien',
  STATUS_HONOR: 'Honneur',
  STATUS_VISITOR: 'Visiteur',
  STATUS_APPLICATION_SUBMITTED: 'applicationSubmitted',
  STATUS_APPLICATION_VALIDATED: 'applicationValidated',

  ROLE_PILOT: 'pilote',
  ROLE_INSTRUCTOR: 'instructeur',
  ROLE_ADMIN: 'admin',
  ROLE_SUPER_ADMIN: 'superAdmin',
  ROLE_CHEF_PILOTE: 'chef-pilote',
  
  MENU_STATUS_HIDDEN: 'hidden',
  MENU_STATUS_SMALL: 'small',
  MENU_STATUS_BIG: 'big',
  
  ACTIVITY_STATUS_SUBSCRIPTIONS: 'subscriptions',
  ACTIVITY_STATUS_SUBSCRIPTIONS_CLOSED: 'subscriptionsClosed',
  ACTIVITY_STATUS_PLANNED: 'planned',
  ACTIVITY_STATUS_ENDED: 'ended',
}
