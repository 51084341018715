import React, { useMemo, useState } from 'react'
import { Card, Button, CardActions, CardContent, Skeleton, CardHeader, Box, Chip } from '@mui/material'
import { useSelector } from 'react-redux'
import { addDays, format, isPast } from 'date-fns'
import { MilitaryTech, Star } from '@mui/icons-material'
import EditQualificationDialog from '../qualifications/EditQualificationDialog'
import { listenCollection } from '../../store/actions/firestoreActions'
import { QUALIFICATION_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import useListener from '../../hooks/useListener'


export const AirworthinessCard = () => {
  
  const profile = useSelector(state => state.auth.profile)
  const qualifications = useSelector(state => state.firestore.qualifications)
  const qualificationTypes = useSelector(state => state.firestore.qualificationTypes?.filter(t => t.required))
  useListener(() => listenCollection({
    collection: QUALIFICATION_COLLECTION,
    where: [['archived', '!=', true], ['userRef', '==', USER_COLLECTION, profile.id]],
    orderBy: [
      ['archived', 'asc'],
      ['type.order', 'asc'],
      ['_createdAt', 'desc'],
    ],
  }), [profile.id])

  const [editQualification, setEditQualification] = useState(undefined)
  
  const types = useMemo(() => qualifications && qualificationTypes?.map(t => {
    const q = qualifications.find(q => q.typeRef.id === t.id)
    if (!q) return ({ ...t, status: 'error', message: 'Validité manquante' })
    else if (t.fields.includes('expireDate') && (!q.expireDate || isPast(q.expireDate.toDate()))) return ({ ...t, status: 'error', message: 'La validité est expirée' })
    else if (t.fields.includes('attachmentUrl') && !q.attachmentUrl) return ({ ...t, status: 'error', message: 'Il manque la photo du document' })
    else if (!q.checkerRef) return ({ ...t, status: 'warning', message: 'La validité n\'a pas été vérifiée' })
    else if (t.fields.includes('expireDate') && isPast(addDays(q.expireDate.toDate(), -60))) return ({ ...t, status: 'warning', message: 'La validité expire le ' + format(q.expireDate.toDate(), 'dd-MM-yyyy') })
    else return ({ ...t, status: 'success', message: 'OK' })
  }), [qualificationTypes, qualifications])

  if (!qualifications) return <Skeleton height={460} sx={{ borderRadius: 1 }} />
  return (
    <Card sx={{ minWidth: 275, mb: 2, p: 1, position: 'relative', height: 460 }}>
      <CardHeader avatar={<Star />} title='Check pilote' sx={{ color: 'grey.600' }} />
      <CardContent>
        <table>
          <tbody>
            {types?.map(type => <tr key={type.id}>
              <td><Box sx={{ width: 20, height: 20, borderRadius: 10, bgcolor: type.status + '.main' }}/></td>
              <td>{type.name}</td>
              <td>{type.message}</td>
            </tr>)}
            <tr>
              <td><Box sx={{ width: 20, height: 20, borderRadius: 10, bgcolor: profile.balance > 0 ? 'success.main' : 'error.main' }}/></td>
              <td>Compte pilote</td>
              <td>{profile.balance > 0 ? 'OK' : 'NOK'}</td>
            </tr>
            <tr>
              <td><Box sx={{ width: 20, height: 20, borderRadius: 10, bgcolor: profile.status === 'Actif' ? 'success.main' : 'error.main' }}/></td>
              <td>Status</td>
              <td>{profile.status}</td>
            </tr>
          </tbody>
        </table>
        {types?.every(t => t.message === 'OK') && profile.balance > 0 && profile.status === 'Actif' &&
          <Chip color='success' icon={<MilitaryTech />} label='Authorisé à voler' sx={{ mt: 1 }} />
        }
      </CardContent>
      <CardActions sx={{ position: 'absolute', bottom: theme => theme.spacing(.5), width: '100%', bgcolor: 'white' }}>
        <Button color='primary' onClick={() => setEditQualification(null)}>
          Nouvelle validité
        </Button>
      </CardActions>
      {editQualification !== undefined && <EditQualificationDialog onClose={() => setEditQualification(undefined)} qualification={editQualification} />}
    </Card>
  )
}

export default AirworthinessCard
