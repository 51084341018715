import React from 'react'
import { Card, CardContent, CardHeader, Skeleton } from '@mui/material'
import { useSelector } from 'react-redux'
import { PlayArrow } from '@mui/icons-material'
import useListener from '../../hooks/useListener'
import { listenCollection } from '../../store/actions/firestoreActions'
import { ACTIVITIES } from '../../_constants/routes'
import ActivityButton from './ActivityButton'


export const ActivityCard = () => {
  
  useListener(() => listenCollection({
    collection: ACTIVITIES,
    orderBy: [['lastDay', 'desc']],
    where: [['lastDay', '>=', new Date()]],
    limit: 4,
    storeAs: 'nextActivities',
  }), [])
  const activities = useSelector(state => state.firestore.nextActivities)
  
  if (!activities) return <Skeleton height={460} variant='rectangular' sx={{ borderRadius: 1 }} />
  return (
    <Card sx={{ minWidth: 275, mb: 2, p: 1, position: 'relative', height: 460 }}>
      <CardHeader avatar={<PlayArrow />} title='Prochain stages' sx={{ color: 'grey.600' }} />
      <CardContent>
        {activities?.reverse().map(activity => <ActivityButton key={activity.id} {...activity} />)}
      </CardContent>
    </Card>
  )
}

export default ActivityCard
