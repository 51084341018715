import {
  SELECT_AIRCRAFT, SELECT_YEAR, TOGGLE_MENU_DRAWER, SELECT_USER, SELECT_LESSON, SELECT_ACCOUNT, TOGGLE_ADMIN_MODE, SELECT_ACTIVITY, NOTIFICATIONS_ALLOWED,
} from '../types'
import { saveReduxState } from '../../_helpers/localStorageHelper'
import { getYear } from 'date-fns'


export const INITIAL_STATE = {
  selectedYear: getYear(Date.now()),
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case SELECT_AIRCRAFT:
      saveReduxState('data', 'selectedAircraft', action.payload)
      return { ...state, selectedAircraft: action.payload }

    case SELECT_YEAR:
      saveReduxState('data', 'selectedYear', action.payload)
      return { ...state, selectedYear: action.payload }

    case SELECT_USER:
      saveReduxState('data', 'selectedUser', action.payload)
      return { ...state, selectedUser: action.payload }

    case SELECT_ACCOUNT:
      saveReduxState('data', 'selectedAccount', action.payload)
      return { ...state, selectedAccount: action.payload }

    case SELECT_LESSON:
      return { ...state, selectedLesson: action.payload }
  
    case SELECT_ACTIVITY:
      return { ...state, selectedActivity: action.payload }

    case TOGGLE_MENU_DRAWER:
      saveReduxState('data', 'menuDrawerOpen', action.payload)
      return { ...state, menuDrawerOpen: action.payload }

    case TOGGLE_ADMIN_MODE:
      saveReduxState('data', 'adminMode', action.payload)
      return { ...state, adminMode: action.payload }
    
    case NOTIFICATIONS_ALLOWED:
      return { ...state, notificationsAllowed: action.payload }

    default:
      return state
  }
}
