import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Collapse, Fab, Grid, Switch, TextField, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Add, ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { deleteLessonAction, updateLessonAction } from '../../store/actions/progressBookActions'
import { selectLesson } from '../../store/actions/dataActions'
import { parseParams } from '../../_helpers/urlHelper'
import { omit } from 'lodash'
import DeleteButton from '../_library/DeleteButton'
import FlightConnector from './FlightConnector'
import { FLIGHT_COLLECTION, NOTIFICATION_COLLECTION, PB_LESSON_COLLECTION, PROGRESS_BOOK_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { addDocAction, deleteDocAction } from '../../store/actions/firestoreActions'
import { DatePicker } from '@mui/x-date-pickers'
import { useLocation } from 'react-router'


const SelectedLessonManager = ({ progressBook, lessonsWithItems, scrollTo }) => {
  
  const location = useLocation()
  const dispatch = useDispatch()
  const profile = useSelector(state => state.auth.profile)
  const selectedLesson = useSelector(state => state.data.selectedLesson)
  const notifications = useSelector(state =>
    state.firestore.notifications?.filter(n => n.params?.progressBookRef?.id === progressBook?.id),
  )
  
  const [expand, setExpand] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})

  const newLessonTemplate = useMemo(() => ({
    id: 'new',
    withTheory: true,
    withPractice: true,
    archived: false,
    progressBookRef: [PROGRESS_BOOK_COLLECTION, progressBook.id],
    pilotRef: progressBook.pilotRef,
    instructorRef: [USER_COLLECTION, profile.id],
    gradedItems: [],
    generalImpression: {},
  }), [progressBook, profile.id])

  useEffect(() => {
    if (location.search) {
      const { lessonId } = parseParams(location.search)
      const lesson = lessonsWithItems?.find(l => l.id === lessonId)
      if (lesson?.selectedItemIds)
        dispatch(selectLesson(lesson))
    }
  }, [location.search, lessonsWithItems])
  
  useEffect(() => {
    if (typeof selectedLesson === 'string') {
      const lessonId = selectedLesson
      const lesson = lessonsWithItems?.find(l => l.id === lessonId)
      if (lesson?.selectedItemIds)
        dispatch(selectLesson(lesson))
    }
  }, [selectedLesson, lessonsWithItems])
  
  useEffect(() => {
    !selectedLesson && setExpand(false)
  }, [selectedLesson])

  const handleSubmit = () => {
    setSubmitted(true)
    setErrors({})
    if (selectedLesson.gradedItems.some(i => i.type === 'theoretical') && !selectedLesson.briefingDuration) {
      setErrors(prev => ({ ...prev, briefingDuration: 'Champs requis' }))
      setSubmitted(false)
    }
    if (!selectedLesson.gradedItems.some(i => i.type === 'practical') && !selectedLesson.date) {
      setErrors(prev => ({ ...prev, date: 'Champs requis' }))
      setSubmitted(false)
      setExpand(true)
    }
    if (selectedLesson.gradedItems.some(i => i.type === 'practical') && !selectedLesson.flightId && !selectedLesson.flightRef) {
      setErrors(prev => ({ ...prev, flightId: 'Champs requis' }))
      setSubmitted(false)
      setExpand(true)
    }
    if ((selectedLesson.gradedItems.some(i => i.type === 'theoretical') && !selectedLesson.briefingDuration)
      || (!selectedLesson.gradedItems.some(i => i.type === 'practical') && !selectedLesson.date)
      || (selectedLesson.gradedItems.some(i => i.type === 'practical') && !selectedLesson.flightId && !selectedLesson.flightRef))
      return
    const response = selectedLesson.id === 'new'
      ? dispatch(addDocAction(PB_LESSON_COLLECTION, omit({ ...selectedLesson, draft: false }, ['id'])))
      : dispatch(updateLessonAction(selectedLesson.id, omit({ ...selectedLesson, draft: false }, ['id'])))
    return response
      .then(() => Promise.all(notifications?.map(n => dispatch(deleteDocAction(NOTIFICATION_COLLECTION, n.id)))))
      .then(() => dispatch(selectLesson(null)))
      .catch(err => console.error(err))
      .finally(() => setSubmitted(false))
  }

  const handleDelete = () => {
    setSubmitted(true)
    return dispatch(deleteLessonAction(selectedLesson.id))
      .then(() => selectLesson(null))
      .catch(err => console.error(err))
      .finally(() => setSubmitted(false))
  }

  return selectedLesson
    ? <Grid container sx={{
      position: 'fixed',
      bgcolor: 'primary.light',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      bottom: 0,
      ml: -1,
      p: 1,
      width: '-webkit-fill-available',
    }}>
      <Grid item md={3}>
        <div>
          <Switch
            checked={selectedLesson.withTheory || false}
            onChange={e => dispatch(selectLesson({
              ...selectedLesson,
              withTheory: e.target.checked,
              gradedItems: e.target.checked ? selectedLesson.gradedItems : selectedLesson.gradedItems.filter(i => i.type === 'practical'),
            }))}
            name='briefingSwitch'
          />
          <span onClick={() => scrollTo('theoreticalCard')} style={{ cursor: 'pointer' }}>Briefing</span>
        </div>
        {selectedLesson.withTheory && <>
          <Typography variant='body1' style={{ display: 'inline-block' }}>Durée :&nbsp;</Typography>
          <TextField
            value={selectedLesson.briefingDuration || ''}
            onChange={e => dispatch(selectLesson({ ...selectedLesson, briefingDuration: e.target.value }))}
            variant='outlined'
            inputProps={{ style: { padding: '2px 5px' } }}
            error={!!errors.briefingDuration}
            helperText={errors.briefingDuration}
            id='briefingDurationInput'
          />
          <Typography variant='body1'>Items : {selectedLesson.gradedItems?.filter(i => i.type === 'theoretical').length || 0}</Typography>
        </>}
      </Grid>
      <Grid item md={3}>
        <div>
          <Switch
            checked={selectedLesson.withPractice || false}
            onChange={e => dispatch(selectLesson({
              ...selectedLesson,
              withPractice: e.target.checked,
              gradedItems: e.target.checked ? selectedLesson.gradedItems : selectedLesson.gradedItems.filter(i => i.type === 'theoretical'),
              generalImpression: e.target.checked ? selectedLesson.generalImpression : {},
            }))}
            name='flightSwitch'
          />
          <span onClick={() => scrollTo('practicalCard')} style={{ cursor: 'pointer' }}>Vol</span>
        </div>
        {selectedLesson.withPractice && <>
          <Typography variant='body1'>Items : {selectedLesson.gradedItems?.filter(i => i.type === 'practical').length || 0}</Typography>
        </>}
      </Grid>
      <Grid item md={3}>
        <div>
          <Switch
            checked={selectedLesson.withPractice || false}
            onChange={e => dispatch(selectLesson({
              ...selectedLesson,
              withPractice: e.target.checked,
              gradedItems: e.target.checked ? selectedLesson.gradedItems : selectedLesson.gradedItems.filter(i => i.type === 'theoretical'),
              generalImpression: e.target.checked ? selectedLesson.generalImpression : {},
            }))}
          />
          <span onClick={() => scrollTo('generalImpressionCard')} style={{ cursor: 'pointer' }}>Impression générale</span>
          {selectedLesson.withPractice && <>
            <Typography variant='body1'>Items : {selectedLesson.generalImpression ? Object.keys(selectedLesson.generalImpression).length : 0}</Typography>
          </>}
        </div>
      </Grid>
      <Grid item md={3}>
        <Button variant='outlined' onClick={handleSubmit} disabled={submitted} sx={{
          display: 'block',
          my: 1,
          mx: 'auto',
          width: '90%',
          fontWeight: 'bold',
          borderWidth: 2,
          borderColor: 'grey.800',
        }}>Valider</Button>
        <Button variant='outlined' onClick={() => dispatch(selectLesson(null))} disabled={submitted} sx={{
          display: 'block',
          my: 1,
          mx: 'auto',
          width: '90%',
        }}>Annuler</Button>
      </Grid>
      <Collapse in={expand} style={{ width: '100%' }}>
        <Grid item xs={12} container spacing={2}>
          <Grid item md={9}>
            {selectedLesson.withPractice
              ? <FlightConnector
                lesson={selectedLesson}
                handleChange={(flightId, flights) =>
                  dispatch(selectLesson({
                    ...selectedLesson,
                    flightRef: [FLIGHT_COLLECTION, flightId],
                    date: flights.find(f => f.id === flightId)?.startDate.toDate(),
                  }))
                }
                error={!!errors.flightId}
              />
              : <>
                <DatePicker
                  disableToolbar
                  variant='inline'
                  inputFormat='dd-MM-yyyy'
                  margin='dense'
                  label='Date de la session'
                  value={typeof selectedLesson.date?.toDate === 'function' ? selectedLesson.date.toDate() : selectedLesson.date || null}
                  onChange={val => dispatch(selectLesson({ ...selectedLesson, date: val }))}
                  slotProps={{ textField: { error: !!errors.date, helperText: errors.date } }}
                  sx={{ display: 'block' }}
                />
              </>
            }
          </Grid>
          <Grid item md={3}>
            {selectedLesson.id !== 'new' && <DeleteButton text='Supprimer' deleteAction={handleDelete} sx={{
              display: 'block',
              my: 1,
              mx: 'auto',
              width: '90%',
            }} />}
          </Grid>
          <Grid item md={12}>
            <TextField
              label='Commentaire général'
              value={selectedLesson.comment || ''}
              onChange={e => dispatch(selectLesson({ ...selectedLesson, comment: e.target.value }))}
              multiline
              rows={3}
              variant='outlined'
              fullWidth
            />
          </Grid>
        </Grid>
      </Collapse>
      <Button color='primary' onClick={() => setExpand(prev => !prev)} id='expandButton' sx={{
        width: '100%',
        height: '20px',
        color: 'white',
      }}>
        {expand
          ? <ArrowDropDown />
          : <ArrowDropUp />
        }
      </Button>
    </Grid>
    : <Fab
      color='secondary'
      aria-label='add'
      onClick={() => {dispatch(selectLesson(newLessonTemplate)); scrollTo('theoreticalCard')}}
      sx={{
        right: theme => theme.spacing(3),
        bottom: theme => theme.spacing(3),
        position: 'fixed',
      }}
    >
      <Add />
    </Fab>
}

SelectedLessonManager.propTypes = {
  progressBook: PropTypes.object.isRequired,
  lessonsWithItems: PropTypes.array,
  scrollTo: PropTypes.func.isRequired,
}

export default SelectedLessonManager
