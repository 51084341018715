import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import withStyles from '@mui/styles/withStyles'
import MUIDataTable from 'mui-datatables'
import { USER_COLLECTION } from '../../_constants/globals'
import { listenCollection } from '../../store/actions/firestoreActions'
import { useLocation, useNavigate } from 'react-router'


const styles = theme => ({
  selectDialog: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  root: {
    margin: 20,
  },
  circularProgressContainer: {
    marginTop: 30,
  },
  tableRow: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
})

const SubscriptionTable = ({ classes, subscriptions, listenCollection }) => {
  
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const settingListener = listenCollection({
      collection: USER_COLLECTION,
      where: [['status', '==', 'applicationSubmitted']],
      orderBy: [['_createdAt', 'asc']],
      storeAs: 'subscriptions',
    })
    return () => {
      settingListener()
    }
  }, [listenCollection])

  const data = subscriptions && subscriptions.reduce((acc, val) => {
    if (val) {
      const { firstname, lastname, email, phone } = val
      acc.push([
        firstname,
        lastname,
        email,
        phone,
      ])
    }
    return acc
  }, [])

  const columns = ['Firstname', 'Lastname', 'E-mail', 'Phone']

  return (
    <div className={classes.root}>
      <MUIDataTable
        data={data}
        columns={columns}
        options={{
          filterType: 'textField',
          selectableRows: 'none',
          serverSide: false,
          onRowClick: (row, { dataIndex }) => navigate(`${location.pathname}?userId=${subscriptions[dataIndex].id}`),
          setRowProps: () => ({ className: classes.clickable }),
        }}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  subscriptions: state.firestore.subscriptions,
})

SubscriptionTable.propTypes = {
  classes: PropTypes.object.isRequired,
  subscriptions: PropTypes.array,
  listenCollection: PropTypes.func.isRequired,
}

export default compose(
  connect(mapStateToProps, { listenCollection }),
  withStyles(styles),
)(SubscriptionTable)
