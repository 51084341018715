import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel,
  FormHelperText, FormLabel, IconButton, Radio, RadioGroup, TextField,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '../_library/Avatar'
import { Close } from '@mui/icons-material'
import CTAButton from '../_library/CTAButton'
import { updateDocAction } from '../../store/actions/firestoreActions'
import { ACTIVITY_COLLECTION, ACTIVITY_STATUS_ENDED, ACTIVITY_STATUS_PLANNED, ACTIVITY_STATUS_SUBSCRIPTIONS,
  ACTIVITY_STATUS_SUBSCRIPTIONS_CLOSED } from '../../_constants/globals'
import { useNavigate } from 'react-router'
import { ACTIVITIES } from '../../_constants/routes'


const PublishCard = ({ sx }) => {
  
  const { id, organizerRef } = useSelector(state => state.data.selectedActivity)
  const profile = useSelector(state => state.auth.profile)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const [openDialog, setOpenDialog] = useState(false)
  const [type, setType] = useState(null)
  const [comment, setComment] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState({})
  
  const info = useMemo(() => {
    setErrors({})
    switch (type) {
      case ACTIVITY_STATUS_SUBSCRIPTIONS: return 'Tous les utilisateurs actifs seront notifiés'
      case ACTIVITY_STATUS_PLANNED: return 'Tous les inscrits au stage seront notifiés'
      case ACTIVITY_STATUS_ENDED: return 'Pense à envoyer un mail sur la mailing liste en précisant où sont les avions'
    }
  }, [type])
  
  const handleSubmit = useCallback(() => {
    setSubmitted(true)
    if (!type) setErrors(prev => ({ ...prev, type: 'Il faut choisir un type de publication' }))
    else dispatch(updateDocAction(ACTIVITY_COLLECTION, id, { status: type, statusComment: comment?.replaceAll('\n', '<br>') || null }))
      .then(() => {
        if (type === ACTIVITY_STATUS_ENDED) navigate(ACTIVITIES)
        return setOpenDialog(false)
      })
      .finally(() => setSubmitted(false))
  }, [type])
  
  return (
    <Card sx={sx}>
      <CardContent sx={{ display: 'flex', '&:last-child': { pb: 2 } }}>
        <Avatar user={profile} sx={{ mr: 2 }} />
        <Button
          variant='contained'
          onClick={() => setOpenDialog(true)}
          fullWidth
          disabled={!organizerRef}
          title={organizerRef ? null : 'Il faut un GO pour faire une publication'}
        >Ajouter une publication</Button>
      </CardContent>
      {openDialog && <Dialog open onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          Créer une publication
          <IconButton
            onClick={() => setOpenDialog(false)}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'grey.500' }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <FormControl error={!!errors.type}>
                <FormLabel>Type de publication</FormLabel>
                <RadioGroup value={type || ''} onChange={e => setType(e.target.value)}>
                  <FormControlLabel value={ACTIVITY_STATUS_SUBSCRIPTIONS} control={<Radio />} label='Ouverture des inscriptions' />
                  <FormControlLabel value={ACTIVITY_STATUS_SUBSCRIPTIONS_CLOSED} control={<Radio />} label='Cloture des inscriptions' />
                  <FormControlLabel value={ACTIVITY_STATUS_PLANNED} control={<Radio />} label='Finaliser le planning' />
                  <FormControlLabel value={ACTIVITY_STATUS_ENDED} control={<Radio />} label='Cloturer le stage' />
                </RadioGroup>
                {(errors.type || info) && <FormHelperText>{errors.type || info}</FormHelperText>}
              </FormControl>
              <TextField
                variant='outlined'
                margin='dense'
                label='Quelque chose a ajouter ?'
                fullWidth
                value={comment || ''}
                multiline
                onChange={e => {
                  setComment(e.target.value)
                }}
                rows={3}
                sx={{ mt: 2 }}
              />
            </form>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <CTAButton onClick={handleSubmit} disabled={submitted} fullWidth>Publier</CTAButton>
        </DialogActions>
      </Dialog>}
    </Card>
  )
}

PublishCard.propTypes = {
  sx: PropTypes.object,
}

export default PublishCard
