import React, { useMemo } from 'react'
import { AvatarGroup, MenuItem, Select } from '@mui/material'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Avatar from '../_library/Avatar'
import { difference } from 'lodash'
import { addDocAction, deleteDocAction, updateDocAction } from '../../store/actions/firestoreActions'
import { ACTIVITY_COLLECTION, AVAILABILITY_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { format } from 'date-fns'


const InstructorSelect = ({ day, period, disabled }) => {
  
  const { id, organizerRef, instructorRefs = [] } = useSelector(state => state.data.selectedActivity || {})
  const allInstructors = useSelector(state => state.firestore.users?.filter(u => u.roles?.includes('instructeur')), shallowEqual)
  const profile = useSelector(state => state.auth.profile)
  const adminMode = useSelector(state => state.data.adminMode)
  const availabilities = useSelector(state => state.firestore.availabilities?.filter(a =>
    format(a.start.toDate(), 'yyyy-MM-ddaa') === format(day, 'yyyy-MM-dd') + period))
  const dispatch = useDispatch()
  
  const { start, end } = useMemo(() => period === 'AM'
    ? ({
      start: new Date(format(day, 'yyyy-MM-dd') + 'T09:00'),
      end: new Date(format(day, 'yyyy-MM-dd') + 'T12:00'),
    })
    : ({
      start: new Date(format(day, 'yyyy-MM-dd') + 'T14:00'),
      end: new Date(format(day, 'yyyy-MM-dd') + 'T18:00'),
    }), [day, period])
  
  const handleChange = e => {
    difference(e.target.value, availabilities.map(a => a.userRef.id)).forEach(userId => {
      dispatch(addDocAction(AVAILABILITY_COLLECTION, {
        day,
        start,
        end,
        period,
        userRef: [USER_COLLECTION, userId],
        activityRef: [ACTIVITY_COLLECTION, id],
      }))
      if (!instructorRefs.some(ref => ref.id === userId))
        dispatch(updateDocAction(ACTIVITY_COLLECTION, id, { instructorRefs: [USER_COLLECTION, userId, 'add'] }))
    })
    availabilities
      .filter(a => !e.target.value.includes(a.userRef.id))
      .forEach(a => dispatch(deleteDocAction(AVAILABILITY_COLLECTION, a.id)))
  }
  
  return allInstructors && !disabled
    ? <Select
      multiple
      value={availabilities?.map(a => a.userRef.id) || []}
      onChange={handleChange}
      disabled={!adminMode && organizerRef.id !== profile.id}
      variant='standard'
      disableUnderline
      inputProps={{ sx: { p: 0 } }}
      renderValue={selected =>
        <AvatarGroup>
          {allInstructors.filter(u => selected.includes(u.id))?.map((user, index) => <Avatar key={index} src={user.photoUrl} alt={user.firstname} />)}
        </AvatarGroup>
      }
    >
      {allInstructors.map(user => (
        <MenuItem
          key={user.id}
          value={user.id}
          selected={!!availabilities?.find(a => a.userRef.id === user.id)}
          sx={{
            fontWeight: availabilities?.find(a => a.userRef.id === user.id) ? 700 : 'initial',
          }}
        >
          <Avatar user={user} />&nbsp;
          {user.firstname + ' ' + user.lastname}
        </MenuItem>
      ))}
    </Select>
    : <AvatarGroup max={3}>
      {availabilities?.map(({ user }, index) => <Avatar key={index} src={user?.photoUrl} alt={user?.firstname} />)}
    </AvatarGroup>
}

InstructorSelect.propTypes = {
  day: PropTypes.object,
  period: PropTypes.string,
  disabled: PropTypes.bool,
}

export default InstructorSelect
