import { FLIGHT_CREATE_SUCCESS } from '../types'


// eslint-disable-next-line default-param-last
export default (state = {}, action) => {
  switch (action.type) {

    case FLIGHT_CREATE_SUCCESS:
      return { ...state, notification: { variant: 'success', message: 'Le vol a bien été enregistré' } }

    default:
      return state
  }
}
