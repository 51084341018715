import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, CardActions, Card, CardContent, Typography, FormControl, Select, MenuItem, FormHelperText,
  Grid, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import InlineAvatarEditor from '../profile/InlineAvatarEditor'
import { updateDocAction } from '../../store/actions/firestoreActions'
import { differenceInYears } from 'date-fns'
import { USER_COLLECTION } from '../../_constants/globals'
import { DatePicker } from '@mui/x-date-pickers'


const labelStyle = {
  color: 'rgba(0,0,0,.54)',
  display: 'inline-block',
  lineHeight: '20px',
  width: 200,
}
const IdentityForm = ({ next, back }) => {
  
  const profile = useSelector(state => state.auth.profile)
  const dispatch = useDispatch()
  
  const [firstname, setFirstname] = useState(profile.firstname)
  const [lastname, setLastname] = useState(profile.lastname)
  const [gender, setGender] = useState(profile.gender)
  const [birthdate, setBirthdate] = useState(profile.birthdate ? profile.birthdate.toDate() : new Date())
  const [birthplace, setBirthplace] = useState(profile.birthplace)
  const [nationality, setNationality] = useState(profile.nationality || 'FR')
  const [errors, setErrors] = useState({})
  
  const handleSubmit = () => {
    setErrors({})
    if (!firstname) setErrors(err => ({ ...err, firstname: 'Le prénom doit être rempli' }))
    if (!lastname) setErrors(err => ({ ...err, lastname: 'Le nom doit être rempli' }))
    if (!gender) setErrors(err => ({ ...err, gender: 'Il faut sélectionner un sexe' }))
    if (differenceInYears(new Date(), birthdate) < 18)
      setErrors(err => ({
        ...err,
        birthdate: 'Ce formulaire n\'est pas accessible aux mineurs. Merci de prendre contact avec un responsable du club',
      }))
    if (!birthplace) setErrors(err => ({ ...err, birthplace: 'Le lieu de naissance doit être rempli' }))
    if (!nationality) setErrors(err => ({ ...err, nationality: 'La nationalité doit être rempli' }))
    if (firstname
      && lastname
      && gender
      && differenceInYears(new Date(), birthdate) >= 18
      && birthplace
      && nationality
    )
      dispatch(updateDocAction(USER_COLLECTION, profile.id, { firstname, lastname, gender, birthdate, birthplace, nationality }))
        .then(next)
  }
  
  return (
    <Card variant='outlined' sx={{ my: 2, mx: 'auto', maxWidth: 900 }}>
      <CardContent>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography color='textSecondary' gutterBottom sx={labelStyle}>Prénom</Typography>
          <TextField
            variant='standard'
            value={firstname || ''}
            onChange={e => setFirstname(e.target.value)}
            error={!!errors.firstname}
            helperText={errors.firstname} />
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography color='textSecondary' sx={labelStyle}>Nom</Typography>
          <TextField
            variant='standard'
            value={lastname || ''}
            onChange={e => setLastname(e.target.value)}
            error={!!errors.lastname}
            helperText={errors.lastname} />
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography color='textSecondary' gutterBottom sx={labelStyle}>Sexe</Typography>
          <FormControl variant='outlined' error={!!errors.gender}>
            <Select
              variant='standard'
              id='sex-select'
              value={gender || ''}
              onChange={e => setGender(e.target.value)}
              margin='dense'>
              <MenuItem value=''>&nbsp;</MenuItem>
              <MenuItem value='woman'>Féminin</MenuItem>
              <MenuItem value='man'>Masculin</MenuItem>
            </Select>
            {errors.gender && <FormHelperText>{errors.gender}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography color='textSecondary' gutterBottom sx={labelStyle}>
            Date de naissance
          </Typography>
          <DatePicker
            inputFormat='dd-MM-yyyy'
            margin='dense'
            id='birthdate'
            value={birthdate}
            onChange={setBirthdate}
            componentsProps={{ textField: { error: !!errors.birthdate, helperText: errors.birthdate } }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography color='textSecondary' gutterBottom sx={labelStyle}>
            Lieu de naissance
          </Typography>
          <TextField
            variant='standard'
            value={birthplace || ''}
            onChange={e => setBirthplace(e.target.value)}
            error={!!errors.birthplace}
            helperText={errors.birthplace} />
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography color='textSecondary' gutterBottom sx={labelStyle}>
            Nationalité
          </Typography>
          <TextField
            variant='standard'
            value={nationality}
            onChange={e => setNationality(e.target.value)}
            error={!!errors.nationality}
            helperText={errors.nationality} />
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <InlineAvatarEditor />
        </Grid>
      </CardContent>
      <CardActions style={{ float: 'right' }}>
        <Button size='large' onClick={back}>Précédent</Button>
        <Button size='large' color='primary' onClick={handleSubmit}>Valider</Button>
      </CardActions>
    </Card>
  )
}

IdentityForm.propTypes = {
  next: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
}

export default IdentityForm
